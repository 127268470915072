import { DialogProps } from '@mui/material';
import * as React from 'react';
import { SharedDialogTriggerProps } from 'src/components/DialogTrigger/DialogTrigger';
import useDefaultTriggerRenderer from 'src/components/DialogTrigger/useDefaultTriggerRenderer';
import useCustomOffset from 'src/components/FloatingDialogTrigger/useCustomOffset';
import OverlayTrigger from 'src/components/OverlayTrigger/OverlayTrigger';
import { OverlayTriggerState } from 'src/components/OverlayTrigger/types';

/**
 * A component that controls the visibility of a `Dialog` component _(without modality)_,
 * using the element returned from `props.renderTrigger` and/or
 * via the methods found in the imperative API exposed by `props.overlayAction`.
 *
 * To control the visibility of a Modal {@link Dialog}, use the {@link DialogTrigger} component.
 */
export const FloatingDialogTrigger = React.forwardRef<
  HTMLElement,
  FloatingDialogTriggerProps
>(function FloatingDialogTrigger(props, forwardedRef) {
  const defaultTriggerRenderer = useDefaultTriggerRenderer();

  const {
    renderDialog: renderDialogProp,
    renderTrigger: renderTriggerProp = defaultTriggerRenderer,
    offset: offsetIn,
    ...rest
  } = props;

  const offset = useCustomOffset(offsetIn);

  function renderFloatingDialog(
    state: OverlayTriggerState
  ): React.ReactElement | undefined {
    const renderedDialog = renderDialogProp && renderDialogProp(state);
    if (!renderedDialog) {
      return undefined;
    }

    const consumerRootSx = renderedDialog.props.sx;

    return React.cloneElement<DialogProps>(renderedDialog, {
      fullScreen: false,
      hideBackdrop: true,
      disableEnforceFocus: true,
      disableEscapeKeyDown: true,
      disableScrollLock: true,
      sx: {
        ...consumerRootSx,
        ...offset,
      },
      PaperProps: {
        ...(renderedDialog.props.PaperProps ?? {}),
        sx: {
          // Remove the margin so the visible edge of the dialog paper
          // is the edge of where clicks are "blocked" from reaching elements
          // "behind" the backdrop.
          margin: 0,
          ...(renderedDialog.props.PaperProps?.sx ?? {}),
        },
      },
    });
  }

  function renderFloatingDialogTrigger(
    state: OverlayTriggerState
  ): React.ReactElement {
    const renderedTrigger = renderTriggerProp(state);

    return React.cloneElement(renderedTrigger, {
      'aria-controls': state.popupId,
    });
  }

  return (
    <OverlayTrigger
      {...rest}
      ref={forwardedRef}
      disableEscapeKeyDown
      disableClickAwayListener
      overlayVariant="dialog"
      triggerClickAction="toggles"
      renderTrigger={renderFloatingDialogTrigger}
      renderOverlay={renderFloatingDialog}
    />
  );
});

export type FloatingDialogTriggerProps = Omit<
  SharedDialogTriggerProps,
  'disableClickAwayListener' | 'disableEscapeKeyDown'
> & {
  /**
   * Optional custom CSS positioning offset to use for the dialog.
   *
   * @default `{top: '50%', left: '50%'}` (centered horizontally and vertically)
   */
  offset?: {
    top?: string | number;
    right?: string | number;
    bottom?: string | number;
    left?: string | number;
  };
};

export default FloatingDialogTrigger;
