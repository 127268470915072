import {
  ChipPropsColorOverrides,
  ChipPropsVariantOverrides,
  CSSObject,
  Theme,
} from '@mui/material';
import { getGreyShadeColorForPaletteMode } from 'src/utils/paperAlphaUtils';
import SassColor from 'src/utils/sass_color_utils';
import chipClasses from 'src/wsd/components/Chip/chipClasses';
import { ClickableComponentStateColors } from 'src/wsd/components/utils/types';

export const badgeColorNames = [
  'default',
  'primary',
  'secondary',
  'info',
  'success',
  'error',
  'warning',
  'wsdBadgeOutlined',
] as const;

export type WsdBadgeColorName =
  | (typeof badgeColorNames)[number]
  | keyof ChipPropsColorOverrides;

export type WsdChipVariantName =
  | 'filled'
  | 'outlined'
  | keyof ChipPropsVariantOverrides;

interface WsdBadgePaletteColors {
  bgColors: ClickableComponentStateColors;
}

function getDerivedBadgePaletteColors(
  defaultColor: string
): WsdBadgePaletteColors {
  const hoverBgColor = SassColor.darken(defaultColor, 5);

  return {
    bgColors: {
      default: defaultColor,
      hover: hoverBgColor,
      press: SassColor.darken(hoverBgColor, 3),
    },
  };
}

export const wsdBadgePalettes: Partial<
  Record<WsdBadgeColorName, ((theme: Theme) => WsdBadgePaletteColors) | null>
> = {
  default: (theme) =>
    getDerivedBadgePaletteColors(
      getGreyShadeColorForPaletteMode(theme.palette.grey[700], theme.palette)
    ),
  primary: (theme) => getDerivedBadgePaletteColors(theme.palette.primary.main),
  secondary: (theme) =>
    getDerivedBadgePaletteColors(theme.palette.secondary.main),
  info: (theme) => getDerivedBadgePaletteColors(theme.palette.info.main),
  success: (theme) => getDerivedBadgePaletteColors(theme.palette.success.main),
  warning: (theme) => getDerivedBadgePaletteColors(theme.palette.warning.main),
  error: (theme) => getDerivedBadgePaletteColors(theme.palette.error.main),
  wsdBadgeOutlined: () => ({
    bgColors: {
      default: 'initial',
      hover: 'initial',
      press: 'initial',
    },
  }),
};

function getBadgeContrastTextColor(bgColor: string, theme: Theme): string {
  if (bgColor === 'initial') return 'inherit';
  return theme.isLegacyWsdTheme && theme.palette.mode === 'light'
    ? '#fff'
    : theme.palette.getContrastText(bgColor);
}

function generateWsdBadgeColorVariantStyles(
  theme: Theme,
  color: WsdBadgeColorName
): CSSObject | null {
  const getPalette = wsdBadgePalettes[color];
  if (getPalette == null) return null;

  const { bgColors } = getPalette(theme);

  return {
    backgroundColor: bgColors.default,
    color: getBadgeContrastTextColor(bgColors.default, theme),
    ...(color === 'wsdBadgeOutlined' && {
      boxShadow: `inset 0 0 0 1px currentColor`,
    }),
    '&[role="button"]': {
      '&:hover': {
        backgroundColor: bgColors.hover,
      },
      [`&.${chipClasses.focusVisible}`]: {
        backgroundColor: bgColors.hover,
        boxShadow: `inset 0 0 0 1px ${theme.palette.action.focusOutlineColor}`,
      },
      '&:active': {
        backgroundColor: bgColors.press,
        boxShadow:
          color === 'wsdBadgeOutlined'
            ? `inset 0 0 0 1px currentColor`
            : 'none',
      },
    },
  };
}

export function generateWsdBadgeStyles(
  theme: Theme,
  color: WsdBadgeColorName
): CSSObject {
  return {
    padding: '0 .525em',
    fontSize: '.85725em',
    minWidth: '.64825em',
    marginRight: '.5em',
    marginTop: '1%',
    verticalAlign: 'text-bottom',
    borderRadius: '1em',
    ...generateWsdBadgeColorVariantStyles(theme, color),
  };
}
