import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  useThemeProps,
} from '@mui/material';
import * as React from 'react';

export interface CheckboxProps extends MuiCheckboxProps {
  /**
   * Callback fired when the state is changed.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event The event source of the callback.
   * You can pull out the new checked state by accessing `event.target.checked` (boolean).
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

// This wrapper is necessary to change the typing of the `onChange` prop from
// `(event: ChangeEvent, checked:bool) => void` to `(event: ChangeEvent) => void`
// in order to avoid unnecessary typing conflicts with `UiProps.onChange`.
export const Checkbox = React.forwardRef<HTMLButtonElement, CheckboxProps>(
  function Checkbox(inProps, ref) {
    const props = useThemeProps({ props: inProps, name: 'MuiCheckbox' });
    const { onChange, ...rest } = props;
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      return onChange?.(event);
    };

    return <MuiCheckbox {...rest} onChange={handleChange} ref={ref} />;
  }
);

export default Checkbox;
