import * as React from 'react';
import type { FloatingDialogTriggerProps } from 'src/components/FloatingDialogTrigger/FloatingDialogTrigger';

/**
 * An internal hook used by `FloatingDialogTrigger` to allow
 * consumers to control the offset position of floating dialogs
 * using the `offset` prop.
 * @package
 */
function useCustomOffset(
  offsetIn: FloatingDialogTriggerProps['offset'] = offsetDefaults
): OffsetWithTransform {
  const buildOffset = React.useCallback<() => OffsetWithTransform>(() => {
    return {
      top: offsetIn?.top ?? offsetDefaults.top,
      right: offsetIn?.right ?? offsetDefaults.right,
      bottom: offsetIn?.bottom ?? offsetDefaults.bottom,
      left: offsetIn?.left ?? offsetDefaults.left,
      transform:
        offsetIn.left === '50%' && offsetIn.top === '50%'
          ? 'translate(-50%, -50%)'
          : undefined,
    };
  }, [offsetIn]);

  const [offset, setOffset] = React.useState(buildOffset);

  React.useLayoutEffect(() => {
    setOffset(buildOffset());
  }, [buildOffset]);

  return offset;
}

type OffsetWithTransform = typeof offsetDefaults & { transform?: string };

export const offsetDefaults: NonNullable<
  Required<FloatingDialogTriggerProps['offset']>
> = {
  top: '50%',
  right: 'auto',
  bottom: 'auto',
  left: '50%',
};

export default useCustomOffset;
