import {
  CssBaseline as MuiCssBaseline,
  CssBaselineProps as MuiCssBaselineProps,
  GlobalStyles as MuiGlobalStyles,
} from '@mui/material';
import * as React from 'react';

/**
 * Global styles for `InputBase`
 * Used within {@link UnifyGlobalStyles} due to
 * `InputBaseProps.disableInjectingGlobalStyles` being defaulted to true
 * within `muiInputBaseStyleOverrides` for `UnifyThemeProvider`.
 *
 * @see https://github.com/mui/material-ui/issues/34436#issuecomment-1256208729
 */
const inputBaseGlobalStyles = {
  '@keyframes mui-auto-fill': { from: { display: 'block' } },
  '@keyframes mui-auto-fill-cancel': { from: { display: 'block' } },
};

/**
 * A named component to make it easier to identfy the presence of these
 * critical global styles in the React Dev Tools.
 */
const UnifyGlobalStyles = () => {
  return <MuiGlobalStyles styles={inputBaseGlobalStyles} />;
};

/**
 * Kickstart an elegant, consistent, and simple Unify baseline to build upon by
 * including this component **once** in the root of your application.
 */
export const CssBaseline: React.FC<MuiCssBaselineProps> = (inProps) => {
  const { children, ...props } = inProps;
  return (
    <MuiCssBaseline {...props}>
      <UnifyGlobalStyles />
      {children}
    </MuiCssBaseline>
  );
};

export default CssBaseline;
export type { CssBaselineProps } from '@mui/material';
