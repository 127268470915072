import type { Color as MuiColor } from '@mui/material';
import type { WkColor } from 'src/styles/colors/types';

const _red: MuiColor = {
  50: '#FFEFE7',
  100: '#FFEFE7',
  200: '#FFDFD0',
  300: '#f8a9a7',
  400: '#FF9A75',
  500: '#f57d7c',
  600: '#f15250',
  700: '#CF2D02',
  800: '#ee2724',
  900: '#a71b19',
  A100: '#FFEFE7',
  A200: '#FFDFD0',
  A400: '#FF9A75',
  A700: '#CF2D02',
};

/**
 * Use a non-wsd red instead.
 * @deprecated
 */
const red: WkColor = {
  ..._red,
  main: _red[800],
  light: _red[100],
  highlight: _red[50],
  onHighlight: _red[800],
  dark: _red[900],
};

// eslint-disable-next-line deprecation/deprecation
export default red;
