import type { Color as MuiColor } from '@mui/material';
import type { WkColor } from 'src/styles/colors/types';

const _purple: MuiColor = {
  50: '#FCEDFF',
  100: '#FCEDFF',
  200: '#F8DCFF',
  300: '#cdadd5',
  400: '#DF9AF8',
  500: '#b384c0',
  600: '#9a5bab',
  700: '#9D46BC',
  800: '#813296',
  900: '#491f60',
  A100: '#FCEDFF',
  A200: '#F8DCFF',
  A400: '#DF9AF8',
  A700: '#9D46BC',
};

/**
 * Use non-wsd purple instead.
 * @deprecated
 */
const purple: WkColor = {
  ..._purple,
  main: _purple[800],
  light: _purple[100],
  highlight: _purple[50],
  onHighlight: _purple[800],
  dark: _purple[900],
};

// eslint-disable-next-line deprecation/deprecation
export default purple;
