import { ComponentsOverrides, styled, useThemeProps } from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';
import * as React from 'react';
import {
  ExtendTypeMap,
  forwardRefToOverridableComponent,
} from 'src/utils/forwardRefToOverridableComponent';
import { Card, CardProps, CardTypeMap } from '../Card/Card';
import {
  InteractivePaper,
  interactivePaperRootOverridesResolver,
} from '../InteractivePaper/InteractivePaper';
import { type SelectableCardClassKey } from './selectableCardClasses';

declare module '@mui/material/styles' {
  interface Components<Theme = unknown> {
    SelectableCard?: {
      defaultProps?: SelectableCardProps;
      styleOverrides?: ComponentsOverrides<Theme>['SelectableCard'];
    };
  }
}

declare module '@mui/material/styles' {
  interface ComponentNameToClassKey {
    SelectableCard: SelectableCardClassKey;
  }
}

type SelectableCardOwnProps = {
  /**
   * Setting this to true will give the Card's root surface a "selected" visual treatment.
   *
   * This treatment is recommended when the entire surface is made clickable
   * via a nested `CardActionArea` component. When the surface is not clickable,
   * `props.active` should be set instead.
   *
   * Default: false
   */
  selected?: boolean;
  /**
   * Setting this to true will give the Card's root surface an "active" visual treatment.
   *
   * This treatment is recommended when the entire surface is not made clickable
   * via a nested `CardActionArea` component. When a nested `CardActionArea` is used,
   * `props.selected` should be set instead.
   *
   * Default: false
   */
  active?: boolean;
  /**
   * Unify system elevation level that determines shadow depth when `variant` is not set to `outlined`.
   * Accepts values between 0 and 6 inclusive.
   *
   * Default: 1
   */
  elevation?: number;
};

type SelectableCardTypeMap<
  AdditionalProps = unknown,
  RootComponent extends React.ElementType = typeof InteractivePaper
> = ExtendTypeMap<
  {
    props: Omit<CardTypeMap['props'], 'elevation'>;
    defaultComponent: CardTypeMap['defaultComponent'];
  },
  SelectableCardOwnProps & AdditionalProps,
  RootComponent
>;

export type SelectableCardProps<
  RootComponent extends React.ElementType = typeof InteractivePaper,
  AdditionalProps = unknown
> = OverrideProps<
  SelectableCardTypeMap<AdditionalProps, RootComponent>,
  RootComponent
>;

const SelectableCardRoot = styled(Card, {
  name: 'SelectableCard',
  slot: 'Root',
  overridesResolver: interactivePaperRootOverridesResolver,
})<CardProps>(() => ({}));

/**
 * A Card component that adds visual treatments for selected / active states.
 *
 * When using a nested `CardActionArea` component to make the entire Card clickable,
 * set `props.selected` to indicate that the Card is selected - otherwise use `props.active`.
 */
export const SelectableCard = forwardRefToOverridableComponent<
  SelectableCardTypeMap,
  SelectableCardProps
>(function SelectableCard(inProps, forwardedRef) {
  const props = useThemeProps({ props: inProps, name: 'SelectableCard' });
  const { component: consumerComponent, ...rest } = props;
  return (
    <SelectableCardRoot
      {...rest}
      as={InteractivePaper}
      component={consumerComponent}
      ref={forwardedRef}
    />
  );
});

export default SelectableCard;
