import * as React from 'react';

export type TabContextValue = {
  idPrefix: string;
  value: string | null | undefined;
} | null;

const DefaultTabContext = React.createContext<TabContextValue>(null);

function useUniquePrefix(): string {
  const [id] = React.useState(() => `mui-p-${Math.round(Math.random() * 1e5)}`);
  return id;
}

export interface TabContextProps {
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
  /**
   * The value of the currently selected `Tab`.
   */
  value: string | null | undefined;
  /**
   * An optional custom `React.Context` object that can be used to prevent
   * context collision when nesting `TabContext` components.
   */
  context?: React.Context<TabContextValue>;
}

/**
 * The React context provider that wraps around `TabList` and `TabPanel`s.
 *
 * If nested within another TabContext, a new context should be created and
 * set as the value of `props.context` on this component.
 */
export const TabContext: React.FC<TabContextProps> = (props) => {
  const { context: Context = DefaultTabContext, children, value } = props;
  const idPrefix = useUniquePrefix();

  const contextValue = React.useMemo(() => {
    return { idPrefix, value };
  }, [idPrefix, value]);

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export function useTabContext(context = DefaultTabContext): TabContextValue {
  return React.useContext(context);
}

export function getPanelId(
  context: TabContextValue,
  value: string
): string | undefined {
  if (context === null) {
    return undefined;
  }
  return `${context.idPrefix}-P-${value}`;
}

export function getTabId(
  context: TabContextValue,
  value: string
): string | undefined {
  if (context === null) {
    return undefined;
  }
  return `${context.idPrefix}-T-${value}`;
}

export default TabContext;
