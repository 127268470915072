import {
  ButtonBase,
  ButtonBaseProps,
  ButtonBaseTypeMap,
  ComponentsOverrides,
  buttonBaseClasses,
  styled,
  typographyClasses,
  useThemeProps,
} from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';
import composeClasses from '@mui/utils/composeClasses';
import clsx from 'clsx';
import * as React from 'react';
import { DisabledAncestorProvider } from 'src/context/DisabledAncestorContext/DisabledAncestorContext';
import { getThemePalette } from 'src/styles/utils';
import { globalMuiClasses } from 'src/utils/cssSelectorConstants';
import {
  ExtendTypeMap,
  forwardRefToOverridableComponent,
} from 'src/utils/forwardRefToOverridableComponent';
import {
  getPaperActionAreaUtilityClass,
  paperActionAreaClasses,
  type PaperActionAreaClassKey,
  type PaperActionAreaClasses,
} from './paperActionAreaClasses';

declare module '@mui/material/styles' {
  interface Components<Theme = unknown> {
    PaperActionArea?: {
      defaultProps?: PaperActionAreaProps;
      styleOverrides?: ComponentsOverrides<Theme>['PaperActionArea'];
    };
  }

  interface ComponentNameToClassKey {
    PaperActionArea: PaperActionAreaClassKey;
  }
}

export interface PaperActionAreaOwnProps {
  /**
   * Whether the state "layer" that decorates the element on hover, focus, etc.
   * should be hidden.
   *
   * Set to true in a situation when the action area is not clickable,
   * but you don't want the contents to appear disabled.
   *   - When set to true, no visual decorations will be shown on hover or focus.
   *   - When set to true, the element rendered will be a `div` that cannot be
   *     focused and the `role` will no longer be `button`.
   *   - This is an escape hatch for use cases where an action area needs to
   *     be clickable only under certain conditions, and you don't want to have
   *     to remount all the children each time that condition changes due to
   *     having to add / remove this action area as a wrapper of those children.
   *
   * Default: false
   */
  hideInteractionStateLayer?: boolean;
  /**
   * Override or extend the styles applied to the component.
   */
  classes?: Partial<PaperActionAreaClasses>;
  /**
   * @dart-deprecated In Dart, use `..dom.href` alongside `..component = Dom.a.elementType` instead.
   */
  href?: string;
}

export type PaperActionAreaTypeMap<
  AdditionalProps = unknown,
  RootComponent extends React.ElementType = ButtonBaseTypeMap['defaultComponent']
> = ExtendTypeMap<
  ButtonBaseTypeMap,
  AdditionalProps & PaperActionAreaOwnProps,
  RootComponent
>;

export type PaperActionAreaProps<
  RootComponent extends React.ElementType = ButtonBaseTypeMap['defaultComponent'],
  AdditionalProps = unknown
> = OverrideProps<
  PaperActionAreaTypeMap<AdditionalProps, RootComponent>,
  RootComponent
>;

type PaperActionAreaOwnerState = PaperActionAreaOwnProps & {
  disabled: boolean;
};

const usePaperActionAreaUtilityClasses = (
  ownerState: PaperActionAreaOwnerState
) => {
  const { classes, disabled, hideInteractionStateLayer } = ownerState;
  const slots = {
    root: [
      'root',
      hideInteractionStateLayer && 'hiddenInteractionStateLayer',
      !disabled && !hideInteractionStateLayer && 'visibleInteractionStateLayer',
    ],
  };

  return composeClasses(slots, getPaperActionAreaUtilityClass, classes);
};

const PaperActionAreaRoot = styled(ButtonBase, {
  name: 'PaperActionArea',
  slot: 'Root',
  overridesResolver: (props, styles) => {
    const { disabled = false } = props;
    return [
      styles.root,
      {
        [`&.${paperActionAreaClasses.hiddenInteractionStateLayer}`]:
          styles.hiddenInteractionStateLayer,
      },
      !disabled && {
        [`&.${paperActionAreaClasses.visibleInteractionStateLayer}`]:
          styles.visibleInteractionStateLayer,
      },
    ];
  },
})<ButtonBaseProps & { ownerState: PaperActionAreaOwnerState }>(
  ({ disabled = false, theme }) => {
    const { focusVisible } = globalMuiClasses;
    const palette = getThemePalette(theme);
    return {
      ...theme.typography.body1,
      display: 'block',
      textAlign: 'inherit',
      borderRadius: 'inherit', // for Safari to work https://github.com/mui/material-ui/issues/36285.
      width: '100%',
      height: '100%',
      transition: theme.transitions.create('backgroundColor', {
        duration: theme.transitions.duration.short,
      }),

      [`&.${buttonBaseClasses.disabled}`]: {
        [`&, .${typographyClasses.root}`]: {
          color: palette.text.disabled,
        },
      },

      [`&.${paperActionAreaClasses.hiddenInteractionStateLayer}`]: {
        cursor: 'initial',
        userSelect: 'text',
      },

      ...(!disabled && {
        [`&.${paperActionAreaClasses.visibleInteractionStateLayer}`]: {
          // The item is hovered, or the focused element is inside the root element
          [`&:hover, &.${paperActionAreaClasses.focusVisible}`]: {
            // TODO: Change this to layer-hover as part of the tokens work (FED-1265)
            backgroundColor: palette.action.hover,
          },

          // The focused element is the root element
          [`&.${focusVisible}, &:focus-visible`]: {
            // TODO: Change this to layer-focus as part of the tokens work (FED-1265)
            backgroundColor: palette.action.focus,
          },
        },
      }),
    };
  }
);

/**
 * Wrap the contents of an `InteractivePaper` component in a `PaperActionArea` to allow the user to interact with
 * the entire surface as a single primary action.
 *
 * The component adds visual decoration for a "state layer" when the surface is hovered, focused, etc.
 * See: <https://m3.material.io/foundations/interaction/states/state-layers>
 *
 * > **WARNING:** You must set `props.component` to 'div' and `props.role` to `undefined/null` if your
 *   `Paper` surface contains other interactive elements to maintain proper accessibility since you
 *   cannot nest button roles. You must also stop event propagation within `onClick` and `onMouseDown`
 *   on any nested button roles to prevent the click even of the paper surface from firing when a nested
 *   button is clicked.
 *
 * See the `CardActionArea` component for example usage.
 */
export const PaperActionArea = forwardRefToOverridableComponent<
  PaperActionAreaTypeMap,
  PaperActionAreaProps
>(function PaperActionArea(inProps, forwardedRef) {
  const props = useThemeProps({ props: inProps, name: 'PaperActionArea' });
  const {
    disableTouchRipple = true,
    hideInteractionStateLayer = false,
    role: roleIn,
    tabIndex: tabIndexIn = 0,
    component: componentIn,
    classes: classesProp,
    className,
    disabled = false,
    ...other
  } = props;

  const ownerState = { classesProp, disabled, hideInteractionStateLayer };
  const classes = usePaperActionAreaUtilityClasses(ownerState);

  const component = hideInteractionStateLayer
    ? componentIn !== undefined && componentIn !== 'button'
      ? componentIn
      : 'div'
    : componentIn;
  const role = hideInteractionStateLayer
    ? roleIn !== 'button'
      ? roleIn
      : undefined
    : undefined;
  const tabIndex = hideInteractionStateLayer ? -1 : tabIndexIn;

  return (
    <DisabledAncestorProvider value={disabled}>
      <PaperActionAreaRoot
        classes={classesProp}
        className={clsx(classes.root, className)}
        disableTouchRipple={disableTouchRipple}
        disabled={disabled}
        component={component}
        role={role}
        tabIndex={tabIndex}
        ownerState={ownerState}
        ref={forwardedRef}
        {...other}
      />
    </DisabledAncestorProvider>
  );
});

export default PaperActionArea;
