import {
  generateUtilityClasses,
  LinkClasses,
  linkClasses as muiLinkClasses,
} from '@mui/material';

// Module augmentation for Button classes
declare module '@mui/material/Link/linkClasses' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface LinkClasses {
    /**
     * The CSS class placed on the element that displays `props.endIcon`.
     */
    endIcon: string;
  }
}

const unifyLinkClasses: Pick<LinkClasses, 'endIcon'> = generateUtilityClasses(
  'MuiLink',
  ['endIcon']
);

const linkClasses: LinkClasses = {
  ...muiLinkClasses,
  ...unifyLinkClasses,
};

export default linkClasses;
