import { Fab as MuiFab, FabTypeMap as MuiFabTypeMap } from '@mui/material';
import React from 'react';
import {
  ExtendTypeMap,
  forwardRefToOverridableComponent,
} from 'src/utils/forwardRefToOverridableComponent';

export type FabProps = {
  /**
   * The size of the component.
   * `small` is equivalent to the dense button styling.
   * @default 'large'
   */
  size?: 'small' | 'large';
};

export type FabTypeMap<
  AdditionalProps = unknown,
  RootComponent extends React.ElementType = MuiFabTypeMap['defaultComponent']
> = ExtendTypeMap<
  {
    props: Omit<
      MuiFabTypeMap['props'],
      | 'color'
      | 'disableFocusRipple'
      | 'disableRipple'
      | 'action'
      | 'centerRipple'
      | 'disableTouchRipple'
      | 'focusRipple'
      | 'focusVisibleClassName'
      | 'TouchRippleProps'
      | 'size'
      | 'touchRippleRef'
    >;
    defaultComponent: RootComponent;
  },
  AdditionalProps & FabProps,
  RootComponent
>;

export const Fab = forwardRefToOverridableComponent<FabTypeMap, FabProps>(
  function Fab(props, ref) {
    return <MuiFab ref={ref} color="primary" {...props} />;
  }
);
