import { DialogProps } from '@mui/material';
import * as React from 'react';
import useDefaultTriggerRenderer from 'src/components/DialogTrigger/useDefaultTriggerRenderer';
import OverlayTrigger, {
  SharedOverlayTriggerProps,
} from 'src/components/OverlayTrigger/OverlayTrigger';
import {
  OverlayRenderFn,
  OverlayTriggerRenderFn,
} from 'src/components/OverlayTrigger/types';

/**
 * A component that controls the visibility of a Modal `Dialog` using
 * the element returned from `props.renderTrigger` and/or
 * via the methods found in the imperative API exposed by `props.overlayAction`.
 */
export const DialogTrigger = React.forwardRef<HTMLElement, DialogTriggerProps>(
  function DialogTrigger(props, forwardedRef) {
    const defaultTriggerRenderer = useDefaultTriggerRenderer();

    const {
      renderDialog: renderDialogProp,
      renderTrigger: renderTriggerProp = defaultTriggerRenderer,
      ...rest
    } = props;

    return (
      <OverlayTrigger
        {...rest}
        ref={forwardedRef}
        overlayVariant="dialog"
        triggerClickAction="opens"
        renderTrigger={renderTriggerProp}
        renderOverlay={renderDialogProp}
      />
    );
  }
);

export type DialogTriggerProps = SharedDialogTriggerProps;

/**
 * Props shared by both `DialogTrigger` and `FloatingDialogTrigger`.
 */
export type SharedDialogTriggerProps = Omit<
  SharedOverlayTriggerProps,
  | 'overlayVariant'
  | 'overlayPopupRole'
  | 'overlayUseCase'
  | 'overlayZIndex'
  | 'renderOverlay'
  | 'children'
  | 'modifiers'
  | 'placement'
  | 'popperOptions'
  | 'popperRef'
  | 'onPlacementUpdate'
  | 'defaultPopperModifierNames'
  | 'ClickAwayListenerProps'
  | 'PopperProps'
  | 'PopoverProps'
  | 'viewportPadding'
> &
  React.HTMLAttributes<HTMLElement> & {
    /**
     * An optional render function that returns the element that toggles the
     * visibility of the `Dialog` when clicked.
     *
     * If omitted, the dialog can still be controlled via the imperative API provided
     * by {@link SharedOverlayTriggerProps.overlayAction}.
     */
    renderTrigger?: OverlayTriggerRenderFn;
    /**
     * A render function that returns a {@link Dialog} that will have its
     * visibility controlled by the element returned by {@link renderTrigger}
     * or by the imperative API exposed by the {@link SharedOverlayTriggerProps.overlayAction} prop.
     */
    renderDialog: OverlayRenderFn<DialogProps>;
  };

export default DialogTrigger;
