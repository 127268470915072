import { generateUtilityClass } from '@mui/material';
import { unstable_generateUtilityClasses as generateUtilityClasses } from '@mui/utils';

export interface DialogSubtitleClasses {
  /** Styles applied to the root element. */
  root: string;
}

export type DialogSubtitleClassKey = keyof DialogSubtitleClasses;

export function getDialogSubtitleUtilityClass(slot: string): string {
  return generateUtilityClass('MuiDialogSubtitle', slot);
}

const dialogSubtitleClasses: DialogSubtitleClasses = generateUtilityClasses(
  'MuiDialogSubtitle',
  ['root']
);

export default dialogSubtitleClasses;
