/**
 * This file contains a custom implementation of MUI's CardActionArea component
 * so that we can have it render a Unify `PaperActionArea` component which
 * contains our system's styles for large interactive surfaces.
 */
import {
  CardActionAreaOwnProps,
  buttonBaseClasses,
  cardActionsClasses,
  cardContentClasses,
  cardHeaderClasses,
  getCardActionAreaUtilityClass,
  iconClasses,
  styled,
  useThemeProps,
} from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';
import composeClasses from '@mui/utils/composeClasses';
import clsx from 'clsx';
import * as React from 'react';
import {
  PaperActionArea,
  type PaperActionAreaTypeMap,
} from 'src/components/PaperActionArea/PaperActionArea';
import { getThemePalette } from 'src/styles/utils';
import {
  forwardRefToOverridableComponent,
  type ExtendTypeMap,
} from 'src/utils/forwardRefToOverridableComponent';

export type CardActionAreaTypeMap<
  AdditionalProps = unknown,
  RootComponent extends React.ElementType = PaperActionAreaTypeMap['defaultComponent']
> = ExtendTypeMap<
  PaperActionAreaTypeMap,
  AdditionalProps & CardActionAreaOwnProps,
  RootComponent
>;

export type CardActionAreaProps<
  RootComponent extends React.ElementType = PaperActionAreaTypeMap['defaultComponent'],
  AdditionalProps = unknown
> = OverrideProps<
  CardActionAreaTypeMap<AdditionalProps, RootComponent>,
  RootComponent
> & {
  component?: React.ElementType;
};

const useUtilityClasses = (ownerState: CardActionAreaOwnProps) => {
  const { classes } = ownerState;

  const slots = {
    root: ['root'],
    focusHighlight: ['focusHighlight'],
  };

  return composeClasses(slots, getCardActionAreaUtilityClass, classes);
};

const CardActionAreaRoot = styled(PaperActionArea, {
  name: 'MuiCardActionArea',
  slot: 'Root',
  overridesResolver: (props, styles) => styles.root,
})(({ disabled = false, disableRipple, theme }) => {
  const palette = getThemePalette(theme);
  const rippleChildPresent = !disableRipple && !disabled;
  return {
    [`&.${buttonBaseClasses.disabled}`]: {
      [`.${cardHeaderClasses.avatar} .${iconClasses.root}`]: {
        color: palette.text.disabled,
      },
    },

    /// When ripple is enabled on the child ButtonBase, an extra element is nested
    /// as the last child of the element rendered by `CardActionArea`. This causes
    /// problems with some built-in card styles that rely on selectors like `:last-child`.
    ...(rippleChildPresent && {
      // Remove bottom border that is added to CardActions when it is not the
      // last child, but it is the last div.
      [`.${cardActionsClasses.root}:last-of-type`]: {
        borderBottomWidth: '0 !important',
      },

      // Treat the last CardContent within the action area before the touch ripple
      // span as the last child.
      [`.${cardContentClasses.root}:last-of-type`]: {
        paddingBottom: theme.spacing(3),
      },
    }),
  };
});

/**
 *
 * Wrap the contents of a `Card` component in a `CardActionArea` to allow the user to interact with
 * the entire surface as a single primary action.
 *
 * > **WARNING:** You must set `props.component` to 'div' and `props.role` to `undefined/null` if your
 *   `Card` surface contains other interactive elements to maintain proper accessibility since you
 *   cannot nest button roles. You must also stop event propagation within `onClick` and `onMouseDown`
 *   on any nested button roles to prevent the click even of the paper surface from firing when a nested
 *   button is clicked.
 */
export const CardActionArea = forwardRefToOverridableComponent<
  CardActionAreaTypeMap,
  CardActionAreaProps
>(function CardActionArea(inProps, forwardedRef) {
  const props = useThemeProps({ props: inProps, name: 'MuiCardActionArea' });
  const { className, disabled, disableRipple = false, ...rest } = props;
  const classes = useUtilityClasses(props);
  return (
    <CardActionAreaRoot
      className={clsx(classes.root, className)}
      disableRipple={disableRipple}
      disabled={disabled}
      {...rest}
      ref={forwardedRef}
    />
  );
});

export default CardActionArea;
