import { ButtonClasses, styled, useThemeProps } from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';
import generateUtilityClass from '@mui/utils/generateUtilityClass';
import clsx from 'clsx';
import React from 'react';
import { getLinkColorStyles } from 'src/components/Link/linkStyleOverrides';
import { getThemePalette } from 'src/styles/utils';
import { activeButtonRoleAriaAttributes } from 'src/utils/cssSelectorConstants';
import {
  ExtendTypeMap,
  forwardRefToOverridableComponent,
} from 'src/utils/forwardRefToOverridableComponent';
import Button, { ButtonTypeMap } from 'src/wsd/components/Button/Button';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface WsdLinkButtonOwnProps {
  classes?: Partial<ButtonClasses>;
}

export type WsdLinkButtonTypeMap<
  AdditionalProps = unknown,
  RootComponent extends React.ElementType = ButtonTypeMap['defaultComponent']
> = ExtendTypeMap<
  {
    props: Omit<
      ButtonTypeMap<AdditionalProps, RootComponent>['props'],
      'color' | 'variant' | 'disableRipple' | 'disableElevation'
    >;
    defaultComponent: ButtonTypeMap<
      AdditionalProps,
      RootComponent
    >['defaultComponent'];
  },
  AdditionalProps & WsdLinkButtonOwnProps,
  RootComponent
>;

export type WsdLinkButtonProps<
  RootComponent extends React.ElementType = ButtonTypeMap['defaultComponent'],
  AdditionalProps = unknown
> = OverrideProps<
  WsdLinkButtonTypeMap<AdditionalProps, RootComponent>,
  RootComponent
>;

const WsdLinkButtonRoot = styled(Button, {
  name: 'MuiLinkButton',
  slot: 'Root',
})(function WsdLinkButtonRoot({ theme }) {
  const palette = getThemePalette(theme);
  if (!theme.isLegacyWsdTheme) {
    return {
      textDecoration: 'underline',
      ':hover': {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
      },
      ...theme.typography.body1,
      ...getLinkColorStyles({ theme }),
      [activeButtonRoleAriaAttributes]: {
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
      },
    };
  }

  return {
    color: palette.blue?.[900],
    transition: 'none',
    ':hover': {
      color: palette.blue?.main,
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
    [activeButtonRoleAriaAttributes]: {
      backgroundColor: palette.blue?.[900],
      color: '#fff',
    },
    ':disabled': {
      color: palette.blue?.[900],
    },
  };
});

export const WsdLinkButton = forwardRefToOverridableComponent<
  WsdLinkButtonTypeMap,
  WsdLinkButtonProps
>(function WsdLinkButton(inProps, forwardedRef) {
  const props = useThemeProps({ props: inProps, name: 'MuiButton' });
  const { children, className: classNameProp, ...rest } = props;
  const className = clsx(
    generateUtilityClass('MuiLinkButton', 'root'),
    classNameProp,
    props.classes?.root
  );

  return (
    <WsdLinkButtonRoot
      {...rest}
      ref={forwardedRef}
      className={className}
      disableElevation={true}
      disableRipple={true}
      variant="text"
      color="inherit"
    >
      {children}
    </WsdLinkButtonRoot>
  );
});

export default WsdLinkButton;
