import {
  AvatarGroup as MuiAvatarGroup,
  AvatarGroupProps as MuiAvatarGroupProps,
  avatarGroupClasses,
  styled,
} from '@mui/material';
import * as React from 'react';
import { AvatarProps, getHeightAndWidth } from 'src/components/Avatar/Avatar';

export type AvatarGroupProps = MuiAvatarGroupProps & {
  /**
   * The size of the component.
   * @default 'xsmall'
   */
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
};

export const AvatarGroup = React.forwardRef<HTMLDivElement, AvatarGroupProps>(
  function AvatarGroup(props, forwardedRef) {
    const { children, ...rest } = props;

    return (
      <AvatarGroupStyled ref={forwardedRef} {...rest}>
        {React.Children.map(children, (el) => {
          if (React.isValidElement(el)) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            return React.cloneElement(el as React.ReactElement<AvatarProps>, {
              size: props.size,
            });
          }
          return el;
        })}
      </AvatarGroupStyled>
    );
  }
);

const AvatarGroupStyled = styled(MuiAvatarGroup, {
  shouldForwardProp: (prop) => prop !== 'size',
})<AvatarGroupProps>(({ theme, size = 'xsmall' }) => {
  return {
    [`& .${avatarGroupClasses.avatar}`]: getHeightAndWidth(size, theme),
  };
});

export default AvatarGroup;
