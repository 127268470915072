import { FabProps, styled } from '@mui/material';
import { rootShouldForwardProp } from '@mui/material/styles/styled';
import UnifyIcons from 'src/components/UnifyIcons';
import { IconSizeName } from 'src/components/UnifyIcons/iconSize';
import { getThemePalette } from 'src/styles/utils';

export const assistantIconSizeNameByFabSize: Record<
  NonNullable<FabProps['size']>,
  Exclude<IconSizeName, 'inherit'>
> = {
  small: 'small',
  medium: 'medium',
  large: 'medium',
};

export type AssistantIconProps = {
  color?: 'default' | 'inherit';
};

/**
 * An `AutoAwesome` icon branded with colors **for the Workiva Assistant EAP experience only.**
 *
 * ------------------------- WARNING ---------------------------
 *
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 *
 *   DO NOT USE THIS ICON UNLESS YOU HAVE
 *   EXPLICIT PERMISSION TO DO SO FROM THE DESIGN SYSTEM TEAM.
 *
 *   SERIOUSLY.
 *
 *   DO NOT USE IT.
 *
 *   DO NOT COPY IT.
 *
 *   DO NOT DERIVE INSPIRATION FROM IT.
 *
 *   DO NOT IMITATE IT.
 *
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 *
 * ------------------------- /WARNING --------------------------
 */
// eslint-disable-next-line deprecation/deprecation
export const AssistantIcon = styled(UnifyIcons.AutoAwesome, {
  name: 'AssistantIcon',
  slot: 'Root',
  shouldForwardProp: (propKey: string) =>
    rootShouldForwardProp(propKey) && !['color', 'placement'].includes(propKey),
})<AssistantIconProps>(function AssistantIcon({ theme, color = 'default' }) {
  return {
    color:
      color === 'default'
        ? getThemePalette(theme).decorative?.purple?.[900]
        : 'inherit',
  };
});

export default AssistantIcon;
