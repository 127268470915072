import {
  generateUtilityClass,
  generateUtilityClasses,
  paperClasses as muiPaperClasses,
  type PaperClasses,
} from '@mui/material';

export interface InteractivePaperClasses extends PaperClasses {
  root: string;
  active: string;
  selected: string;
}

export type InteractivePaperClassKey = keyof InteractivePaperClasses;

export function getInteractivePaperUtilityClass(slot: string): string {
  return generateUtilityClass('InteractivePaper', slot);
}

export const interactivePaperClasses: InteractivePaperClasses = {
  ...muiPaperClasses,
  ...generateUtilityClasses('InteractivePaper', ['root', 'active', 'selected']),
};

export default interactivePaperClasses;
