import React from 'react';
import { AnyMaterialSymbolsIconProps } from 'src/components/MaterialSymbolsIcon/AnyMaterialSymbolsIcon';

/**
 * Props for a component that renders a {@link AnyMaterialSymbolsIcon}
 * with the `glyph` and `isFilled` props hard-coded.
 *
 * Used by {@link createMaterialSymbolsIcon} and generated icon components.
 * */
export type MaterialSymbolsIconProps = Omit<
  AnyMaterialSymbolsIconProps,
  'glyph' | 'filled'
>;

/**
 * A dummy component used to generate a Dart [MaterialSymbolsIconProps] class,
 * which is used by `createMaterialSymbolsIcon`.
 *
 * This component will throw when rendered, and should never be exported or used directly.
 *
 * @private
 */
export const DummyMaterialSymbolsIcon: React.FC<
  MaterialSymbolsIconProps
> = () => {
  throw 'This component should never be used, and is just for generating Dart bindings';
};

export default DummyMaterialSymbolsIcon;
