/* eslint-disable @typescript-eslint/no-explicit-any */
import { TransitionProps as MuiTransitionProps } from '@mui/material/transitions';
import * as React from 'react';
import {
  CSSTransition as RtgCSSTransition,
  Transition as RtgTransition,
  TransitionGroup as RtgTransitionGroup,
  SwitchTransition,
} from 'react-transition-group';
import {
  CSSTransitionClassNames,
  CSSTransitionProps as RtgCSSTransitionProps,
} from 'react-transition-group/CSSTransition';
import { SwitchTransitionProps } from 'react-transition-group/SwitchTransition';
import { TransitionProps as RtgTransitionProps } from 'react-transition-group/Transition';
import { IntrinsicTransitionGroupProps } from 'react-transition-group/TransitionGroup';

type DartTransitionCallbackHandler<T> = (
  node: HTMLElement | undefined,
  arg2: T
) => void;

// Update prop arg nullability to reflect the updates in the wrapper components.
type UpdatedTransitionProps = {
  /**
   * Add a custom transition end trigger. Called with the transitioning DOM
   * node and a done callback. Allows for more fine grained transition end
   * logic. Note: Timeouts are still used as a fallback if provided.
   */
  addEndListener?: (node: HTMLElement | undefined, done: () => void) => void;

  // For some reason omitting `addEndListener` also removes `nodeRef` so we have to add it back here.
  /**
   * A React reference to DOM element that need to transition: https://stackoverflow.com/a/51127130/4671932
   * When `nodeRef` prop is used, node is not passed to callback functions (e.g. onEnter) because user already has direct access to the node.
   * When changing `key` prop of `Transition` in a `TransitionGroup` a new `nodeRef` need to be provided to `Transition` with changed `key`
   * prop (@see https://github.com/reactjs/react-transition-group/blob/master/test/Transition-test.js).
   */
  nodeRef?: RtgTransitionProps['nodeRef'];

  /**
   * Callback fired before the "entering" status is applied. An extra
   * parameter `isAppearing` is supplied to indicate if the enter stage is
   * occurring on the initial mount
   */
  onEnter?: (node: HTMLElement | undefined, isAppearing: boolean) => void;

  /**
   * Callback fired after the "entering" status is applied. An extra parameter
   * isAppearing is supplied to indicate if the enter stage is occurring on
   * the initial mount
   */
  onEntering?: (node: HTMLElement | undefined, isAppearing: boolean) => void;

  /**
   * Callback fired after the "entered" status is applied. An extra parameter
   * isAppearing is supplied to indicate if the enter stage is occurring on
   * the initial mount
   */
  onEntered?: (node: HTMLElement | undefined, isAppearing: boolean) => void;

  // These prop types needed to be updated to work around https://github.com/reactjs/react-transition-group/issues/908
  /**
   * Callback fired before the "exiting" status is applied.
   */
  onExit?: (node: HTMLElement | undefined) => void;

  /**
   * Callback fired after the "exiting" status is applied.
   */
  onExiting?: (node: HTMLElement | undefined) => void;

  /**
   * Callback fired after the "exited" status is applied.
   */
  onExited?: (node: HTMLElement | undefined) => void;
};

// We prioritize `MuiTransitionProps` because it includes `TransitionActions` and has the correct typing for handlers/callbacks.
export type TransitionProps = Omit<
  MuiTransitionProps,
  keyof UpdatedTransitionProps
> &
  Omit<RtgTransitionProps, keyof UpdatedTransitionProps> &
  UpdatedTransitionProps;

/**
 * The Transition component lets you describe a transition from one component
 * state to another _over time_ with a simple declarative API. Most commonly
 * It's used to animate the mounting and unmounting of Component, but can also
 * be used to describe in-place transition states as well.
 *
 * By default the `Transition` component does not alter the behavior of the
 * component it renders, it only tracks "enter" and "exit" states for the components.
 * It's up to you to give meaning and effect to those states. For example we can
 * add styles to a component when it enters or exits:
 *
 * ```jsx
 * import Transition from 'react-transition-group/Transition';
 * import Box from '@mui/material/Box';
 *
 * const duration = 300;
 *
 * const defaultStyle = {
 *   transition: `opacity ${duration}ms ease-in-out`,
 *   opacity: 0,
 * }
 *
 * const transitionStyles = {
 *   entering: { opacity: 1 },
 *   entered:  { opacity: 1 },
 * };
 *
 * const Fade = ({ in: inProp }) => (
 *   <Transition in={inProp} timeout={duration}>
 *     {(state) => (
 *       <Box sx={{
 *         ...defaultStyle,
 *         ...transitionStyles[state]
 *       }}>
 *         I'm A fade Transition!
 *       </Box>
 *     )}
 *   </Transition>
 * );
 * ```
 * > Note: if you are using Dart and want to use a Function child argument you must wrap it with `allowInterop` and it expects 2 args `(state, childProps)`.
 */
export const Transition = React.forwardRef<HTMLElement, TransitionProps>(
  function Transition(props, ref) {
    const {
      timeout,
      addEndListener,
      onEnter,
      onEntering,
      onEntered,
      children,
      ...rest
    } = props;

    return (
      <RtgTransition
        {...rest}
        {...useDartifiedTransitionCallbacks(
          timeout,
          addEndListener,
          onEnter,
          onEntering,
          onEntered
        )}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
        ref={ref as any}
      >
        {Array.isArray(children) && children.length === 1
          ? children[0]
          : children}
      </RtgTransition>
    );
  }
);

// We prioritize `MuiTransitionProps` because it includes `TransitionActions` and has the correct typing for handlers/callbacks.
export type CSSTransitionProps = Omit<
  MuiTransitionProps,
  keyof UpdatedTransitionProps
> &
  Omit<RtgCSSTransitionProps, keyof UpdatedTransitionProps> &
  UpdatedTransitionProps & {
    // Re-adding this prop because it was removed for some reason by the props update above.
    /**
     * The animation `classNames` applied to the component as it enters or exits.
     * A single name can be provided and it will be suffixed for each stage: e.g.
     *
     * `classNames="fade"` applies `fade-enter`, `fade-enter-active`,
     * `fade-exit`, `fade-exit-active`, `fade-appear`, and `fade-appear-active`.
     *
     * Each individual classNames can also be specified independently like:
     *
     * ```js
     * classNames={{
     *   appear: 'my-appear',
     *   appearActive: 'my-appear-active',
     *   appearDone: 'my-appear-done',
     *   enter: 'my-enter',
     *   enterActive: 'my-enter-active',
     *   enterDone: 'my-enter-done',
     *   exit: 'my-exit',
     *   exitActive: 'my-exit-active',
     *   exitDone: 'my-exit-done'
     * }}
     * ```
     */
    classNames?: string | CSSTransitionClassNames | undefined;
  };

/**
 * The CSSTransition component is inspired by the excellent ng-animate library,
 * you should use it if you're using CSS transitions or animations. It's built
 * upon the Transition component, so it inherits all of its props.
 *
 * CSSTransition applies a pair of class names during the appear, enter,
 * and exit states of the transition. The first class is applied and
 * then a second *-active class in order to activate the CSS transition.
 * After the transition, matching *-done class names are applied to persist
 * the transition state.
 *
 * ```jsx
 * function App() {
 *   const [inProp, setInProp] = useState(false);
 *   const nodeRef = useRef(null);
 *   return (
 *     <div>
 *       <CSSTransition nodeRef={nodeRef} in={inProp} timeout={200} classNames="my-node">
 *         <div ref={nodeRef}>
 *           {"I'll receive my-node-* classes"}
 *         </div>
 *       </CSSTransition>
 *       <button type="button" onClick={() => setInProp(true)}>
 *         Click to Enter
 *       </button>
 *     </div>
 *   );
 * }
 * ```
 */
export const CSSTransition = React.forwardRef<HTMLElement, CSSTransitionProps>(
  function CSSTransition(props, ref) {
    const {
      timeout,
      addEndListener,
      onEnter,
      onEntering,
      onEntered,
      children,
      ...rest
    } = props;
    return (
      <RtgCSSTransition
        {...rest}
        {...useDartifiedTransitionCallbacks(
          timeout,
          addEndListener,
          onEnter,
          onEntering,
          onEntered
        )}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
        ref={ref as any}
      >
        {Array.isArray(children) && children.length === 1
          ? children[0]
          : children}
      </RtgCSSTransition>
    );
  }
);

// Need to override the original `TransitionGroupProps` because it had some
// generic coercion that couldn't satisfy both TS and Dart,
// as well as the `childFactory` definition they have is incorrect.
export type TransitionGroupProps = IntrinsicTransitionGroupProps & {
  children?: React.ReactNode;
  component?: React.ElementType;
  // Docs and @types definitions only mention the first `child` argument.
  childFactory?: (
    child: React.ReactElement,
    index?: number,
    children?: Array<any>
  ) => React.ReactElement;
};

/**
 * The `<TransitionGroup>` component manages a set of `<Transition>` components
 * in a list. Like with the `<Transition>` component, `<TransitionGroup>`, is a
 * state machine for managing the mounting and unmounting of components over
 * time.
 *
 * Consider the example below using the `Fade` CSS transition from before.
 * As items are removed or added to the TodoList the `in` prop is toggled
 * automatically by the `<TransitionGroup>`. You can use _any_ `<Transition>`
 * component in a `<TransitionGroup>`, not just css.
 *
 * ```jsx
 * import TransitionGroup from 'react-transition-group/TransitionGroup';
 *
 * class TodoList extends React.Component {
 *   constructor(props) {
 *     super(props)
 *     this.state = {items: ['hello', 'world', 'click', 'me']}
 *   }
 *   handleAdd() {
 *     const newItems = this.state.items.concat([
 *       prompt('Enter some text')
 *     ]);
 *     this.setState({ items: newItems });
 *   }
 *   handleRemove(i) {
 *     let newItems = this.state.items.slice();
 *     newItems.splice(i, 1);
 *     this.setState({items: newItems});
 *   }
 *   render() {
 *     return (
 *       <div>
 *         <button onClick={() => this.handleAdd()}>Add Item</button>
 *         <TransitionGroup>
 *           {this.state.items.map((item, i) => (
 *             <FadeTransition key={item}>
 *               <div>
 *                 {item}{' '}
 *                 <button onClick={() => this.handleRemove(i)}>
 *                   remove
 *                 </button>
 *               </div>
 *             </FadeTransition>
 *           ))}
 *         </TransitionGroup>
 *       </div>
 *     );
 *   }
 * }
 * ```
 *
 * Note that `<TransitionGroup>`  does not define any animation behavior!
 * Exactly _how_ a list item animates is up to the individual `<Transition>`
 * components. This means you can mix and match animations across different
 * list items.
 */
export const TransitionGroup = React.forwardRef<
  HTMLElement,
  TransitionGroupProps
>(function TransitionGroup(props, ref) {
  return (
    <RtgTransitionGroup
      {...props}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
      ref={ref as any}
    />
  );
});

// Exported for convenience
export { SwitchTransition };
export type { SwitchTransitionProps };
// The JS Component's onEnter handlers have very strange undocumented behavior that will
// put the `isAppearing` boolean as the first argument if `props.nodeRef` is set.
//
// This reorganizes the arguments so that they are always in the same position
// which allows Dart to have its typed api.
function dartifyTransitionCallback<
  // Make T's bound non-nullable
  T extends NonNullable<unknown>
>(callback: DartTransitionCallbackHandler<T>) {
  return (...args: [HTMLElement, T] | [T, undefined]) => {
    function hasTwoArgs(argsList: typeof args): argsList is [HTMLElement, T] {
      // `T` is non-nullable, so we can test the second arg to see which it is.
      return argsList[1] != null;
    }
    // When the user provides the `props.nodeRef` the first arg `nodeOrIsAppearing` is the `isAppearing` boolean
    let node: HTMLElement | undefined;
    let arg2: T;
    if (hasTwoArgs(args)) {
      [node, arg2] = args;
    } else {
      [arg2] = args;
    }
    callback?.(node, arg2);
  };
}

// Conditionally returns each prop handler wrapped with [dartifyTransitionCallback].
const useDartifiedTransitionCallbacks = (
  timeout: TransitionProps['timeout'],
  _addEndListener?: TransitionProps['addEndListener'],
  _onEnter?: TransitionProps['onEnter'],
  _onEntering?: TransitionProps['onEntering'],
  _onEntered?: TransitionProps['onEntered']
) => {
  return {
    // Transition requires either timeout or addEndListener props, so conditionally include them.
    ...(_addEndListener === undefined
      ? {
          // Assume this is non-null in this case, just like the component does.
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          timeout: timeout!,
        }
      : {
          ...(timeout != null && { timeout }),
          addEndListener:
            dartifyTransitionCallback<() => void>(_addEndListener),
        }),
    ...(_onEnter && {
      onEnter: dartifyTransitionCallback<boolean>(_onEnter),
    }),
    ...(_onEntering && {
      onEntering: dartifyTransitionCallback<boolean>(_onEntering),
    }),
    ...(_onEntered && {
      onEntered: dartifyTransitionCallback<boolean>(_onEntered),
    }),
  };
};
