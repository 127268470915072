import * as React from 'react';
import {
  useCommittedValue,
  useLockableController,
} from 'src/components/lockable/LockableTextInputBase/hooks';
import LockableTextInputActionApi from 'src/components/lockable/LockableTextInputBase/lockable_text_input_action_api';

/**
 * The text that shows if the locked input has no value yet. When the
 * input has been edited to a non-empty value, this text will no longer
 * show.
 *
 * It is mostly exposed to use in tests.
 */
export const DEFAULT_LOCKED_DISPLAY_TEXT = 'Double-click to unlock';

interface UnlockedInputProps {
  /**
   * Handles committing the current value when the input loses
   * focus.
   */
  onBlur: React.FocusEventHandler;

  /**
   * Updates the input's value a change event is fired.
   */
  onChange: React.ChangeEventHandler;

  /**
   * Watches for important key presses that affect whether the
   * input is locked.
   */
  onKeyDown: React.KeyboardEventHandler;

  /**
   * Ensures that the input is focused on mount when it becomes
   * editable.
   */
  autoFocus: true;

  /**
   * The current value of the input.
   *
   * Because the value being passed in is for the _unlocked_ element, this value may
   * or may not be the committed value. It is the value that updates as the user
   * types, before being discarded or committed.
   */
  value: string;

  /**
   * The ref that allows for imperative controls (focus, blur) of
   * the input.
   */
  inputRef: React.Ref<HTMLInputElement | HTMLTextAreaElement | undefined>;
}

export interface LockedComponentProps {
  /**
   * Handles unlocking the component.
   *
   * A special case here may be that the component should be capable of
   * being "disabled". The {@link LockableTextInputBase} does not have a
   * concept of being disabled. Therefore, it may be desirable to conditionally
   * call this provided callback depending on whether or not it should be capable
   * of becoming unlocked.
   */
  onDoubleClick: React.MouseEventHandler<HTMLDivElement>;

  /**
   * The ref responsible for imperative handles on the locked UI (blur, focus, etc).
   *
   * This is typed as `any` to provide flexibility to apply the ref to any component
   * that makes sense for the locked implementation.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: React.Ref<any>;

  /**
   * The value that can be displayed as the locked variant content in the case that
   * {@link LockedComponentProps.value} is an empty string.
   *
   * This value is equal to {@link LockableTextInputBaseProps.lockedPlaceholder}.
   */
  placeholder: string;

  /**
   * The current value of the input.
   *
   * Consumers wrapping {@link LockableTextInputBase} can trust that this value is
   * the current committed value and decide how it should be shown the user.
   *
   * If there is no value yet, this will be any empty string. In that case, {@link LockedComponentProps.placeholder}
   * can be used.
   */
  value: string;
}

export interface LockableTextInputBaseProps {
  /**
   * Renders the "unlocked" UI, which is an editable text input.
   *
   * This should be one of MUI's input components (`InputBase`,
   * `OutlinedInput`, `TextField`, etc). The render function receives
   * props that _must_ be attached to the input in order to for the
   * component to work as expected.
   *
   * To see details on the props passed in, see {@link UnlockedInputProps}.
   */
  renderUnlockedInput: (props: UnlockedInputProps) => React.ReactElement;

  /**
   * Renders the "locked" UI, which can be anything that is meant to mirror
   * the "unlocked" version but without the ability to change the value.
   *
   * For the best UX, this should have similar height, width, and text styling
   * and the unlocked version. However, the only real constraint is that
   * it can respond to a double click event to become editable.
   *
   * Props passed into the render function should _always_ be applied to the
   * component being rendered.
   *
   * To see details on the props passed in, see {@link LockedComponentProps}.
   */
  renderLockedComponent: (props: LockedComponentProps) => React.ReactElement;

  /**
   * A callback fired when the input is put into an editable state.
   */
  onUnlock?: () => void;

  /**
   * A callback fired when the input leaves an editable state.
   */
  onLock?: () => void;

  /**
   * A callback that is fired when a commit action is fired.
   *
   * @param committedValue is the value to be committed. Note that if the
   * component is in a controlled configuration, the value won't actually be
   * tracked internally. In order for the value to be set, it must be passed
   * back in the typical controlled fashion.
   * @param prevCommittedValue is the last committed value that is being replaced
   * by `committedValue`.
   */
  onCommit?: (committedValue: string, prevCommittedValue: string) => void;

  /**
   * A callback that is fired when a discard action is fired.
   *
   * @param discardedVal is the value that was uncommitted and is currently
   * being discarded.
   * @param prevCommittedValue is the last committed value that is being reverted
   * to.
   */
  onDiscard?: (discardedVal: string, prevCommittedValue: string) => void;

  /**
   * The underlying committed value for the input.
   *
   * Setting this puts the input's value into a controlled configuration
   * and relies on the component's parent to continue updating the value
   * (with the help of `onCommitAttempt`).
   *
   * This should not be set and unset, as that can cause unexpected behavior.
   * Once in a controlled configuration, it should remain that way.
   */
  value?: string;

  /**
   * The underlying locked state of the input.
   *
   * Setting this puts the input's locked state into a controlled configuration
   * and relies on the component's parent to continue updating the locked status
   * (with the help on `onCommitAttempt`).
   *
   * This should not be set and unset, as that can cause unexpected behavior.
   * Once in a controlled configuration, it should remain that way.
   */
  isLocked?: boolean;

  /**
   * The text to show when the input is locked and there is no
   * committed value yet.
   *
   * __NOTE:__ This does not affect the unlocked variant in any way because it
   * is only displayed by the locked variant. If you would like to set the unlocked
   * placeholder, pass the placeholder text directly to the unlocked input.
   */
  lockedPlaceholder?: string;

  /**
   * The imperative handle that can be used to interact with the currently shown
   * element, whether that be the locked or unlocked version.
   */
  // TODO (FED-189) update the doc comment with a link to a design system article with usage examples
  lockableAction?: React.Ref<LockableTextInputActionApi | undefined>;
}

/**
 * A base wrapper for text inputs that should have a "locked" and an "unlocked"
 * version.
 *
 * Locked means that the input cannot be edited until actively unlocked. An input
 * becomes unlocked by double clicking on the locked version. When locked, the last
 * committed value will be shown. If there is no committed value, a placeholder
 * (decided by {@link LockableTextInputBaseProps.lockedPlaceholder}) will show instead.
 *
 * Unlocked means that the underlying text input can be edited. An edit must be
 * committed in order to be saved into state. Committing happens when the input is
 * blurred or the `Enter` key is pressed. Pressing `Escape` will discard and lock
 * the input.
 *
 * The component exposes imperative handles on its ref to interact with both the
 * unlocked and locked components. It can also be used to toggle whether the
 * component is locked or not. See {@link LockableTextInputBaseProps.lockableAction}
 * for more details.
 *
 * Both the locked state and the committed value can be controlled via their
 * respective props. Once in the controlled configuration, for either or both
 * cases, the component should not return to _uncontrolled_. This will cause
 * unexpected behaviors. While in the controlled setting for editing, the
 * imperative actions cannot be used to toggle editability.
 */
const LockableTextInputBase: React.FC<LockableTextInputBaseProps> = ({
  onLock,
  onUnlock,
  onCommit,
  onDiscard,
  isLocked: isLockedControlledValue,
  value: controlledValue,
  renderUnlockedInput,
  renderLockedComponent,
  lockableAction,
  lockedPlaceholder = DEFAULT_LOCKED_DISPLAY_TEXT,
}) => {
  const { isLocked, setIsLocked } = useLockableController({
    isLocked: isLockedControlledValue,
    onUnlock,
    onLock: () => {
      onLock?.();
      if (isLockedControlledValue !== undefined) {
        forceCommit();
      }
    },
    name: 'LockableTextInputBase',
    state: 'LockableTextInputBase.isLocked',
  });
  const { displayValue, setUncommittedValue, forceCommit, handleKeyDown } =
    useCommittedValue({
      value: controlledValue,
      keybindConfig: {
        Escape: false,
        Enter: true,
      },
      onCommit: (committedValue, prevCommittedValue) => {
        onCommit?.(committedValue, prevCommittedValue);
        setIsLocked(true);
      },
      onDiscard: (discaredValue, prevCommittedValue) => {
        onDiscard?.(discaredValue, prevCommittedValue);
        setIsLocked(true);
      },
      name: 'LockableTextInputBase',
      state: 'LockableTextInputBase.value',
    });

  const unlockedRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>();
  const lockedRef = React.useRef<HTMLElement>();

  React.useImperativeHandle(
    lockableAction,
    () => ({
      focus: () => {
        if (isLocked) {
          lockedRef.current?.focus();
        } else {
          unlockedRef.current?.focus();
        }
      },
      blur: () => {
        if (isLocked) {
          lockedRef.current?.blur();
        } else {
          unlockedRef.current?.blur();
        }
      },
      value: displayValue,
      isLocked,
      inputEl: unlockedRef.current,
      unlock: () => setIsLocked(false),
      lock: () => setIsLocked(true),
    }),
    [isLocked, displayValue, setIsLocked]
  );

  if (!isLocked) {
    return renderUnlockedInput({
      onBlur: () => {
        forceCommit();
      },
      autoFocus: true,
      value: displayValue,
      inputRef: unlockedRef,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setUncommittedValue(e.target.value);
      },
      onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
        handleKeyDown(e);
      },
    });
  }

  return renderLockedComponent({
    onDoubleClick: () => {
      setIsLocked(false);
    },
    ref: lockedRef,
    placeholder: lockedPlaceholder,
    value: displayValue,
  });
};

export default LockableTextInputBase;
