import { iconClasses, styled, Theme, useThemeProps } from '@mui/material';
import {
  default as MuiAvatar,
  AvatarTypeMap as MuiAvatarTypeMap,
} from '@mui/material/Avatar';
import { OverrideProps } from '@mui/material/OverridableComponent';
import * as React from 'react';
import {
  ExtendTypeMap,
  forwardRefToOverridableComponent,
} from 'src/utils/forwardRefToOverridableComponent';

export function getHeightAndWidth(size: string, theme: Theme): object {
  return {
    ...(size === 'xsmall' && {
      ...theme.typography.caption,
      height: '24px',
      width: '24px',
      [`& > .${iconClasses.root}`]: {
        fontSize: '20px',
      },
    }),
    ...(size === 'small' && {
      ...theme.typography.body1,
      height: '40px',
      width: '40px',
    }),
    ...(size === 'medium' && {
      ...theme.typography.body1,
      height: '48px',
      width: '48px',
    }),
    ...(size === 'large' && {
      ...theme.typography.h5,
      fontWeight: theme.typography.fontWeightRegular,
      height: '56px',
      width: '56px',
    }),
    ...(size === 'xlarge' && {
      ...theme.typography.h4,
      fontWeight: theme.typography.fontWeightRegular,
      height: '64px',
      width: '64px',
    }),
  };
}

export interface AvatarOwnProps {
  /**
   * The size of the component.
   * @default 'xsmall'
   */
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';

  /**
   * An arbitrary string that, if specified, will be used to automatically select a color to use for the Avatar's background and text.
   *
   * The specific color chosen will always be the same for equal strings, but that color may change across different versions of this library.
   *
   * For instance, when rendering an Avatar that represents a user, this can be set to the user's ID.
   *
   * If this prop is not set, the Avatar will stay the default color.
   */
  colorId?: string;
}

export type AvatarTypeMap<
  AdditionalProps = unknown,
  RootComponent extends React.ElementType = MuiAvatarTypeMap['defaultComponent']
> = ExtendTypeMap<
  MuiAvatarTypeMap,
  AdditionalProps & AvatarOwnProps,
  RootComponent
>;

export type AvatarProps<
  RootComponent extends React.ElementType = MuiAvatarTypeMap['defaultComponent'],
  AdditionalProps = unknown
> = OverrideProps<AvatarTypeMap<AdditionalProps, RootComponent>, RootComponent>;

export const Avatar = forwardRefToOverridableComponent<
  AvatarTypeMap,
  AvatarProps
>(function Avatar(inProps, forwardedRef) {
  const props = useThemeProps({ props: inProps, name: 'MuiAvatar' });
  const { ...rest } = props;

  return <AvatarStyled ref={forwardedRef} {...rest}></AvatarStyled>;
});

type AvatarColor = { color: string; backgroundColor: string };

// This list will be moved into the theme in https://jira.atl.workiva.net/browse/FED-1205
const avatarColors = [
  // These colors are from the avatar token list: https://unify.workiva.org/foundations/colors?tab=tokens#avatar-tokens
  // They are hard coded here to they can be used without the theme and as a part of getAvatarColor
  {
    // token: avatar-seafoam-background
    backgroundColor: '#DFFFD0',
    // token: text-default
    color: '#383838',
  },
  {
    // token: avatar-teal-background
    backgroundColor: '#00716A',
    // token: text-onColor
    color: '#FFFFFF',
  },
  {
    // token: avatar-slate-background
    backgroundColor: '#EBEBEB',
    // token: text-default
    color: '#383838',
  },
  {
    // token: avatar-gray-background
    backgroundColor: '#383838',
    // token: text-onColor
    color: '#FFFFFF',
  },
  {
    // token: avatar-sky-background
    backgroundColor: '#C2E2FF',
    // token: text-default
    color: '#383838',
  },
  {
    // token: avatar-darkblue-background
    backgroundColor: '#002C52',
    // token: text-onColor
    color: '#FFFFFF',
  },
  {
    // token: avatar-tan-background
    backgroundColor: '#FFF0D8',
    // token: text-default
    color: '#383838',
  },
  {
    // token: avatar-olive-background
    backgroundColor: '#715100',
    // token: text-onColor
    color: '#FFFFFF',
  },
  {
    // token: avatar-lavender-background
    backgroundColor: '#EBDFF6',
    // token: text-default
    color: '#383838',
  },
  {
    // token: avatar-purple-background
    backgroundColor: '#552380',
    // token: text-onColor
    color: '#FFFFFF',
  },
  {
    // token: avatar-pink-background
    backgroundColor: '#F0CADE',
    // token: text-default
    color: '#383838',
  },
  {
    // token: avatar-lipstick-background
    backgroundColor: '#C22A7C',
    // token: text-onColor
    color: '#FFFFFF',
  },
  {
    // token: avatar-ivy-background
    backgroundColor: '#E5E5BF',
    // token: text-default
    color: '#383838',
  },
  {
    // token: avatar-forest-background
    backgroundColor: '#465F02',
    // token: text-onColor
    color: '#FFFFFF',
  },
  {
    // token: avatar-rain-background
    backgroundColor: '#C6CFE1',
    // token: text-default
    color: '#383838',
  },
  {
    // token: avatar-indigo-background
    backgroundColor: '#1C4086',
    // token: text-onColor
    color: '#FFFFFF',
  },
  {
    // token: avatar-sand-background
    backgroundColor: '#D4C8BF',
    // token: text-default
    color: '#383838',
  },
  {
    // token: avatar-brown-background
    backgroundColor: '#522200',
    // token: text-onColor
    color: '#FFFFFF',
  },
];

/**
 * Returns one color from [avatarColors] based on the provided [colorId].
 */
export function getAvatarColor(colorId: string): AvatarColor {
  colorId = colorId.trim().toLowerCase();

  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < colorId.length; i += 1) {
    hash = colorId.charCodeAt(i) + ((hash << 5) - hash);
  }

  return avatarColors[Math.abs(parseInt(`${hash}`, 16) % avatarColors.length)];
}

export const AvatarStyled = styled(MuiAvatar, {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'colorId',
})<AvatarProps>(({ theme, size = 'xsmall', colorId }) => {
  const avatarColor = colorId ? getAvatarColor(colorId) : undefined;
  return {
    ...getHeightAndWidth(size, theme),
    ...(avatarColor && {
      color: `${avatarColor.color} !important`,
      backgroundColor: `${avatarColor.backgroundColor} !important`,
    }),
  };
});

export default Avatar;
