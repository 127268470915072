import * as React from 'react';
import { OverlayTriggerState } from 'src/components/OverlayTrigger/types';

/**
 * An internal utility hook used by `DialogTrigger` and `FloatingDialogTrigger`
 * that returns a function to be used as the default value for `renderTrigger`
 * when the consumer doesn't specify one.
 *
 * This allows these types of triggers to control the visibility of `Dialog`s
 * in a "headless" manner via an `OverlayActionApi`.
 */
function useDefaultTriggerRenderer(): (
  state: OverlayTriggerState
) => React.ReactElement {
  function defaultTriggerRenderer(
    state: OverlayTriggerState
  ): React.ReactElement {
    return <HeadlessDialogTrigger aria-expanded={state.isOpen} />;
  }

  return defaultTriggerRenderer;
}

export const headlessDialogTriggerDataAttr =
  'data-unify-default-dialog-trigger';

const HeadlessDialogTrigger = React.forwardRef<
  HTMLSpanElement,
  React.HTMLAttributes<HTMLSpanElement>
>(function HeadlessDialogTrigger(props, forwardedRef) {
  return (
    <span
      {...props}
      aria-haspopup="true"
      hidden
      ref={forwardedRef}
      {...{ [`${headlessDialogTriggerDataAttr}`]: '' }}
    />
  );
});

export default useDefaultTriggerRenderer;
