import {
  SelectClasses as MuiSelectClasses,
  generateUtilityClasses,
  selectClasses as muiSelectClasses,
} from '@mui/material';
import generateUtilityClass from '@mui/utils/generateUtilityClass';
import type { UnifyFieldSeverityClasses } from 'src/components/TextField/useUnifySeverityUtilityClasses';

// Module augmentation for SelectClasses
declare module '@mui/material/Select' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface SelectClasses extends UnifySelectClasses {}
}

export type UnifySelectClasses = UnifyFieldSeverityClasses;

export type SelectClasses = MuiSelectClasses & UnifyFieldSeverityClasses;

export type SelectClassKey = keyof SelectClasses;

export function getSelectUtilityClass(slot: string): string {
  return generateUtilityClass('MuiSelect', slot);
}

const unifySelectClasses: UnifySelectClasses = generateUtilityClasses(
  'MuiSelect',
  [
    'invalid',
    'severitySuccess',
    'severityWarning',
    'severityError',
    'adornedStartSeverityIcon',
    'adornedEndSeverityIcon',
    'severityIconRoot',
  ]
);

export const selectClasses: SelectClasses = {
  ...muiSelectClasses,
  ...unifySelectClasses,
};

export default selectClasses;
