import { styled } from '@mui/system';
import React, { useContext } from 'react';
import WsdLinkButton, {
  WsdLinkButtonProps,
} from 'src/wsd/components/WsdLinkButton/WsdLinkButton';
import { AlertContext } from '../Alert/AlertContext';

// FIXME: Update these values to consume theme tokens when available FED-1265
const hoverColors = {
  info: {
    standard: '#004C8F',
    filled: '#FFFFFFBF',
    outlined: '#004C8F',
  },
  success: {
    standard: '#2E9300',
    filled: '#FFFFFFBF',
    outlined: '#2E9300',
  },
  warning: {
    standard: '#983E00',
    filled: '#272727BF',
    outlined: '#F97300',
  },
  error: {
    standard: '#6B0000',
    filled: '#FFFFFFBF',
    outlined: '#F10000',
  },
};

const StyledWsdLinkButton = styled(WsdLinkButton, {
  shouldForwardProp: (prop) => prop !== '$hoverColor',
})<{ $hoverColor: string }>(({ $hoverColor }) => ({
  color: 'inherit',
  fontWeight: 'bold',
  textDecoration: 'underline',
  textDecorationColor: 'inherit',
  '&:hover': {
    color: `${$hoverColor} !important`,
    textDecorationColor: `${$hoverColor} !important`,
  },
  paddingLeft: '8px',
  paddingRight: '8px',
}));

export type AlertActionProps = WsdLinkButtonProps;

export const AlertAction = React.forwardRef<
  HTMLButtonElement,
  AlertActionProps
>(function AlertAction(props, ref) {
  const { variant, severity } = useContext(AlertContext);
  const hoverColor =
    severity && variant ? hoverColors[severity][variant] : 'inherit';

  return <StyledWsdLinkButton $hoverColor={hoverColor} ref={ref} {...props} />;
});

export default AlertAction;
