import {
  CircularProgressClasses,
  generateUtilityClasses,
  circularProgressClasses as muiCircularProgressClasses,
} from '@mui/material';

// Module augmentation for Button classes
declare module '@mui/material/CircularProgress/circularProgressClasses' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface CircularProgressClasses {
    /**
     * The CSS class placed on the `role="progressbar"` element when
     * `props.visibleValue` is true.
     */
    visibleValue: string;
    /**
     * The CSS class placed on the element that displays the value when `props.visibleValue` is true.
     */
    valueDisplay: string;
    /**
     * The CSS class placed on the element that wraps around both the
     * `role="progressbar"` element and the optional `ValueDisplay` element.
     */
    wrapper: string;
  }
}

const unifyCircularProgressClasses: Pick<
  CircularProgressClasses,
  'visibleValue' | 'valueDisplay' | 'wrapper'
> = generateUtilityClasses('MuiCircularProgress', [
  'visibleValue',
  'valueDisplay',
  'wrapper',
]);

const circularProgressClasses: CircularProgressClasses = {
  ...muiCircularProgressClasses,
  ...unifyCircularProgressClasses,
};

export default circularProgressClasses;
