import { CSSObject, Theme } from '@mui/material';
import { getGreyShadeColorForPaletteMode } from 'src/utils/paperAlphaUtils';
import {
  WsdBadgeColorName,
  WsdBadgePaletteColors,
  WsdBadgeVariantName,
} from 'src/wsd/components/WsdBadge/style';

export const wsdLabelPalettes: Partial<
  Record<WsdBadgeColorName, ((theme: Theme) => WsdBadgePaletteColors) | null>
> = {
  default: (theme) => ({
    bgColors: {
      default: getGreyShadeColorForPaletteMode(
        theme.palette.grey[700],
        theme.palette
      ),
    },
  }),
  primary: (theme) => ({
    bgColors: {
      default: theme.palette.primary.main,
    },
  }),
  secondary: (theme) => ({
    bgColors: {
      default: theme.palette.secondary.main,
    },
  }),
  success: (theme) => ({
    bgColors: {
      default: theme.palette.success.main,
    },
  }),
  info: (theme) => ({
    bgColors: {
      default: theme.palette.info.main,
    },
  }),
  warning: (theme) => ({
    bgColors: {
      default: theme.palette.warning.main,
    },
  }),
  error: (theme) => {
    return {
      bgColors: {
        default: theme.palette.error.main,
      },
    };
  },
};

function generateWsdLabelColorVariantStyles(
  theme: Theme,
  color: WsdBadgeColorName
): CSSObject | null {
  const getLabelPalette = wsdLabelPalettes[color];
  if (!getLabelPalette) return null;

  const { bgColors } = getLabelPalette(theme);

  return {
    backgroundColor: bgColors.default,
    color:
      theme.isLegacyWsdTheme && theme.palette.mode === 'light'
        ? '#fff'
        : theme.palette.getContrastText(bgColors.default),
  };
}

export function generateWsdLabelStyles(
  theme: Theme,
  color: WsdBadgeColorName,
  variant: WsdBadgeVariantName
): CSSObject {
  // Widen the type of `variant` to avoid warnings when type checking wsd options that don't
  // technically exist in the union, but could be passed through the Dart wrapper.
  // We can remove support for this once we stop supporting these options in Dart.
  const wsdVariant: unknown = variant;

  return {
    ...(wsdVariant === 'wsdLabel' &&
      generateWsdLabelColorVariantStyles(theme, color)),
    ...(wsdVariant === 'wsdLabelStatus' && {
      backgroundColor: getGreyShadeColorForPaletteMode(
        theme.palette.grey[100],
        theme.palette
      ),
      color: getGreyShadeColorForPaletteMode(
        theme.palette.grey[600],
        theme.palette
      ),
      borderColor: getGreyShadeColorForPaletteMode(
        theme.palette.grey[600],
        theme.palette
      ),
      textTransform: 'none',
    }),
  };
}
