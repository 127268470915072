import MuiTooltip, {
  TooltipProps as MuiTooltipProps,
} from '@mui/material/Tooltip';
import * as React from 'react';

export type TooltipProps = Omit<MuiTooltipProps, 'arrow'> & {
  /**
   * Whether the tooltip has an arrow.
   *
   * If `false`, the arrow is removed.
   *
   * @default true
   */
  arrow?: boolean;
};

// This wrapper is necessary to work around this issue: https://github.com/mui/material-ui/issues/34150
// Where there is a key warning for tooltip children in the console only when using the prod bundle.

export const Tooltip = React.forwardRef<unknown, TooltipProps>(function Tooltip(
  props,
  ref
) {
  const { children, ...rest } = props;
  return (
    <MuiTooltip {...rest} ref={ref}>
      {
        /*Clone children to add key to prevent key warning.*/
        React.cloneElement(children, { key: 'tooltip-child' })
      }
    </MuiTooltip>
  );
});

export default Tooltip;
