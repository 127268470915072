import { Theme } from '@mui/material';

export default class RemValue {
  value: string;
  number: number;

  constructor(value: string) {
    this.value = value;
    this.number = parseFloat(value.substring(0, value.indexOf('rem')));
  }

  toPxNumber(theme: Theme): number {
    return this.number * theme.typography.htmlFontSize;
  }

  toPxString(theme: Theme): string {
    return `${this.toPxNumber(theme)}px`;
  }
}
