import {
  ListItemButton,
  ListItem as MuiListItem,
  ListItemTypeMap as MuiListItemTypeMap,
  listItemSecondaryActionClasses,
  useThemeProps,
} from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';
import { styled } from '@mui/material/styles';
import React from 'react';
import { AnyProps } from 'src/types/util';
import {
  ExtendTypeMap,
  forwardRefToOverridableComponent,
} from 'src/utils/forwardRefToOverridableComponent';

export interface ListItemOwnProps {
  /**
   * @deprecated
   * Use `secondaryActions` instead.
   */
  secondaryAction?: React.ReactNode;

  /**
   * The element(s) to display at the end of ListItem.
   * When multiple actions are present, wrap them with a `ListItemSecondaryActions` component.
   */
  secondaryActions?: React.ReactNode;

  /**
   * @deprecated
   * The `focusRipple` prop no longer has any effect on `ListItem`.
   * If you need ripple effects on focus, use a nested `ListItemButton` component,
   * where you can set the `focusRipple` prop as needed.
   */
  focusRipple?: boolean;
}

type ListItemDefaultComponent = 'li';

export type ListItemTypeMap<
  AdditionalProps = unknown,
  RootComponent extends React.ElementType = MuiListItemTypeMap<
    AdditionalProps,
    ListItemDefaultComponent
  >['defaultComponent']
> = ExtendTypeMap<
  MuiListItemTypeMap<AdditionalProps, ListItemDefaultComponent>,
  AdditionalProps & ListItemOwnProps,
  RootComponent
>;

export type ListItemProps<
  RootComponent extends React.ElementType = MuiListItemTypeMap<
    AnyProps,
    ListItemDefaultComponent
  >['defaultComponent'],
  AdditionalProps = unknown
> = OverrideProps<
  ListItemTypeMap<AdditionalProps, RootComponent>,
  RootComponent
>;

const ListItemSecondaryActionsWrapper = styled('div', {
  name: 'ListItemSecondaryActionsWrapper',
  slot: 'Root',
})(({ theme }) => ({
  [`.${listItemSecondaryActionClasses.root}`]: {
    marginLeft: theme.spacing(1),
    position: 'static',
    transform: 'none',
  },
}));

export const ListItem = forwardRefToOverridableComponent<
  ListItemTypeMap,
  ListItemProps
>(function ListItem(inProps, forwardedRef) {
  const props = useThemeProps({ props: inProps, name: 'MuiListItem' });
  const { children, secondaryActions, ...other } = props;

  const secondaryActionsElement = secondaryActions ? (
    <ListItemSecondaryActionsWrapper
      className={listItemSecondaryActionClasses.root}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
      onTouchStart={(e) => {
        e.stopPropagation();
      }}
    >
      {secondaryActions}
    </ListItemSecondaryActionsWrapper>
  ) : (
    <></>
  );
  // Check if there is a ListItemButton among the children
  let hasListItemButton = false;

  // If there is a ListItemButton, we need to render it and inject the secondaryActions into it
  const renderedChildren = secondaryActions
    ? React.Children.map(children, (child) => {
        if (React.isValidElement(child) && child.type === ListItemButton) {
          hasListItemButton = true;
          return React.cloneElement(
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            child as React.ReactElement, // Cast to ReactElement for 'role' prop
            {
              role:
                // Remove button role for a11y - can't have a button within a button
                child.props.role === undefined || child.props.role === 'button'
                  ? undefined
                  : child.props.role,
            },
            child.props.children,
            secondaryActionsElement
          );
        }
        return child;
      })
    : children;

  return (
    <MuiListItem ref={forwardedRef} {...other}>
      {renderedChildren}
      {!hasListItemButton ? secondaryActionsElement : null}
    </MuiListItem>
  );
});

export default ListItem;
