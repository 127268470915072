import {
  default as MuiPagination,
  PaginationProps as MuiPaginationProps,
} from '@mui/material/Pagination';
import { UsePaginationItem } from '@mui/material/usePagination';
import * as React from 'react';

// This wrapper only exists to override the type of `getItemAriaLabel` to have a nullable `page` param to work around https://github.com/mui/material-ui/issues/43385
// Once we consume the fix for that issue, we can remove this file and export Pagination straight from MUI again.
export type PaginationProps = Omit<MuiPaginationProps, 'getItemAriaLabel'> & {
  /**
   * Accepts a function which returns a string value that provides a user-friendly name for the current page.
   * This is important for screen reader users.
   *
   * For localization purposes, you can use the provided [translations](/material-ui/guides/localization/).
   * @param {string} type The link or button type to format ('page' | 'first' | 'last' | 'next' | 'previous' | 'start-ellipsis' | 'end-ellipsis'). Defaults to 'page'.
   * @param {number} page The page number to format.
   * @param {bool} selected If true, the current page is selected.
   * @returns {string}
   */
  getItemAriaLabel?: (
    type: UsePaginationItem['type'],
    page: UsePaginationItem['page'],
    selected: boolean
  ) => string;
};

export const Pagination = React.forwardRef<HTMLElement, PaginationProps>(
  function Pagination(props, ref) {
    return <MuiPagination {...props} ref={ref} />;
  }
);
