import { useForkRef, useTheme } from '@mui/material';
import * as React from 'react';
import OverlayActionApi from 'src/components/OverlayTrigger/OverlayActionApi';
import OverlayTrigger, {
  OverlayTriggerProps,
  SharedOverlayTriggerProps,
} from 'src/components/OverlayTrigger/OverlayTrigger';
import { OverlayRenderFn } from 'src/components/OverlayTrigger/types';

/**
 * A component that renders a "trigger" that controls the visibility of a `Popper`.
 */
export const PopperTrigger = React.forwardRef<HTMLElement, PopperTriggerProps>(
  function PopperTrigger(props, forwardedRef) {
    const { spacing } = useTheme();

    const {
      overlayAction: overlayActionProp,
      renderOverlay: renderOverlayProp,
      renderTrigger: renderTriggerProp,
      disableEscapeKeyDown = false,
      overlayPopupRole = 'dialog',
      viewportPadding = parseInt(spacing(1)),
      PopperProps: PopperPropsProp = {},
      popperRef: popperRefProp,
      ...rest
    } = props;

    const internalOverlayActionRef = React.useRef<OverlayActionApi>();
    const overlayAction = useForkRef(
      internalOverlayActionRef,
      overlayActionProp
    );
    const internalPopperRef = React.useRef<HTMLDivElement>(null);
    const popperRef = useForkRef(
      internalPopperRef,
      PopperPropsProp?.ref,
      // @ts-expect-error it's fine.
      popperRefProp
    );

    const overlayRenderer = React.useCallback<OverlayRenderFn>(
      (state) => {
        return renderOverlayProp?.(state);
      },
      [renderOverlayProp]
    );

    if (renderTriggerProp === undefined) {
      return null;
    }

    return (
      <OverlayTrigger
        {...rest}
        ref={forwardedRef}
        viewportPadding={viewportPadding}
        disableEscapeKeyDown={disableEscapeKeyDown}
        overlayAction={overlayAction}
        overlayVariant="popper"
        overlayPopupRole={overlayPopupRole}
        overlayUseCase="describes"
        renderTrigger={renderTriggerProp}
        renderOverlay={overlayRenderer}
        // @ts-expect-error its fine.
        popperRef={popperRef}
      />
    );
  }
);

export type PopperTriggerProps = Omit<
  SharedOverlayTriggerProps,
  'overlayVariant' | 'children' | 'PopoverProps' | 'disableAutoFocus'
> &
  Pick<OverlayTriggerProps, 'renderTrigger'> &
  React.HTMLAttributes<HTMLElement>;

export default PopperTrigger;
