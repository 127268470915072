import { useControlled } from '@mui/material';
import { UseControlledProps } from '@mui/utils/useControlled';
import React from 'react';

/**
 * A thin wrapper around MUI's {@link useControlled} that also exposes if the state is
 * in a controlled configuration.
 */
export default function useTransparentControlled<T = unknown>(
  props: UseControlledProps<T>
): [T, (newValue: T | ((prevValue: T) => T)) => void, boolean] {
  const [value, setValue] = useControlled(props);
  const { current: isControlled } = React.useRef(
    props.controlled !== undefined
  );

  return [value, setValue, isControlled];
}
