import {
  CSSInterpolation,
  ComponentsOverrides,
  styled,
  useThemeProps,
} from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';
import composeClasses from '@mui/utils/composeClasses';
import clsx from 'clsx';
import * as React from 'react';
import { Paper, PaperProps, PaperTypeMap } from 'src/components/Paper/Paper';
import { getThemePalette } from 'src/styles/utils';
import {
  ExtendTypeMap,
  forwardRefToOverridableComponent,
} from 'src/utils/forwardRefToOverridableComponent';
import { useUnifyElevationProp } from '../../styles/elevationUtils';
import {
  InteractivePaperClassKey,
  getInteractivePaperUtilityClass,
  interactivePaperClasses,
  type InteractivePaperClasses,
} from './interactivePaperClasses';

declare module '@mui/material/styles' {
  interface Components<Theme = unknown> {
    InteractivePaper?: {
      defaultProps?: InteractivePaperProps;
      styleOverrides?: ComponentsOverrides<Theme>['InteractivePaper'];
    };
  }

  interface ComponentNameToClassKey {
    InteractivePaper: InteractivePaperClassKey;
  }
}

type InteractivePaperTypeMap<
  AdditionalProps = unknown,
  RootComponent extends React.ElementType = PaperTypeMap['defaultComponent']
> = ExtendTypeMap<
  PaperTypeMap,
  AdditionalProps & InteractivePaperOwnProps,
  RootComponent
>;

export type InteractivePaperProps<
  RootComponent extends React.ElementType = PaperTypeMap['defaultComponent'],
  AdditionalProps = unknown
> = OverrideProps<
  InteractivePaperTypeMap<AdditionalProps, RootComponent>,
  RootComponent
>;

export type InteractivePaperOwnProps = Pick<
  PaperProps,
  'variant' | 'elevation'
> & {
  /**
   * Override or extend the styles applied to the component.
   */
  classes?: Partial<InteractivePaperClasses>;

  /**
   * Setting this to true will give the paper surface a "selected" visual treatment.
   *
   * This treatment is recommended when the entire surface is made clickable
   * via a nested `PaperActionArea` component. When the surface is not clickable,
   * `props.active` should be set instead.
   *
   * Default: false
   */
  selected?: boolean;
  /**
   * Setting this to true will give the paper surface an "active" visual treatment.
   *
   * This treatment is recommended when the entire surface is not made clickable
   * via a nested `PaperActionArea` component. When a nested `PaperActionArea` is used,
   * `props.selected` should be set instead.
   *
   * Default: false
   */
  active?: boolean;
};

const useInteractivePaperUtilityClasses = (
  ownerState: InteractivePaperOwnProps
) => {
  const { classes, variant, active, selected } = ownerState;

  const slots = {
    root: ['root', variant, active && 'active', selected && 'selected'],
  };

  return composeClasses(slots, getInteractivePaperUtilityClass, classes);
};

export const interactivePaperRootOverridesResolver: (
  props: unknown,
  styles: Record<string, CSSInterpolation>
) => CSSInterpolation = (props, styles) => {
  return [
    styles.root,
    { [`&.${interactivePaperClasses.active}`]: styles.active },
    { [`&.${interactivePaperClasses.selected}`]: styles.selected },
  ];
};

const InteractivePaperRoot = styled(Paper, {
  name: 'InteractivePaper',
  slot: 'Root',
  overridesResolver: interactivePaperRootOverridesResolver,
})<{ ownerState: InteractivePaperOwnProps }>(({ ownerState, theme }) => {
  const palette = getThemePalette(theme);
  const { variant } = ownerState;
  const tokens = {
    active: {
      bdWidth: 1,
      // TODO: Change this to --Core-Input-input-hovered-border when tokens are implemented (FED-1265)
      // NOTE: This must be a fully-opaque color to prevent the elevation shadow from darkening its appearance.
      bdColor: palette.grey[600] /*palette.text.secondary*/,
    },
    selected: {
      bdWidth: 2,
      bdColor: palette.action.focusOutlineColor,
    },
  };

  return {
    transition: theme.transitions.create(['borderColor', 'outlineColor'], {
      duration: theme.transitions.duration.short,
    }),

    [`&.${interactivePaperClasses.active}`]: {
      outlineOffset: tokens.active.bdWidth * -1,

      ...(variant === 'outlined' && {
        borderColor: tokens.active.bdColor,
        // Use a combination of outline and border so we don't increase the border
        // width when toggling the active state - which will cause the content to "jump"
        // due to box model layout shift.
        outline: `${tokens.active.bdWidth - 1}px solid ${
          tokens.active.bdColor
        }`,
      }),
      ...(variant !== 'outlined' && {
        outline: `${tokens.active.bdWidth}px solid ${tokens.active.bdColor}`,
      }),
    },

    [`&.${interactivePaperClasses.selected}`]: {
      outlineOffset: tokens.selected.bdWidth * -1,

      ...(variant === 'outlined' && {
        borderColor: tokens.selected.bdColor,
        // Use a combination of outline and border so we don't increase the border
        // width when toggling the active state - which will cause the content to "jump"
        // due to box model layout shift.
        outline: `${tokens.selected.bdWidth - 1}px solid ${
          tokens.selected.bdColor
        }`,
      }),
      ...(variant !== 'outlined' && {
        outline: `${tokens.selected.bdWidth}px solid ${tokens.selected.bdColor}`,
      }),
    },
  };
});

/**
 * A Paper component that adds visual treatments for UI interactivity variations like
 * active or selected:
 *
 * 1. `active`: When set to true, `props.active` will add a visual treatement that
 *   indicates an "active" surface among a series of related surfaces.
 *     * This should only be used when the surface does not have its `selected` prop value
 *       toggled when a nested `PaperActionArea` is clicked.
 * 2. `selected`: When set to true, `props.selected` will add a visual treatement that
 *   indicates that a surface was "selected" via user interaction like click.
 *     * This should only be used when the surface is wired up by consumer logic to have
 *       its `selected` prop value toggled when a nested `PaperActionArea` is clicked.
 */
export const InteractivePaper = forwardRefToOverridableComponent<
  InteractivePaperTypeMap,
  InteractivePaperProps
>(function InteractivePaper(inProps, forwardedRef) {
  const defaultPaperProps = useThemeProps({ props: inProps, name: 'MuiPaper' });
  const props = useThemeProps({
    props: defaultPaperProps,
    name: 'InteractivePaper',
  });

  const {
    classes: classesProp,
    className,
    variant,
    elevation: unifyElevationProp = 1,
    active = false,
    selected = false,
    ...rest
  } = props;

  const unifyElevation =
    variant !== 'outlined' && (selected || active)
      ? unifyElevationProp + 1
      : unifyElevationProp;

  const muiOwnerState = {
    classes: classesProp,
    variant,
    // Do the conversion to MUI prop value here so the CSS classes are correct.
    elevation: useUnifyElevationProp(unifyElevation),
  };

  const ownerState = {
    ...muiOwnerState,
    active,
    selected,
  };

  const classes = useInteractivePaperUtilityClasses(ownerState);

  return (
    <InteractivePaperRoot
      {...muiOwnerState}
      {...rest}
      className={clsx(classes.root, className)}
      // Do NOT do the conversion to MUI prop value here since our Paper wrapper component will do that internally.
      elevation={unifyElevation}
      ref={forwardedRef}
      ownerState={ownerState}
    />
  );
});

export default InteractivePaper;
