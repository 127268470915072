import {
  Divider,
  IconButton,
  DialogTitle as MuiDialogTitle,
  DialogTitleProps as MuiDialogTitleProps,
  useThemeProps,
} from '@mui/material';
import { ModalTypeMap as MuiModalTypeMap } from '@mui/material/Modal';
import { OverrideProps } from '@mui/material/OverridableComponent';
import { visuallyHidden } from '@mui/utils';
import { useUnifyDialogContext } from 'src/components/Dialog/UnifyDialogContext';
import { DialogSubtitleProps } from 'src/components/DialogTitle/DialogSubtitle';
import DialogTitleGroup from 'src/components/DialogTitle/DialogTitleGroup';
import { UnifyIcons } from 'src/components/UnifyIcons';
import {
  ExtendTypeMap,
  forwardRefToOverridableComponent,
} from 'src/utils/forwardRefToOverridableComponent';

import * as React from 'react';

export interface DialogTitleOwnProps<
  TSubtitleElementType extends React.ElementType = 'p'
> {
  /**
   * The optional subtitle content to display beneath the title of the `Dialog`.
   */
  subtitle?: React.ReactNode;

  /**
   * The component used for the subtitle node.
   * Either a string to use a HTML element or a component.
   *
   * @default 'p'
   */
  subtitleComponent?: React.ElementType;

  /**
   * Additional props to pass along to the subtitle node when `subtitle` is set.
   */
  subtitleProps?: Partial<DialogSubtitleProps<TSubtitleElementType>>;

  /**
   * If `true`, a horizontal divider will be displayed between the title and content.
   *
   * Default: `false`
   */
  showDivider?: boolean;
}

export type DialogTitleTypeMap<
  AdditionalProps = unknown,
  RootComponent extends React.ElementType = MuiModalTypeMap['defaultComponent']
> = ExtendTypeMap<
  {
    props: MuiDialogTitleProps;
    defaultComponent: MuiModalTypeMap['defaultComponent'];
  },
  AdditionalProps & DialogTitleOwnProps,
  RootComponent
>;

export type DialogTitleProps<
  RootComponent extends React.ElementType = MuiModalTypeMap['defaultComponent'],
  AdditionalProps = unknown
> = OverrideProps<
  DialogTitleTypeMap<AdditionalProps, RootComponent>,
  RootComponent
>;

export const DialogTitle = forwardRefToOverridableComponent<
  DialogTitleTypeMap,
  DialogTitleProps
>(function DialogTitle(inProps, forwardedRef) {
  const props = useThemeProps({
    props: inProps,
    name: 'MuiDialogTitle',
  });
  const {
    dialogId,
    showCloseButton = false,
    onClose,
  } = useUnifyDialogContext();

  const {
    children,
    showDivider = false,
    subtitle,
    subtitleComponent,
    subtitleProps,
    sx: sxIn = {},
    ...rest
  } = props;

  const title = (
    <MuiDialogTitle
      {...rest}
      ref={forwardedRef}
      sx={{
        ...sxIn,
        ...(showCloseButton && {
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }),
      }}
    >
      {children}
      {showCloseButton && (
        <IconButton
          aria-label="Close Dialog"
          aria-controls={dialogId}
          // 1 unit of negative theme spacing margin to offset the built-in padding of IconButton
          // so that the DialogTitle height doesn't change when the close button is visible.
          sx={{ mt: -1, mb: -1, mr: -1 }}
          // @ts-expect-error there is currently no way to augment `closeButtonClick` as a new type of reason on `ModalOwnProps.onClick`.
          onClick={(event) => onClose?.(event, 'closeButtonClick')}
        >
          <UnifyIcons.Close />
        </IconButton>
      )}
    </MuiDialogTitle>
  );

  if (!subtitle) {
    return (
      <>
        {title}
        <Divider
          data-test-id="dialog-title-divider"
          sx={{ ...(!showDivider && visuallyHidden) }}
        />
      </>
    );
  }

  return (
    <DialogTitleGroup
      showDivider={showDivider}
      title={title}
      subtitle={subtitle}
      subtitleComponent={subtitleComponent}
      subtitleProps={subtitleProps}
    />
  );
});

export default DialogTitle;
