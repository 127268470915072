import * as React from 'react';
import type { GridToolbar } from './GridToolbar';

/**
 * GridToolbar is a sub component of datagrid.
 *
 * > ⚠️ *Warning*: This is a React.lazy component that must be wrapped with `React.Suspense`.
 */
export const GridToolbarLazy = React.lazy<typeof GridToolbar>(() =>
  import('src/browser.data_grid_pro_v7').then((module) => ({
    default: module.GridToolbar,
  }))
);
