import { useThemeProps } from '@mui/material';
import {
  default as MuiMenuItem,
  MenuItemTypeMap as MuiMenuItemTypeMap,
} from '@mui/material/MenuItem';
import { OverrideProps } from '@mui/material/OverridableComponent';
import * as React from 'react';
import {
  ExtendTypeMap,
  forwardRefToOverridableComponent,
} from 'src/utils/forwardRefToOverridableComponent';

export interface MenuItemOwnProps {
  /**
   * Can be an arbitrary object when using components like Select and AutoComplete
   * The value of the selected option.
   *
   * @dart-deprecated In Dart, use `..dom.value` instead.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;

  /**
   * @dart-deprecated In Dart, use `..dom.href` alongside `..component = Dom.a.elementType` instead.
   */
  href?: string;
}

export type MenuItemTypeMap<
  AdditionalProps = unknown,
  RootComponent extends React.ElementType = MuiMenuItemTypeMap['defaultComponent']
> = ExtendTypeMap<
  MuiMenuItemTypeMap,
  AdditionalProps & MenuItemOwnProps,
  RootComponent
>;

export type MenuItemProps<
  RootComponent extends React.ElementType = MuiMenuItemTypeMap['defaultComponent'],
  AdditionalProps = unknown
> = OverrideProps<
  MenuItemTypeMap<AdditionalProps, RootComponent>,
  RootComponent
>;

export const MenuItem = forwardRefToOverridableComponent<
  MenuItemTypeMap,
  MenuItemProps
>(function MenuItem(inProps, forwardedRef) {
  const props = useThemeProps({ props: inProps, name: 'MuiMenuItem' });
  const { children, ...rest } = props;
  return (
    <MuiMenuItem ref={forwardedRef} {...rest}>
      {children}
    </MuiMenuItem>
  );
});

export default MenuItem;
