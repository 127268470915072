import {
  Accordion as MuiAccordion,
  AccordionProps as MuiAccordionProps,
  accordionClasses,
  accordionDetailsClasses,
  accordionSummaryClasses,
  styled,
  useThemeProps,
} from '@mui/material';
import { unstable_extendSxProp as extendSxProp } from '@mui/system';
import * as React from 'react';
import {
  accordionSummaryContentHeaderSpacingAmount,
  accordionSummaryThemeSpacingAmount,
} from 'src/components/Accordion/sharedAccordionConstants';
import { useUnifyElevationProp } from 'src/styles/elevationUtils';
import { iconSizes } from '../UnifyIcons/iconSize';

export type AccordionProps = Omit<
  MuiAccordionProps,
  'expandIcon' | 'elevation'
> & {
  /**
   * The alignment to use for the expand/collapse indicator icon.
   * @default 'left'
   */
  alignment?: 'left' | 'right';
  /**
   * Unify system elevation level that determines shadow depth when `variant` is not set to `outlined`.
   * Accepts values between 0 and 6 inclusive.
   * @default 2
   */
  elevation?: number;
  /**
   * When gutters are disabled, the option show or remove dividers.
   * @default false
   */
  disableDividers?: boolean;
};

/**
 *
 * Demos:
 *
 * - [Accordion](https://mui.com/material-ui/react-accordion/)
 *
 * API:
 *
 * - [Accordion API](https://mui.com/material-ui/api/accordion/)
 * - inherits [Paper API](https://mui.com/material-ui/api/paper/)
 */
export const Accordion = React.forwardRef<HTMLDivElement, AccordionProps>(
  function Accordion(inProps, forwardedRef) {
    const themeProps = useThemeProps({
      props: inProps,
      name: 'MuiAccordion',
    });
    const props = extendSxProp(themeProps);
    const {
      children,
      variant = 'elevation',
      disableGutters = true,
      disableDividers = false,
      square = true,
      alignment = 'left',
      elevation: unifyElevation = variant === 'outlined' ? 0 : 2,
      ...rest
    } = props;

    const muiElevation = useUnifyElevationProp(unifyElevation);

    return (
      <UnifyAccordion
        disableGutters={disableGutters}
        square={square}
        elevation={muiElevation}
        disableDividers={disableDividers}
        variant={variant}
        alignment={alignment}
        ref={forwardedRef}
        {...rest}
      >
        {children}
      </UnifyAccordion>
    );
  }
);

const UnifyAccordion = styled(MuiAccordion, {
  shouldForwardProp: (prop) => prop !== 'disableDividers',
})<AccordionProps>(function UnifyAccordion({
  theme,
  disableGutters = true,
  disableDividers = false,
  variant = 'elevation',
  alignment = 'left',
}) {
  // to disable dividers, we need disable gutters
  const shouldDisableDividers = disableGutters && disableDividers;
  const spaceFromExpandIconToSummaryEdge =
    theme.spacingScalingFactor * accordionSummaryThemeSpacingAmount;
  const spaceFromContentHeaderToExpandIcon =
    theme.spacingScalingFactor * accordionSummaryContentHeaderSpacingAmount;
  const expandIconWidth = iconSizes.medium;
  const expandIconPadding = theme.spacingScalingFactor * 1.5;
  const leftAlignmentDetailsIndentation =
    spaceFromExpandIconToSummaryEdge +
    spaceFromContentHeaderToExpandIcon +
    expandIconWidth +
    expandIconPadding;
  const alignmentStyles = {
    ...(alignment === 'left' && {
      [`& .${accordionSummaryClasses.root}`]: {
        flexDirection: 'row-reverse',
      },
      // Left-align the details with the header
      [`& .${accordionDetailsClasses.root}`]: {
        paddingLeft: leftAlignmentDetailsIndentation,
      },
    }),
    ...(alignment === 'right' && {
      [`& .${accordionDetailsClasses.root}`]: {
        // Left-align the details with the header
        // (In right alignment, there is no icon to the left of the header)
        paddingLeft:
          leftAlignmentDetailsIndentation -
          (expandIconWidth + expandIconPadding),
      },
    }),
  };

  return {
    //
    // Adjacent Accordion Spacing
    //
    ...(!disableGutters && {
      [`& + &, &.${accordionClasses.expanded} + &`]: {
        marginTop: theme.spacing(accordionSummaryThemeSpacingAmount),
      },
    }),
    //
    // Custom Disabled Styling
    //
    [`&.${accordionClasses.disabled}`]: {
      backgroundColor: (theme.vars ?? theme).palette.background.paper,
    },
    //
    // Rounded Edges with Gutters
    //
    [`&.${accordionClasses.rounded}`]: {
      ...(!disableGutters && {
        borderRadius: (theme.vars ?? theme).shape.borderRadius,
      }),
    },
    //
    // Disable Dividers Styling
    //
    ...(variant === 'elevation' &&
      shouldDisableDividers && {
        [`&:not(:first-of-type)`]: {
          clipPath: 'inset(0px -10px -10px -10px)',
        },
      }),
    //
    // Horizontal Divider Customization
    //
    ...(variant === 'outlined' && {
      '&:before': {
        // Never display the horizontal divider when each accordion
        // is already displayed as an outlined paper element.
        display: 'none !important',
      },
      ...(shouldDisableDividers && {
        [`&:not(:last-of-type)`]: {
          // Avoid double divider between adjacent accordions
          // when disableGutters is true
          borderBottomWidth: 0,
        },
      }),
      ...(disableGutters && {
        [`& + &`]: {
          // Avoid double divider between adjacent accordions
          // when disableGutters is true
          borderTopWidth: 0,
        },
      }),
    }),
    //
    // Switch alignment
    //
    ...alignmentStyles,
    ...(variant === 'elevation' && {
      ...(!disableGutters && {
        '&:before': {
          // Non-outlined accordions with a gutter between adjacent
          // accordions should never display a divider
          opacity: 0,
        },
      }),
      ...(disableGutters && {
        [`&.${accordionClasses.expanded}:not(:first-of-type)`]: {
          // Non-outlined accordions without a gutter between them
          // should have a divider between adjacent accordions even
          // when it is expanded (divergence from MUI styles)
          '&:before': {
            display: 'block !important',
            opacity: '1 !important',
          },
        },
      }),
    }),
  };
});

export default Accordion;
