import type { Color as MuiColor } from '@mui/material';
import type { WkColor } from 'src/styles/colors/types';

const _orange: MuiColor = {
  50: '#FFF0D8',
  100: '#FFF0D8',
  200: '#FFE1B5',
  300: '#fddea2',
  400: '#FAA034',
  500: '#fdce74',
  600: '#fcbd45',
  700: '#B84B00',
  800: '#fbad17',
  900: '#f26c21',
  A100: '#FFF0D8',
  A200: '#FFE1B5',
  A400: '#FAA034',
  A700: '#B84B00',
};

/**
 * Use a non-wsd orange instead.
 * @deprecated
 */
const orange: WkColor = {
  ..._orange,
  main: _orange[800],
  light: _orange[100],
  highlight: _orange[50],
  onHighlight: _orange[800],
  dark: _orange[900],
};

// eslint-disable-next-line deprecation/deprecation
export default orange;
