import {
  Card as MuiCard,
  CardTypeMap as MuiCardTypeMap,
  useThemeProps,
} from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';
import * as React from 'react';
import { useUnifyElevationProp } from 'src/styles/elevationUtils';
import {
  ExtendTypeMap,
  forwardRefToOverridableComponent,
} from 'src/utils/forwardRefToOverridableComponent';

export interface CardOwnProps {
  /**
   * Unify system elevation level that determines shadow depth when `variant` is not set to `outlined`.
   * Accepts values between 0 and 6 inclusive.
   *
   * Default: 1
   */
  elevation?: number;
}

export type CardTypeMap<
  AdditionalProps = unknown,
  RootComponent extends React.ElementType = MuiCardTypeMap['defaultComponent']
> = ExtendTypeMap<
  {
    props: Omit<MuiCardTypeMap['props'], 'elevation'>;
    defaultComponent: MuiCardTypeMap['defaultComponent'];
  },
  AdditionalProps & CardOwnProps,
  RootComponent
>;

export type CardProps<
  RootComponent extends React.ElementType = MuiCardTypeMap['defaultComponent'],
  AdditionalProps = unknown
> = OverrideProps<CardTypeMap<AdditionalProps, RootComponent>, RootComponent>;

export const Card = forwardRefToOverridableComponent<CardTypeMap, CardProps>(
  function Card(inProps, forwardedRef) {
    const props = useThemeProps({ props: inProps, name: 'MuiCard' });
    const {
      children,
      elevation: unifyElevation = props.variant === 'outlined' ? 0 : 1,
      ...rest
    } = props;

    const muiElevation = useUnifyElevationProp(unifyElevation);

    return (
      <MuiCard {...rest} ref={forwardedRef} elevation={muiElevation}>
        {children}
      </MuiCard>
    );
  }
);

export default Card;
