import { areArraysEqual } from '@mui/base';
import {
  AutocompleteHighlightChangeReason,
  AutocompleteInputChangeReason,
} from '@mui/base/useAutocomplete';
import {
  AutocompleteOwnerState,
  AutocompleteRenderOptionState,
  ChipTypeMap,
  Autocomplete as MuiAutocomplete,
  type AutocompleteProps as MuiAutocompleteProps,
} from '@mui/material';
import React, { useEffect, useRef } from 'react';

function AutocompleteRoot<
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']
>(
  props: AutocompleteProps<
    Value,
    Multiple,
    DisableClearable,
    FreeSolo,
    ChipComponent
  >,
  forwardedRef: React.Ref<unknown>
): React.ReactElement {
  const {
    value: valueProp,
    renderOption,
    renderOptionWithOwnerState,
    ...rest
  } = props;

  // Work around FED-1493 by preventing new arrays with the same values
  // (which always happens when rendered from Dart, even when memoized;
  // see ticket for more info)
  // from triggering https://github.com/mui/material-ui/issues/32425.
  //
  // Note that removing this behavior would be a breaking change,
  // since consumers that don't memoize value would start getting this bad behavior.
  const lastValue = useRef(valueProp);
  const value =
    valueProp !== lastValue.current &&
    Array.isArray(valueProp) &&
    Array.isArray(lastValue.current) &&
    areArraysEqual(valueProp, lastValue.current)
      ? lastValue.current
      : valueProp;
  useEffect(() => {
    lastValue.current = value;
  }, [value]);

  return (
    <MuiAutocomplete<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>
      ref={forwardedRef}
      {...rest}
      value={value}
      {...((renderOption ?? renderOptionWithOwnerState) != null && {
        renderOption: (
          props: React.HTMLAttributes<HTMLLIElement>,
          option: Value,
          state: AutocompleteRenderOptionState,
          ownerState: AutocompleteOwnerState<
            Value,
            Multiple,
            DisableClearable,
            FreeSolo,
            ChipComponent
          >
        ) => {
          if (renderOption) {
            return renderOption(props, option, state);
          }
          if (renderOptionWithOwnerState) {
            return renderOptionWithOwnerState(props, option, state, ownerState);
          }
        },
      })}
    ></MuiAutocomplete>
  );
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const Autocomplete = React.forwardRef(AutocompleteRoot);

// This custom implementation of AutocompleteProps is used to override the `renderOption` prop signature so that
// it doesn't break existing Dart consumers and to add `renderOptionWithOwnerState` as the alternative.
export type AutocompleteProps<
  Value,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = 'div'
> = Omit<
  MuiAutocompleteProps<
    Value,
    Multiple,
    DisableClearable,
    FreeSolo,
    ChipComponent
  >,
  'renderOption' | 'onKeyDown' | 'onHighlightChange' | 'onInputChange'
> & {
  /**
   * Render the option, use `getOptionLabel` by default.
   *
   * For access to the `ownerState` as an additional arg, use `renderOptionWithOwnerState`.
   *
   * @param {object} props The props to apply on the li element.
   * @param {Value} option The option to render.
   * @param {object} state The state of each option.
   * @returns {ReactNode}
   */
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: Value,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;

  /**
   * Render the option, use `getOptionLabel` by default.
   *
   * @param {object} props The props to apply on the li element.
   * @param {Value} option The option to render.
   * @param {object} state The state of each option.
   * @param {object} ownerState The state of the Autocomplete component.
   * @returns {ReactNode}
   */
  renderOptionWithOwnerState?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: Value,
    state: AutocompleteRenderOptionState,
    ownerState: AutocompleteOwnerState<
      Value,
      Multiple,
      DisableClearable,
      FreeSolo,
      ChipComponent
    >
  ) => React.ReactNode;

  // Override the nullability of the `event` param to work around https://github.com/mui/material-ui/issues/43213
  /**
   * Callback fired when the highlight option changes.
   *
   * @param {React.SyntheticEvent} event The event source of the callback.
   * @param {Value} option The highlighted option.
   * @param {string} reason Can be: `"keyboard"`, `"auto"`, `"mouse"`, `"touch"`.
   */
  onHighlightChange?: (
    event: React.SyntheticEvent | undefined,
    option: Value | null,
    reason: AutocompleteHighlightChangeReason
  ) => void;

  // Override the nullability of the `event` param to work around https://github.com/mui/material-ui/issues/43899
  /**
   * Callback fired when the input value changes.
   *
   * @param {React.SyntheticEvent} event The event source of the callback.
   * @param {string} value The new value of the text input.
   * @param {string} reason Can be: `"input"` (user input), `"reset"` (programmatic change), `"clear"`.
   */
  onInputChange?: (
    event: React.SyntheticEvent | null,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => void;
};
