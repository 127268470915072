import MuiButtonBase, {
  ButtonBaseTypeMap as MuiButtonBaseTypeMap,
} from '@mui/material/ButtonBase';
import { OverrideProps } from '@mui/material/OverridableComponent';
import * as React from 'react';
import {
  ExtendTypeMap,
  forwardRefToOverridableComponent,
} from 'src/utils/forwardRefToOverridableComponent';

export interface ButtonBaseOwnProps {
  href?: string;
}

export type ButtonBaseTypeMap<
  AdditionalProps = unknown,
  RootComponent extends React.ElementType = MuiButtonBaseTypeMap['defaultComponent']
> = ExtendTypeMap<
  MuiButtonBaseTypeMap,
  AdditionalProps & ButtonBaseOwnProps,
  RootComponent
>;

export type ButtonBaseProps<
  RootComponent extends React.ElementType = MuiButtonBaseTypeMap['defaultComponent'],
  AdditionalProps = unknown
> = OverrideProps<
  ButtonBaseTypeMap<AdditionalProps, RootComponent>,
  RootComponent
>;

/**
 * `ButtonBase` contains as few styles as possible.
 * It aims to be a simple building block for creating a button.
 * It contains a load of style reset and some focus/ripple logic.
 *
 * Demos:
 *
 * - [Buttons](https://mui.com/material-ui/react-button/)
 *
 * API:
 *
 * - [ButtonBase API](https://mui.com/material-ui/api/button-base/)
 */
export const ButtonBase = forwardRefToOverridableComponent<
  ButtonBaseTypeMap,
  ButtonBaseProps
>(function ButtonBase(props, ref) {
  return <MuiButtonBase {...props} ref={ref} />;
});

export default ButtonBase;
