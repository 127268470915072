import { Popover, useForkRef, useTheme } from '@mui/material';
import * as React from 'react';
import OverlayActionApi from 'src/components/OverlayTrigger/OverlayActionApi';
import OverlayTrigger, {
  OverlayTriggerProps,
  SharedOverlayTriggerProps,
} from 'src/components/OverlayTrigger/OverlayTrigger';
import { OverlayRenderFn } from 'src/components/OverlayTrigger/types';

/**
 * A component that renders a "trigger" that controls the visibility of a {@link Popover}.
 */
export const PopoverTrigger = React.forwardRef<
  HTMLElement,
  PopoverTriggerProps
>(function PopoverTrigger(props, forwardedRef) {
  const { spacing } = useTheme();

  const {
    overlayAction: overlayActionProp,
    renderOverlay: renderOverlayProp,
    renderTrigger: renderTriggerProp,
    disableEscapeKeyDown = false,
    disableAutoFocus,
    overlayPopupRole = 'none',
    viewportPadding = parseInt(spacing(1)),
    PopoverProps: PopoverPropsProp = {},
    ...rest
  } = props;

  const internalOverlayActionRef = React.useRef<OverlayActionApi>();
  const overlayAction = useForkRef(internalOverlayActionRef, overlayActionProp);
  const internalPopoverRef = React.useRef<HTMLDivElement>(null);
  const popoverRef = useForkRef(internalPopoverRef, PopoverPropsProp?.ref);

  const overlayRenderer = React.useCallback<OverlayRenderFn>(
    (state) => {
      return (
        <Popover
          anchorEl={state.anchorEl}
          {...PopoverPropsProp}
          ref={popoverRef}
          disableAutoFocus={disableAutoFocus}
          open={state.isOpen}
        >
          {renderOverlayProp?.(state)}
        </Popover>
      );
    },
    [PopoverPropsProp, popoverRef, disableAutoFocus, renderOverlayProp]
  );

  if (renderTriggerProp === undefined) {
    return null;
  }

  return (
    <OverlayTrigger
      {...rest}
      ref={forwardedRef}
      viewportPadding={viewportPadding}
      disableAutoFocus={disableAutoFocus}
      disableEscapeKeyDown={disableEscapeKeyDown}
      overlayAction={overlayAction}
      overlayVariant="popover"
      overlayPopupRole={overlayPopupRole}
      overlayUseCase="describes"
      renderTrigger={renderTriggerProp}
      renderOverlay={overlayRenderer}
    />
  );
});

export type PopoverTriggerProps = Omit<
  SharedOverlayTriggerProps,
  | 'overlayVariant'
  | 'children'
  | 'modifiers'
  | 'popperOptions'
  | 'popperRef'
  | 'onPlacementUpdate'
  | 'defaultPopperModifierNames'
  | 'PopperProps'
> &
  Pick<OverlayTriggerProps, 'renderTrigger'> &
  React.HTMLAttributes<HTMLElement>;

export default PopoverTrigger;
