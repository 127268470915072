import {
  InputBaseProps,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  OutlinedInputProps,
  SelectChangeEvent,
  styled,
  useFormControl,
  useThemeProps,
} from '@mui/material';
import clsx from 'clsx';
import * as React from 'react';
import { selectClasses } from 'src/components/Select/unifySelectClasses';
import {
  UseSeveritiesHookOutProps,
  UseSeveritiesOwnProps,
  getUnifyFieldSeverityStyledOverridesResolver,
  getUnifyFieldSeverityStyles,
  useUnifySeverity,
} from 'src/components/TextField/useUnifySeverity';
import {
  useUnifySeverityUtilityClasses,
  type UnifyFieldSeverityClasses,
} from 'src/components/TextField/useUnifySeverityUtilityClasses';

declare module '@mui/material/Select' {
  export interface BaseSelectProps
    extends Omit<UseSeveritiesOwnProps, 'severityIconPlacement'> {
    /**
     * If `true`, the field is displayed in an error state.
     * @default false
     * @deprecated Use `isInvalid` instead.
     */
    error?: boolean;

    /**
     * @default primary
     * @deprecated Use `severity` / `isInvalid` instead.
     */
    color?: InputBaseProps['color'];

    /**
     * The placement of an optional "severity" icon that displays when `severity` is set to
     * `'success'`, `'warning'` or `'error'`.
     *
     * **Icons By Severity:**
     *   - **success:** `MaterialSymbolsGlyph.CheckCircle`
     *   - **warning:** `MaterialSymbolsGlyph.Warning`
     *   - **error:** `MaterialSymbolsGlyph.Error`
     *
     * @default 'none'
     */
    severityIconPlacement?: 'none' | 'start';
  }
}

export type SelectProps<T = unknown> = Omit<MuiSelectProps, 'onSelect'> &
  Pick<OutlinedInputProps, 'notched'> & {
    /**
     * Callback fired when a menu item is selected.
     *
     * @param {SelectChangeEvent<T>} event The event source of the callback.
     * You can pull out the new value by accessing `event.target.value` (any).
     * **Warning**: This is a generic event not a change event unless the change event is caused by browser autofill.
     * @param {object} [child] The react element that was selected when `native` is `false` (default).
     *
     * @dart-deprecated Use onSelect instead to avoid Dart runtime exceptions when accessing event.target.value.
     */
    onChange?: (event: SelectChangeEvent<T>, child: React.ReactNode) => void;

    /**
     * Callback fired when the input is blurred.
     *
     * Notice that the first argument (event) might be undefined
     */
    onBlur?: React.FocusEventHandler<unknown>;

    /**
     * Callback fired when a menu item is selected.
     *
     * @param {SelectChangeEvent<T>} event
     * The event source of the callback.
     * Warning: This is a generic event not a change event unless the change event is caused by browser autofill.
     *
     * @param {any} value
     * The selected value
     *
     * @param {object} [child] The react element that was selected when `native` is `false` (default).
     */
    onSelect?: (
      event: SelectChangeEvent<T>,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      child: React.ReactNode
    ) => void;
  };

const useUtilityClasses = (ownerState: UnifySelectOwnerState) =>
  useUnifySeverityUtilityClasses('MuiSelect', ownerState);

type UnifySelectOwnerState = Pick<MuiSelectProps, 'classes'> &
  Required<Pick<MuiSelectProps, 'variant' | 'disabled'>> &
  UseSeveritiesHookOutProps['ownerState'];

type UnifySelectRootProps = MuiSelectProps & {
  ownerState: Omit<UnifySelectOwnerState, 'classes'> & {
    classes: UnifyFieldSeverityClasses;
  };
};

const UnifySelectRoot = styled(MuiSelect, {
  name: 'MuiSelect',
  slot: 'Root',
  overridesResolver:
    getUnifyFieldSeverityStyledOverridesResolver(selectClasses),
})<UnifySelectRootProps>(({ theme, ownerState }) =>
  getUnifyFieldSeverityStyles(theme, {
    ...ownerState,
    componentName: 'MuiSelect',
  })
);

/**
 *
 * Demos:
 *
 * - [Select](https://mui.com/material-ui/react-select/)
 *
 * API:
 *
 * - [Select API](https://mui.com/material-ui/api/select/)
 * - inherits [OutlinedInput API](https://mui.com/material-ui/api/outlined-input/)
 */
export const Select = React.forwardRef<HTMLDivElement, SelectProps>(
  function Select(inProps, forwardedRef) {
    const muiFormControlContext = useFormControl();
    const ancestorFormControlError = muiFormControlContext?.error;

    const {
      onSelect,
      children,
      className: classNameProp,
      classes: classesProp,
      variant = 'outlined',
      // eslint-disable-next-line deprecation/deprecation
      error: errorProp = ancestorFormControlError,
      isInvalid: isInvalidProp = false,
      severity: severityProp = 'none',
      severityIconPlacement = 'none',
      disabled,
      SelectDisplayProps: SelectDisplayPropsProp,
      ...rest
    } = useThemeProps({ props: inProps, name: 'MuiSelect' });

    const {
      startAdornment,
      endAdornment,
      error,
      ownerState: severityOwnerState,
    } = useUnifySeverity(
      {
        error: errorProp,
        isInvalid: isInvalidProp,
        severity: severityProp,
        severityIconPlacement,
        disabled,
      },
      selectClasses
    );

    const ownerState: UnifySelectOwnerState = {
      ...severityOwnerState,
      variant,
      classes: classesProp,
    };

    const classes = useUtilityClasses(ownerState);
    const className = clsx(classNameProp, classes.root);

    return (
      <UnifySelectRoot
        ref={forwardedRef}
        onChange={(event, child) =>
          onSelect?.(event, event.target.value, child)
        }
        {...rest}
        className={className}
        variant={variant}
        error={error}
        disabled={disabled}
        SelectDisplayProps={{
          ...SelectDisplayPropsProp,
          'aria-invalid': ownerState.isInvalid,
          ...(errorProp && {
            className: `${SelectDisplayPropsProp?.className ?? ''} Mui-error`,
          }),
        }}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        ownerState={{ ...ownerState, classes: selectClasses }}
      >
        {children}
      </UnifySelectRoot>
    );
  }
);

export default Select;
