import * as React from 'react';
import type { DateRangeCalendar as MuiDateRangeCalendar } from 'src/date_pickers';
export { type DateRangeCalendarProps } from 'src/date_pickers';
/**
 * DateRangeCalendar lets the user select a range of dates without any input or popper / modal.
 *
 * > ⚠️ *Warning*: This is a React.lazy component that must be wrapped with `React.Suspense`.
 *
 * @deprecated Use `package:unify_ui/components/date_and_time_pickers.dart` instead. Will be removed in next major version.
 */
export const DateRangeCalendarLazy = React.lazy<typeof MuiDateRangeCalendar>(
  () =>
    import('src/browser.date_pickers').then((module) => ({
      default: module.DateRangeCalendar,
    }))
);
