import { ModalOwnProps as MuiModalOwnProps } from '@mui/material/Modal';
import * as React from 'react';

export type IUnifyDialogContext = {
  /**
   * The HTML `id` of the element with `role=dialog` rendered by the `Dialog` component.
   */
  dialogId?: string;
  /**
   * If `true`, a close button will be displayed in the title. Proxies the value of `DialogProps.onClose`.
   *
   * Default: `false`
   */
  showCloseButton: boolean;
  /**
   * Callback fired when the `Dialog` component should be closed.
   */
  onClose: MuiModalOwnProps['onClose'];
};

const UnifyDialogContext = React.createContext<IUnifyDialogContext>({
  showCloseButton: false,
  onClose: undefined,
});

/**
 * An internal context provider that is used by the DialogTitle component to know whether the close button should be visible,
 * and whether there is an `onClose` prop callback.
 * @internal
 */
export const UnifyDialogContextProvider = UnifyDialogContext.Provider;

/**
 * A React context hook that returns owner state information provided by the `Dialog` component.
 */
export function useUnifyDialogContext(): IUnifyDialogContext {
  const ctx = React.useContext(UnifyDialogContext);
  if (
    ctx.showCloseButton &&
    ctx.onClose === undefined &&
    process.env.NODE_ENV !== 'production'
  ) {
    console.error(
      'UNIFY: The onClose prop on a Dialog must be set when showCloseButton is true.'
    );
  }
  return ctx;
}
