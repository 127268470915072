import {
  ToggleButtonClasses,
  generateUtilityClasses,
  toggleButtonClasses as muiToggleButtonClasses,
} from '@mui/material';

// Module augmentation for ToggleButton classes
declare module '@mui/material/ToggleButton/toggleButtonClasses' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface ToggleButtonClasses extends UnifyToggleButtonClasses {}
}

export interface UnifyToggleButtonClasses {
  startIcon: string;
  hasChildren: string;
}

const unifyToggleButtonClasses: UnifyToggleButtonClasses =
  generateUtilityClasses('MuiToggleButton', ['startIcon', 'hasChildren']);

export const toggleButtonClasses: ToggleButtonClasses = {
  ...muiToggleButtonClasses,
  ...unifyToggleButtonClasses,
};

export default toggleButtonClasses;
