import { unstable_composeClasses as composeClasses } from '@mui/base';
import {
  ComponentsOverrides,
  styled,
  SxProps,
  TextFieldProps,
  useThemeProps,
} from '@mui/material';
import clsx from 'clsx';
import * as React from 'react';
import {
  getTextFieldUtilityClass,
  LockableTextFieldClasses,
  LockableTextFieldClassKey,
} from 'src/components/lockable/LockableTextField/lockable_text_field_classes';
import LockedTextField from 'src/components/lockable/LockableTextField/variants/LockedTextField';
import UnlockedTextField from 'src/components/lockable/LockableTextField/variants/UnlockedTextField';
import LockableTextInputBase, {
  LockableTextInputBaseProps,
} from 'src/components/lockable/LockableTextInputBase/LockableTextInputBase';
import { addTestId } from 'src/utils/addDomTestAttribute';

declare module '@mui/material/styles' {
  interface Components<Theme = unknown> {
    LockableTextField?: {
      defaultProps?: LockableTextFieldProps;
      styleOverrides: ComponentsOverrides<Theme>['LockableTextField'];
    };
  }
}

declare module '@mui/material/styles' {
  interface ComponentNameToClassKey {
    LockableTextField: LockableTextFieldClassKey;
  }
}

const useUtilityClasses = (ownerState: LockableTextFieldProps) => {
  const { classes } = ownerState;

  const slots = {
    root: ['root'],
    locked: ['locked'],
    unlocked: ['unlocked'],
  };

  return composeClasses(slots, getTextFieldUtilityClass, classes);
};

const LockableTextFieldRoot = styled('div', {
  name: 'LockableTextField',
  slot: 'Root',
  overridesResolver: (_, styles) => styles.root,
})({});

type IncomingLockableTextInputBaseProps = Omit<
  LockableTextInputBaseProps,
  'renderLockedComponent' | 'renderUnlockedInput'
>;

export type LockableTextFieldProps = IncomingLockableTextInputBaseProps &
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > &
  // These props are hand-picked to be shared between locked and unlocked items because
  // setting one should impact the other as well. Props not in this list should be set
  // via `LockedInputProps` and `UnlockedTextFieldProps`.
  Pick<
    TextFieldProps,
    | 'disabled'
    | 'size'
    | 'variant'
    | 'multiline'
    | 'margin'
    | 'maxRows'
    | 'minRows'
    | 'rows'
    | 'fullWidth'
  > & {
    /**
     * A test ID that will apply to the root component of either the
     * locked or unlocked element, whichever is showing.
     *
     * To apply a test ID to `LockableTextField`, add it directly to
     * the component usage.
     *
     * To apply a test ID to various levels of a the locked or unlocked
     * variants, use {@link LockableTextFieldProps.LockedTextFieldProps} or
     * {@link LockableTextFieldProps.UnlockedTextFieldProps}.
     */
    inputRootTestId?: string;

    /**
     * Props that will be applied to the locked text input only.
     */
    LockedTextFieldProps?: Partial<Omit<TextFieldProps, 'value'>>; // omit value to avoid type collisions

    /**
     * Props that will be applied to the unlocked text field only.
     */
    UnlockedTextFieldProps?: Partial<Omit<TextFieldProps, 'value'>>; // omit value to avoid type collisions

    /**
     * The system prop that allows for customization of the element that
     * wraps the locked and unlocked elements.
     */
    sx?: SxProps;

    /**
     * Override or extend the styles applied to the component.
     */
    classes?: Partial<LockableTextFieldClasses>;
  };

/**
 * A lockable text input that uses wraps MUI's `TextField` and requires a user to
 * double click in order to make the input editable.
 *
 * This input can be either locked or unlocked. It defaults to locked.
 * Locked means that the input cannot be edited until actively unlocked. An input
 * becomes unlocked by double clicking on the locked version. When locked, the last
 * committed value will be shown. If there is no committed value, a placeholder
 * (decided by {@link LockableTextInputBaseProps.lockedPlaceholder}) will show instead.
 *
 * Unlocked means that the underlying text input can be edited. An edit must be
 * committed in order to be saved into state. Committing happens when the input is
 * blurred or the `Enter` key is pressed. Pressing `Escape` will discard and lock
 * the input.
 *
 * The component exposes imperative handles on its ref to interact with both the
 * unlocked and locked components. It can also be used to toggle whether the
 * component is locked or not. See {@link LockableTextInputBaseProps.lockableAction}
 * for more details.
 *
 * Both the locked state and the committed value can be controlled via their
 * respective props. Once in the controlled configuration, for either or both
 * cases, the component should not return to _uncontrolled_. This will cause
 * unexpected behaviors. While in the controlled setting for editing, the
 * imperative actions cannot be used to toggle editability.
 */
export const LockableTextField = React.forwardRef<
  HTMLDivElement,
  LockableTextFieldProps
>(function LockableTextField(inProps, ref) {
  const props = useThemeProps({ props: inProps, name: 'LockableTextField' });
  const {
    // Lockable specific props
    isLocked,
    lockedPlaceholder,
    value,
    onCommit,
    onDiscard,
    onLock,
    onUnlock,
    lockableAction,

    // Shared props between locked and unlocked
    disabled,
    size,
    variant,
    multiline,
    margin,
    maxRows,
    minRows,
    rows,
    placeholder,
    fullWidth,

    // Props speific to `LockableTextField`
    LockedTextFieldProps = {},
    inputRootTestId,
    UnlockedTextFieldProps = {},

    // Pull out class name to use with `clsx`
    className,
    // everything else, to be passed on to wrapper
    ...wrapperProps
  } = props;
  const classes = useUtilityClasses(props);

  const sharedTextFieldProps = {
    disabled,
    size,
    variant,
    multiline,
    margin,
    maxRows,
    minRows,
    rows,
    placeholder,
    fullWidth,
  };

  const lockableBaseProps: IncomingLockableTextInputBaseProps = {
    isLocked,
    lockedPlaceholder,
    value,
    onCommit,
    onDiscard,
    onLock,
    onUnlock,
    lockableAction,
  };

  const testIdProp = inputRootTestId ? addTestId(inputRootTestId) : {};

  return (
    <LockableTextFieldRoot
      ref={ref}
      className={clsx(classes.root, className)}
      {...wrapperProps}
    >
      <LockableTextInputBase
        renderLockedComponent={(propsToAdd) => {
          const { onDoubleClick, ...otherRenderPropsToAdd } = propsToAdd;
          const {
            onDoubleClick: incomingOnDoubleClick,
            className: lockedClassName,
            ...otherLockTextFieldProps
          } = LockedTextFieldProps;
          return (
            <LockedTextField
              onDoubleClick={(e) => {
                if (disabled) return;
                onDoubleClick(e);
                incomingOnDoubleClick?.(e);
              }}
              className={clsx(classes.locked, lockedClassName)}
              {...testIdProp}
              {...sharedTextFieldProps}
              {...otherRenderPropsToAdd}
              {...otherLockTextFieldProps}
            />
          );
        }}
        renderUnlockedInput={(propsToAdd) => {
          const { onBlur, onChange, onKeyDown, ...otherRenderPropsToAdd } =
            propsToAdd;
          const {
            onKeyDown: incomingOnKeyDown,
            onChange: incomingOnChange,
            onBlur: incomingOnBlur,
            className: unlockedClassName,
            ...otherUnlockedTextFieldProps
          } = UnlockedTextFieldProps;

          return (
            <UnlockedTextField
              onBlur={(e) => {
                onBlur(e);
                incomingOnBlur?.(e);
              }}
              onChange={(e) => {
                onChange(e);
                incomingOnChange?.(e);
              }}
              onKeyDown={(e) => {
                onKeyDown(e);
                incomingOnKeyDown?.(e);
              }}
              className={clsx(classes.unlocked, unlockedClassName)}
              {...testIdProp}
              {...sharedTextFieldProps}
              {...otherRenderPropsToAdd}
              {...otherUnlockedTextFieldProps}
            />
          );
        }}
        {...lockableBaseProps}
      />
    </LockableTextFieldRoot>
  );
});

export default LockableTextField;
