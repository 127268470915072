import type { Color as MuiColor } from '@mui/material';
import type { WkColor } from 'src/styles/colors/types';

const _blue: MuiColor = {
  50: '#E3F5FF',
  100: '#E3F5FF',
  200: '#C7EBFD',
  300: '#99d4ff',
  400: '#69BCF7',
  500: '#66bfff',
  600: '#33a9ff',
  700: '#1269D9',
  800: '#0094ff',
  900: '#026dce',
  A100: '#E3F5FF',
  A200: '#C7EBFD',
  A400: '#69BCF7',
  A700: '#1269D9',
};

/**
 * Use a non-wsd blue instead.
 * @deprecated
 */
const blue: WkColor = {
  ..._blue,
  main: _blue[800],
  light: _blue[100],
  highlight: _blue[50],
  onHighlight: _blue[800],
  dark: _blue[900],
};

// eslint-disable-next-line deprecation/deprecation
export default blue;
