import {
  fabClasses,
  FabProps,
  GlobalStyles,
  styled,
  tabsClasses,
} from '@mui/material';
import { FabTypeMap as MuiFabTypeMap } from '@mui/material/Fab';
import { OverrideProps } from '@mui/material/OverridableComponent';
import { Theme } from '@mui/material/styles';
import { rootShouldForwardProp } from '@mui/material/styles/styled';
import * as React from 'react';
import { iconSizes } from 'src/components/UnifyIcons/iconSize';
import assistantChatFabClasses from 'src/eap/components/WorkivaAssistant/AssistantChatFab/assistantChatFabClasses';
import AssistantFab, {
  AssistantFabTypeMap,
} from 'src/eap/components/WorkivaAssistant/AssistantFab/AssistantFab';
import { assistantIconSizeNameByFabSize } from 'src/eap/components/WorkivaAssistant/AssistantIcon/AssistantIcon';
import { getThemePalette } from 'src/styles/utils';
import {
  ExtendTypeMap,
  forwardRefToOverridableComponent,
} from 'src/utils/forwardRefToOverridableComponent';

export const defaultChatFabText = 'AI';
export const defaultChatFabSize: NonNullable<FabProps['size']> = 'large';

const chatFabBottomOffset = '10vh';
const chatFabHeight = '48px';

export interface AssistantChatFabOwnProps
  extends Pick<React.HTMLAttributes<HTMLDivElement>, 'onClick'> {
  /**
   * Set to true to make the fab "expand" to become fully visible and get
   * an "active" visual treatment on the button.
   *
   * @default false
   */
  active?: boolean;
  /**
   * The text to display in the button when `props.active` is set to `true`.
   *
   * @default 'Assistant'
   */
  text?: string;
}

export type AssistantChatFabTypeMap<
  AdditionalProps = unknown,
  RootComponent extends React.ElementType = MuiFabTypeMap['defaultComponent']
> = ExtendTypeMap<
  {
    props: Omit<AssistantFabTypeMap['props'], 'active' | 'text'>;
    defaultComponent: RootComponent;
  },
  AdditionalProps & AssistantChatFabOwnProps,
  RootComponent
>;

export type AssistantChatFabProps<
  RootComponent extends React.ElementType = AssistantFabTypeMap['defaultComponent'],
  AdditionalProps = unknown
> = OverrideProps<
  AssistantChatFabTypeMap<AdditionalProps, RootComponent>,
  RootComponent
>;

const AssistantChatFabRoot = styled('div', {
  name: 'AssistantChatFab',
  slot: 'Root',
  overridesResolver: (props, styles) => {
    const { ownerState } = props;

    return [styles.root, ownerState.expand && styles.expand];
  },
  shouldForwardProp: (propKey: string) => rootShouldForwardProp(propKey),
})<
  AssistantChatFabProps & {
    ownerState: {
      expand?: boolean;
      disabled: boolean;
      size: Required<NonNullable<FabProps['size']>>;
    };
  }
>(function AssistantChatFabRoot({ theme, ownerState }) {
  const { expand = false, size } = ownerState;

  // Not available for consumption as a constant from MUI
  const fabHorizontalPaddingBySize = {
    small: theme.spacingScalingFactor,
    medium: theme.spacingScalingFactor * 2,
    large: theme.spacingScalingFactor * 2,
  };

  // The styles for this box that wraps around the `AssistantFab` adds right padding
  // in the amount that the design dictates that the button should be offset from
  // the right edge of the viewport.
  //
  // The mouseenter / leave handlers will be placed here to prevent user frustration if
  // they hover the far right edge of the screen to make the assistant button fly out -
  // leaving their cursor in the "void" to the right of the button - which will cause
  // the button to fly back out.
  return {
    cursor: 'pointer',
    position: 'fixed',
    bottom: chatFabBottomOffset,
    left: '100vw',
    right: 'auto',
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
    // Reveal enough of the fab to display the icon plus 6px of space to the right
    transform: `translateX(-${
      fabHorizontalPaddingBySize[size] +
      iconSizes[assistantIconSizeNameByFabSize[size]] +
      6
    }px)`,

    '&:active': {
      transform: 'translateX(-100%)',
    },

    ...(expand && {
      transform: 'translateX(-100%)',
    }),

    '&.Mui-focusVisible': {
      // Focus decoration is displayed on the child AssistantFab
      // via a descendant selector
      outline: 0,
    },

    [`& .${fabClasses.root}`]: {
      minWidth: 0,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      paddingRight: theme.spacing(1),
    },
  };
});

/**
 * An `AssistantFab` button that "flies out" when hovered and when the
 * Workiva Assistant chat module is visible.
 *
 * To be used **for the Workiva Assistant EAP experience only.**
 *
 * ------------------------- WARNING ---------------------------
 *
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 *
 *   DO NOT USE THIS COMPONENT UNLESS YOU HAVE
 *   EXPLICIT PERMISSION TO DO SO FROM THE DESIGN SYSTEM TEAM.
 *
 *   SERIOUSLY.
 *
 *   DO NOT USE IT.
 *
 *   DO NOT COPY IT.
 *
 *   DO NOT DERIVE INSPIRATION FROM IT.
 *
 *   DO NOT IMITATE IT.
 *
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 *
 * ------------------------- /WARNING --------------------------
 */
export const AssistantChatFab = forwardRefToOverridableComponent<
  AssistantChatFabTypeMap,
  AssistantChatFabProps
>(function AssistantChatFab(props, forwardedRef) {
  const {
    onClick,
    disabled = false,
    size = defaultChatFabSize,
    active,
    text = defaultChatFabText,
    ...rest
  } = props;
  const pinnedOpen = active;
  const [expand, setExpanded] = React.useState(pinnedOpen ?? false);

  const handleMouseEnter = React.useCallback(() => {
    setExpanded(true);
  }, []);

  const handleMouseLeave = React.useCallback(() => {
    if (pinnedOpen) return;
    setExpanded(false);
  }, [pinnedOpen]);

  return (
    <AssistantChatFabRoot
      role="presentation"
      className={assistantChatFabClasses.root}
      ownerState={{
        expand: pinnedOpen ?? expand,
        disabled: disabled,
        size: size,
      }}
      // Place click events this element instead of the child Fab
      // because of the 3 units of theme spacing to the right of
      // the fab button so that the "gutter" remains hoverable / clickable
      // to alleviate user frustration when the button slides
      // out often past where their cursor position was when the
      // expansion was initiated.
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ShellScrollAffordanceStyles />
      <AssistantFab
        {...rest}
        onFocus={handleMouseEnter}
        onBlur={handleMouseLeave}
        disabled={disabled}
        size={size}
        active={active}
        text={text}
        ref={forwardedRef}
      />
    </AssistantChatFabRoot>
  );
});

const ShellScrollAffordanceStyles = React.memo(
  function ShellScrollAffordanceStyles() {
    const whiteEdgeShadowStyles = (theme: Theme) => {
      return {
        content: '""',
        position: 'absolute',
        bottom: `calc(${sidePanelBottomPadding})`,
        left: 0,
        right: 0,
        height: 10,
        background: `linear-gradient(to top, ${
          getThemePalette(theme).background.paper
        } 0%, ${getThemePalette(theme).background.paper} 5%, transparent)`,
      };
    };

    const editorFooterHeights = {
      zesty: '33px',
      zestier: '38px',
    };
    // Bottom padding amount to be applied to the shell's side panel gutter to
    // prevent tabs within the gutter from being obstructed by the floating button.
    const sidePanelBottomPadding = `${chatFabBottomOffset} + ${chatFabHeight}`;
    const sidePanelAboveEditorFooterBottomPadding = {
      default: {
        zesty: sidePanelBottomPadding,
        zestier: sidePanelBottomPadding,
      },
      aboveEditorFooter: {
        zesty: `${sidePanelBottomPadding} - ${editorFooterHeights.zesty}`,
        zestier: `${sidePanelBottomPadding} - ${editorFooterHeights.zestier}`,
      },
    };
    const shellGutterScrollSelectors = {
      default: {
        zesty: '.shell-panel-gutter',
        zestier: `.EditorSidePanelGutter-root .${tabsClasses.root}`,
      },
      aboveEditorFooter: {
        zesty: '.shell-panel-gutter--above-status-bar',
        zestier: `.EditorSidePanelGutter-above-editor-footer .${tabsClasses.root}`,
      },
    };

    return (
      <GlobalStyles
        styles={(theme) => ({
          /* ------------- ZESTY SHELL ------------- */
          [shellGutterScrollSelectors.default.zesty]: {
            height: '100%',
            position: 'relative',
            paddingBottom: `calc(${sidePanelAboveEditorFooterBottomPadding.default.zesty}) !important`,
          },
          [shellGutterScrollSelectors.aboveEditorFooter.zesty]: {
            paddingBottom: `calc(${sidePanelAboveEditorFooterBottomPadding.aboveEditorFooter.zesty}) !important`,
          },

          /* ------------- ZESTIER SHELL ------------- */
          [shellGutterScrollSelectors.default.zestier]: {
            height: '100%',
            position: 'relative',
            paddingBottom: `calc(${sidePanelAboveEditorFooterBottomPadding.default.zestier})`,

            '&:after': whiteEdgeShadowStyles(theme),
          },
          [shellGutterScrollSelectors.aboveEditorFooter.zestier]: {
            paddingBottom: `calc(${sidePanelAboveEditorFooterBottomPadding.aboveEditorFooter.zestier})`,

            '&:after': {
              bottom: `calc(${sidePanelAboveEditorFooterBottomPadding.aboveEditorFooter.zestier})`,
            },
          },
        })}
      />
    );
  }
);

export default AssistantChatFab;
