import {
  StepLabelProps,
  capitalize,
  useTheme,
  useThemeProps,
  type StepIconProps,
} from '@mui/material';
import composeClasses from '@mui/utils/composeClasses';
import clsx from 'clsx';
import * as React from 'react';
import { MaterialSymbolsIconProps } from 'src/components/MaterialSymbolsIcon/MaterialSymbolsIcon';
import { getStepIconUtilityClass } from 'src/components/StepIcon/stepIconClasses';
import UnifyIcons from 'src/components/UnifyIcons';
import { getThemePalette } from 'src/styles/utils';
import { type StepSeverity } from './StepSeverity';

type StepIconOwnerState = Required<
  Pick<StepIconProps, 'completed' | 'active'>
> &
  Pick<StepIconProps, 'classes'>;

type UnifySeverityStepIconOwnerState = {
  severity: Required<StepLabelProps['severity']>;
};

export type UnifySeverityStepIconProps = StepIconProps & {
  ownerState: UnifySeverityStepIconOwnerState;
};

export const UnifySeverityStepIcon: React.FC<UnifySeverityStepIconProps> = (
  inProps
) => {
  const props = useThemeProps({ props: inProps, name: 'MuiStepIcon' });
  const {
    classes: classesProp,
    className: classNameProp,
    ownerState,
    completed = false,
    active = false,
    sx: sxIn,
    ...rest
  } = props;
  const { severity } = ownerState;
  const classes = useUtilityClasses({
    severity,
    completed,
    active,
    classes: classesProp,
  });
  const className = clsx(classNameProp, classes.root);
  const theme = useTheme();
  const palette = getThemePalette(theme);
  const iconComponentsAndColors: {
    [key in Exclude<StepSeverity, 'default'>]: {
      Component: React.FC<MaterialSymbolsIconProps>;
      color: string;
    };
  } = {
    error: {
      Component: UnifyIcons.ErrorFilled,
      color: palette.error.dark,
    },
    warning: {
      Component: UnifyIcons.WarningFilled,
      color: palette.warning.dark,
    },
    success: {
      Component: UnifyIcons.CheckCircleFilled,
      color: palette.success.dark,
    },
    inProgress: {
      Component: UnifyIcons.ChangeCircleFilled,
      // FIXME: Update this value to consume theme tokens when available FED-3033
      color: '#873BC9',
    },
  };

  const { Component: IconComponent, color: colorVar } =
    severity && severity !== 'default'
      ? iconComponentsAndColors[severity]
      : {
          Component: UnifyIcons.CheckCircleFilled,
          color: palette.primary.dark,
        };

  return (
    <IconComponent
      {...rest}
      ref={null} // Can't pass a div ref to a span component
      className={className}
      sx={{ ...sxIn, color: colorVar }}
    />
  );
};

const useUtilityClasses = (
  ownerState: UnifySeverityStepIconOwnerState & StepIconOwnerState
) => {
  const { classes, active, completed, severity } = ownerState;

  const slots = {
    root: [
      'root',
      active && 'active',
      completed && 'completed',
      severity && `severity${capitalize(severity)}`,
    ],
  };

  return composeClasses(slots, getStepIconUtilityClass, classes);
};

export default UnifySeverityStepIcon;
