import { useThemeProps } from '@mui/material';
import {
  default as MuiDialog,
  DialogProps as MuiDialogProps,
} from '@mui/material/Dialog';
import { ModalTypeMap as MuiModalTypeMap } from '@mui/material/Modal';
import { OverrideProps } from '@mui/material/OverridableComponent';
import useId from '@mui/utils/useId';
import * as React from 'react';
import {
  IUnifyDialogContext,
  UnifyDialogContextProvider,
} from 'src/components/Dialog/UnifyDialogContext';
import {
  ExtendTypeMap,
  forwardRefToOverridableComponent,
} from 'src/utils/forwardRefToOverridableComponent';

export interface DialogOwnProps {
  /**
   * If `true`, a close button will be displayed in the title.
   *
   * Default: `false`
   */
  showCloseButton?: boolean;
}

export type DialogTypeMap<
  AdditionalProps = unknown,
  RootComponent extends React.ElementType = MuiModalTypeMap['defaultComponent']
> = ExtendTypeMap<
  {
    props: MuiDialogProps;
    defaultComponent: MuiModalTypeMap['defaultComponent'];
  },
  AdditionalProps & DialogOwnProps,
  RootComponent
>;

export type DialogProps<
  RootComponent extends React.ElementType = MuiModalTypeMap['defaultComponent'],
  AdditionalProps = unknown
> = OverrideProps<DialogTypeMap<AdditionalProps, RootComponent>, RootComponent>;

/**
 * Dialogs are overlaid modal paper based components with a backdrop.
 *
 * Demos:
 *
 * - [Dialog](https://mui.com/material-ui/react-dialog/)
 *
 * API:
 *
 * - [Dialog API](https://mui.com/material-ui/api/dialog/)
 * - inherits [Modal API](https://mui.com/material-ui/api/modal/)
 */
export const Dialog = forwardRefToOverridableComponent<
  DialogTypeMap,
  DialogProps
>(function Dialog(inProps, forwardedRef) {
  const props = useThemeProps({ props: inProps, name: 'MuiDialog' });
  const {
    children,
    onClose,
    PaperProps: PaperPropsProp = {},
    showCloseButton = false,
    ...rest
  } = props;
  const dialogId = useId(PaperPropsProp.id);
  const unifyDialogContextValue: IUnifyDialogContext = React.useMemo(() => {
    return {
      dialogId,
      showCloseButton,
      onClose,
    };
  }, [dialogId, showCloseButton, onClose]);
  const PaperProps = {
    ...PaperPropsProp,
    id: dialogId,
  };

  return (
    <MuiDialog
      PaperProps={PaperProps}
      onClose={onClose}
      ref={forwardedRef}
      {...rest}
    >
      <UnifyDialogContextProvider value={unifyDialogContextValue}>
        {children}
      </UnifyDialogContextProvider>
    </MuiDialog>
  );
});

export default Dialog;
