import {
  StepLabel as MuiStepLabel,
  StepContext,
  StepContextType,
  StepLabelProps,
  stepLabelClasses,
  styled,
  useTheme,
  useThemeProps,
  type StepIconProps,
} from '@mui/material';
import * as React from 'react';
import { getThemePalette } from '../../styles/utils';
import { getMuiName, setMuiName } from '../../utils/mui_name';
import { type StepSeverity } from './StepSeverity';
import { UnifySeverityStepIcon } from './UnifySeverityStepIcon';

// Module augmentation for StepLabelProps
declare module '@mui/material/StepLabel/StepLabel' {
  interface StepLabelProps {
    /**
     * @deprecated
     * Use `severity` to communicate that a step has an `error` instead.
     */
    error?: boolean;

    /**
     * The severity of the step.
     * Renders a different icon based on the severity.
     */
    severity?: StepSeverity;
  }
}

const UnifyStepLabelRoot = styled(MuiStepLabel, {
  name: 'UnifyStepLabel',
  slot: 'Root',
})<
  Omit<StepLabelProps, 'severity'> & {
    ownerState: { isInactive: boolean; usesIconGlyph: boolean };
  }
>(function UnifyStepLabelRoot({ theme, ownerState }) {
  const palette = getThemePalette(theme);
  const { isInactive, usesIconGlyph } = ownerState;

  return {
    [`.${stepLabelClasses.labelContainer}`]: {
      color: isInactive ? palette.text.disabled : palette.text.secondary,
      ...theme.typography.body2,
    },
    [`.${stepLabelClasses.label}.${stepLabelClasses.label}`]: {
      color: isInactive ? palette.text.disabled : palette.text.primary,
      ...theme.typography.body1,
      fontWeight: theme.typography.fontWeightBold,
    },
    [`&.${stepLabelClasses.error}`]: {
      color: palette.text.primary,
    },
    ...(!usesIconGlyph && {
      [`.${stepLabelClasses.iconContainer} > svg`]: {
        // Reduce the 24px container by 1px on all sides to make the
        // svg `<circle>` el have the same size as a default unify icon (20px)
        padding: '2px',
      },
    }),
  };
});

export const StepLabel = React.forwardRef<HTMLDivElement, StepLabelProps>(
  function StepLabel(inProps, forwardedRef) {
    const theme = useTheme();
    const props = useThemeProps({ props: inProps, name: 'MuiStepLabel' });
    const { completed, active } = React.useContext(
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      StepContext as React.Context<StepContextType>
    );

    const {
      severity = 'default',
      // eslint-disable-next-line deprecation/deprecation
      error = severity === 'error',
      StepIconComponent: StepIconComponentProp,
      StepIconProps: StepIconPropsProp,
      ...rest
    } = props;

    const ownerState = {
      isInactive: severity === 'default' && !error && !active && !completed,
      usesIconGlyph: severity !== 'default' || error || completed,
    };

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const iconComponent = (
      severity !== 'default' || completed
        ? UnifySeverityStepIcon
        : StepIconComponentProp
    ) as React.ElementType<StepIconProps>;

    const stepIconPropsWithSeverity = {
      ...StepIconPropsProp,
      error: error,
      ownerState: {
        severity,
      },
    };

    return (
      <UnifyStepLabelRoot
        StepIconComponent={iconComponent}
        StepIconProps={stepIconPropsWithSeverity}
        error={error}
        theme={theme}
        ownerState={ownerState}
        {...rest}
        ref={forwardedRef}
      />
    );
  }
);
setMuiName(StepLabel, getMuiName(MuiStepLabel));

export default StepLabel;
