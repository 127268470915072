import { useThemeProps } from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';
import {
  default as MuiTab,
  TabTypeMap as MuiTabTypeMap,
} from '@mui/material/Tab';
import React from 'react';
import {
  ExtendTypeMap,
  forwardRefToOverridableComponent,
} from 'src/utils/forwardRefToOverridableComponent';

export interface TabOwnProps {
  /**
   * @dart-deprecated In Dart, use `..dom.href` alongside `..component = Dom.a.elementType` instead.
   */
  href?: string;

  // Override `any` from MuiTabProps, so that the type matches that of TabContext
  // and TabPanel.
  /** The value of the corresponding `TabPanel`. */
  value?: string;
}

export type TabTypeMap<
  AdditionalProps = unknown,
  RootComponent extends React.ElementType = MuiTabTypeMap['defaultComponent']
> = ExtendTypeMap<
  {
    props: Omit<MuiTabTypeMap['props'], 'value'>;
    defaultComponent: MuiTabTypeMap['defaultComponent'];
  },
  AdditionalProps & TabOwnProps,
  RootComponent
>;

export type TabProps<
  RootComponent extends React.ElementType = MuiTabTypeMap['defaultComponent'],
  AdditionalProps = unknown
> = OverrideProps<TabTypeMap<AdditionalProps, RootComponent>, RootComponent>;

//
// This wrapper exists mainly to override the type of the `value` prop.
//

/**
 * Demos:
 *
 * - [Tabs](https://mui.com/material-ui/react-tabs/)
 *
 * API:
 *
 * - [Tab API](https://mui.com/material-ui/api/tab/)
 * - inherits [ButtonBase API](https://mui.com/material-ui/api/button-base/)
 */
export const Tab = forwardRefToOverridableComponent<TabTypeMap, TabProps>(
  function Tab(inProps, forwardedRef) {
    const props = useThemeProps({ props: inProps, name: 'MuiTab' });
    const { ...rest } = props;
    return <MuiTab ref={forwardedRef} {...rest} />;
  }
);

export default Tab;
