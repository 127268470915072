import { generateUtilityClass, generateUtilityClasses } from '@mui/material';

export interface PaperActionAreaClasses {
  root: string;
  /**
   * State class applied when the `hideInteractionStateLayer`
   * prop is true and all hover / focus decorations are turned off.
   */
  hiddenInteractionStateLayer: string;
  /**
   * State class applied when the `hideInteractionStateLayer` and `disabled`
   * props are both false and hover / focus decorations are turned on.
   */
  visibleInteractionStateLayer: string;
  /** State class applied to the ButtonBase root element if the action area is keyboard focused. */
  focusVisible: string;
}

export type PaperActionAreaClassKey = keyof PaperActionAreaClasses;

export function getPaperActionAreaUtilityClass(slot: string): string {
  return generateUtilityClass('PaperActionArea', slot);
}

export const paperActionAreaClasses: PaperActionAreaClasses =
  generateUtilityClasses('PaperActionArea', [
    'root',
    'focusVisible',
    'hiddenInteractionStateLayer',
    'visibleInteractionStateLayer',
  ]);

export default paperActionAreaClasses;
