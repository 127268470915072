import MuiFormControlLabel, {
  FormControlLabelProps as MuiFormControlLabelProps,
} from '@mui/material/FormControlLabel';
import * as React from 'react';

export interface FormControlLabelProps extends MuiFormControlLabelProps {
  /**
   * Callback fired when the state is changed.
   *
   * @param {React.SyntheticEvent} event The event source of the callback.
   * You can pull out the new checked state by accessing `event.target.checked` (boolean).
   */
  onChange?: (event: React.SyntheticEvent) => void;
}

// This wrapper is necessary to change the typing of the `onChange` prop from
// `(event: SyntheticEvent, checked:bool) => void` to `(event: SyntheticEvent) => void`
// in order to avoid unnecessary typing conflicts with `UiProps.onChange`.
/**
 * Drop-in replacement of the Radio, Switch and Checkbox component. Use this component if you want to display an extra label.
 */
export const FormControlLabel = React.forwardRef<
  HTMLButtonElement,
  FormControlLabelProps
>(function FormControlLabel(props, ref) {
  const { control, onChange, ...rest } = props;
  const handleChange = (event: React.SyntheticEvent) => {
    return onChange?.(event);
  };

  return (
    <MuiFormControlLabel
      {...rest}
      onChange={handleChange}
      /*Clone control to add key to prevent key warning caused by https://github.com/mui/material-ui/issues/34150
      that only occurs when using the prod bundle.*/
      control={React.cloneElement(control, { key: 'label-control' })}
      ref={ref}
    />
  );
});

export default FormControlLabel;
