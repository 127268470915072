import { generateUtilityClass, styled } from '@mui/material';
import clsx from 'clsx';
import * as React from 'react';
import badgeClasses from 'src/components/Badge/badgeClasses';
import { generateWsdLabelStyles } from 'src/wsd/components/Label/style';
import { WsdBadgeColorName } from 'src/wsd/components/WsdBadge/style';
import WsdBadge, { WsdBadgeProps } from 'src/wsd/components/WsdBadge/WsdBadge';

/** @deprecated Avoid using this. It was in place only for initial web_skin_dart migration purposes */
export type LabelProps = Omit<
  WsdBadgeProps,
  'anchorOrigin' | 'badgeContent' | 'color'
> & {
  /**
   * Sets `wsdLabelStatus` variant for Labels
   *
   * @default false
   */
  isStatus?: boolean;

  /**
   * Sets color for Labels.
   *
   * Adds `LabelColor` enum generation in Dart
   *
   * @default 'default'
   */
  color?: WsdBadgeColorName;
};

const WsdLabelRoot = styled(WsdBadge, {
  name: 'MuiLabel',
  slot: 'Root',
  shouldForwardProp: (prop) => prop !== 'isStatus',
  // eslint-disable-next-line deprecation/deprecation
})<LabelProps>(function WsdLabelRoot({ theme, color, variant }) {
  if (!theme.isLegacyWsdTheme) return {};

  return {
    marginRight: 0,
    [`.${badgeClasses.badge}`]: {
      fontFamily: 'inherit',
      userSelect: 'none',
      display: 'inline-block',
      textAlign: 'center',
      lineHeight: 'inherit',
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
      height: 'auto',
      position: 'relative',
      transform: 'none',
      padding: '.1em .54em .1em',
      textTransform: 'uppercase',
      fontSize: '0.8em',
      border: `1px solid transparent`,
      borderRadius: '.27em',
      verticalAlign: 'inherit',
      ...generateWsdLabelStyles(theme, color ?? 'default', variant),
    },
  };
});

/** @deprecated Avoid using this. It was in place only for initial web_skin_dart migration purposes */
// eslint-disable-next-line deprecation/deprecation
export const Label = React.forwardRef<HTMLSpanElement, LabelProps>(
  function WsdLabel(props, forwardedRef) {
    const {
      children,
      variant,
      color = 'default',
      className,
      isStatus,
      ...rest
    } = props;
    const classes = clsx(
      generateUtilityClass('MuiLabel', 'root'),
      'MuiBadge-wsdLabel',
      isStatus && 'MuiBadge-isStatus',
      className
    );

    return (
      <WsdLabelRoot
        ref={forwardedRef}
        badgeContent={children}
        color={color}
        //@ts-expect-error WSD options were removed from the prop type, but still work under the hood
        variant={variant ?? (isStatus ? 'wsdLabelStatus' : 'wsdLabel')}
        className={classes}
        {...rest}
      />
    );
  }
);

// eslint-disable-next-line deprecation/deprecation
export default Label;
