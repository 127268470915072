/* eslint-disable deprecation/deprecation */
import { Palette } from '@mui/material/styles';
import type {
  TypographyOptions,
  TypographyStyleOptions,
} from '@mui/material/styles/createTypography';
import wsdGrey from 'src/wsd/styles/colors/grey';

const wsdFontFamilyCssVarVal =
  '"Lato","Helvetica Neue",Helvetica,Arial,sans-serif';
export const wsdFontFamily = `var(--body-font-family, ${wsdFontFamilyCssVarVal}),${wsdFontFamilyCssVarVal}`;

function getWsdTypography(palette: Palette): Partial<TypographyOptions> {
  const defaults = {
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 1.4285714286,
    letterSpacing: 'normal',
  };
  const body1: TypographyStyleOptions = {
    ...defaults,
    fontSize: 14,
    fontWeight: 'inherit',
  };
  const body2: TypographyStyleOptions = {
    ...defaults,
    fontSize: 12,
    lineHeight: 1.5,
  };
  const caption: TypographyStyleOptions = {
    ...body2,
    lineHeight: 1.66,
  };
  const overline: TypographyStyleOptions = {
    ...body2,
    lineHeight: 2.66,
  };
  const button: TypographyStyleOptions = {
    ...defaults,
    fontSize: 14,
  };
  const h1: TypographyStyleOptions = {
    ...defaults,
    lineHeight: 1.1,
    letterSpacing: 'normal',
    fontSize: 36,
    color: palette.mode === 'light' ? wsdGrey[900] : palette.text.primary,
  };
  const h2: TypographyStyleOptions = {
    ...h1,
    fontSize: 28,
  };
  const h3: TypographyStyleOptions = {
    ...h2,
    fontSize: 24,
  };
  const h4: TypographyStyleOptions = {
    ...h3,
    fontSize: 18,
  };
  const h5: TypographyStyleOptions = {
    ...h4,
    fontWeight: 700,
    fontSize: 16,
  };
  const h6: TypographyStyleOptions = {
    ...h5,
    fontSize: 14,
  };

  return {
    fontFamily: wsdFontFamily,
    body1,
    body2,
    button,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    subtitle1: { ...h5, fontWeight: 'normal', lineHeight: 1.75 },
    subtitle2: { ...h6, lineHeight: 1.57 },
    caption,
    overline,
  };
}

export default getWsdTypography;
