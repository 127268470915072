import {
  CardHeader as MuiCardHeader,
  CardHeaderPropsWithComponent as MuiCardHeaderPropsWithComponent,
  paperClasses,
  TypographyProps,
  useThemeProps,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import * as React from 'react';
import {
  cardContentHorizontalThemeSpacing,
  cardContentVerticalThemeSpacing,
} from 'src/components/CardContent/cardContentStyleOverrides';
import { getThemePalette } from 'src/styles/utils';

export type { CardHeaderProps };

type CardHeaderProps = MuiCardHeaderPropsWithComponent<React.ElementType> & {
  showDividers?: boolean;
};

export const CardHeader = React.forwardRef<HTMLElement, CardHeaderProps>(
  function UnifyCardHeader(inProps, ref) {
    const props = useThemeProps({ props: inProps, name: 'CardHeader' });
    const {
      showDividers,
      titleTypographyProps: inTitleTypographyProps,
      subheaderTypographyProps: inSubheaderTypographyProps,
      children,
      sx: inSx = {},
      ...rest
    } = props;

    const unifyTitleProps: Partial<TypographyProps> = {
      // MUI defaults this to body2 when the avatar is present and an h5 otherwise. We explicitly want this to be h5
      // and bold by default.
      variant: 'h5',
      // There is some odd line height issues when there are multiple lines of text and an avatar.
      lineHeight: 1,
      fontWeight: 700,
      ...inTitleTypographyProps,
    };

    // Override MUI's body2 / body1 styling and propagate custom styling.
    const unifySubheaderProps: Partial<TypographyProps> = {
      variant: 'subtitle2',
      ...inSubheaderTypographyProps,
    };

    const borderStyles: Partial<SxProps<Theme>> = {
      borderBottom: (theme) => `1px solid ${getThemePalette(theme).divider}`,
    };

    // Add the conditional & default outline dividers.
    const sx: SxProps<Theme> = {
      padding: (theme) =>
        theme.spacing(
          cardContentVerticalThemeSpacing,
          cardContentHorizontalThemeSpacing
        ),
      // Skip applying the divider if the flag is unset.
      ...(showDividers !== false && {
        // Apply borders in an outlined parent by default.
        [`.${paperClasses.outlined} &`]: borderStyles,
      }),
      // Force apply border if set.
      ...(showDividers && borderStyles),
      // Propagate the custom styles.
      ...inSx,
    };

    return (
      <MuiCardHeader
        titleTypographyProps={unifyTitleProps}
        subheaderTypographyProps={unifySubheaderProps}
        sx={sx}
        ref={ref}
        {...rest}
      >
        {children}
      </MuiCardHeader>
    );
  }
);
