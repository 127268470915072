import { ChipPropsColorOverrides, CSSObject, Theme } from '@mui/material';
import { getGreyShadeColorForPaletteMode } from 'src/utils/paperAlphaUtils';
import { WsdChipVariantName } from 'src/wsd/components/Chip/style/variants/badge';
import { StaticComponentStateColors } from 'src/wsd/components/utils/types';
import { greenC } from 'src/wsd/styles/colors/green';

export const labelColorNames = [
  'default',
  'primary',
  'secondary',
  'info',
  'success',
  'error',
  'warning',
  'wsdLabelBlue',
  'wsdLabelLightBlue',
  'wsdLabelTeal',
  'wsdLabelGray',
  'wsdLabelRed',
  'wsdLabelGreen',
  'wsdLabelPurple',
  'wsdLabelOrange',
] as const;

export type WsdLabelColorName =
  | (typeof labelColorNames)[number]
  | keyof ChipPropsColorOverrides;

interface WsdLabelPaletteColors {
  bgColors: StaticComponentStateColors;
}

export const wsdLabelPalettes: Partial<
  Record<WsdLabelColorName, ((theme: Theme) => WsdLabelPaletteColors) | null>
> = {
  default: (theme) => ({
    bgColors: {
      default: getGreyShadeColorForPaletteMode(
        theme.palette.grey[700],
        theme.palette
      ),
    },
  }),
  primary: (theme) => ({
    bgColors: {
      default: theme.palette.primary.main,
    },
  }),
  secondary: (theme) => ({
    bgColors: {
      default: theme.palette.secondary.main,
    },
  }),
  success: (theme) => ({
    bgColors: {
      default: theme.palette.success.main,
    },
  }),
  info: (theme) => ({
    bgColors: {
      default: theme.palette.info.main,
    },
  }),
  warning: (theme) => ({
    bgColors: {
      default: theme.palette.warning.main,
    },
  }),
  error: (theme) => {
    return {
      bgColors: {
        default: theme.palette.error.main,
      },
    };
  },
  wsdLabelBlue: (theme) => ({
    bgColors: {
      default: theme.palette.blue[900],
    },
  }),
  wsdLabelLightBlue: (theme) => ({
    bgColors: {
      default: theme.palette.blue[600],
    },
  }),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  wsdLabelTeal: (_) => ({
    bgColors: {
      default: '#00ceae',
    },
  }),
  wsdLabelGray: (theme) => ({
    bgColors: {
      default: getGreyShadeColorForPaletteMode(
        theme.palette.grey[600],
        theme.palette
      ),
    },
  }),
  wsdLabelRed: (theme) => ({
    bgColors: {
      default: theme.palette.red[600],
    },
  }),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  wsdLabelGreen: (_) => ({
    bgColors: {
      // eslint-disable-next-line deprecation/deprecation
      default: greenC[600],
    },
  }),
  wsdLabelPurple: (theme) => ({
    bgColors: {
      default: theme.palette.purple[600],
    },
  }),
  wsdLabelOrange: (theme) => ({
    bgColors: {
      default: theme.palette.orange[800],
    },
  }),
};

function generateWsdLabelColorVariantStyles(
  theme: Theme,
  color: WsdLabelColorName
): CSSObject | null {
  const getPalette = wsdLabelPalettes[color];
  if (getPalette == null) return null;

  const { bgColors } = getPalette(theme);

  return {
    backgroundColor: bgColors.default,
    color:
      theme.isLegacyWsdTheme && theme.palette.mode === 'light'
        ? '#fff'
        : theme.palette.getContrastText(bgColors.default),
  };
}

export function generateWsdLabelStyles(
  theme: Theme,
  color: WsdLabelColorName,
  variant: WsdChipVariantName
): CSSObject {
  // Widen the type of `variant` to avoid warnings when type checking wsd options that don't
  // technically exist in the union, but could be passed through the Dart wrapper.
  // We can remove support for this once we stop supporting these options in Dart.
  const wsdVariant: unknown = variant;

  return {
    padding: '.1em .54em .1em',
    textTransform: 'uppercase',
    fontSize: '0.8em',
    border: `1px solid transparent`,
    borderRadius: '.27em',
    verticalAlign: 'inherit',
    ...(wsdVariant === 'wsdLabel' &&
      generateWsdLabelColorVariantStyles(theme, color)),
    ...(wsdVariant === 'wsdLabelStatus' && {
      backgroundColor: getGreyShadeColorForPaletteMode(
        theme.palette.grey[100],
        theme.palette
      ),
      color: getGreyShadeColorForPaletteMode(
        theme.palette.grey[600],
        theme.palette
      ),
      borderColor: getGreyShadeColorForPaletteMode(
        theme.palette.grey[600],
        theme.palette
      ),
      textTransform: 'none',
    }),
  };
}
