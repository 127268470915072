import {
  Grid,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryTypeMap as MuiAccordionSummaryTypeMap,
  Typography,
  accordionSummaryClasses,
  styled,
  useThemeProps,
} from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';
import { unstable_extendSxProp as extendSxProp } from '@mui/system';
import * as React from 'react';
import {
  accordionSummaryContentHeaderSpacingAmount,
  accordionSummaryThemeSpacingAmount,
} from 'src/components/Accordion/sharedAccordionConstants';
import UnifyIcons from 'src/components/UnifyIcons';
import {
  ExtendTypeMap,
  forwardRefToOverridableComponent,
} from 'src/utils/forwardRefToOverridableComponent';
import { getThemePalette } from '../../styles/utils';

export interface AccordionSummaryOwnProps {
  header?: string;
  summary?: string;
  renderSecondaryAction?: () => React.ReactNode;
  renderChip?: () => React.ReactNode;
  //Included since the generation deletes them
  component?: React.ElementType;
  href?: string;
}

export type AccordionSummaryTypeMap<
  AdditionalProps = unknown,
  RootComponent extends React.ElementType = MuiAccordionSummaryTypeMap['defaultComponent']
> = ExtendTypeMap<
  MuiAccordionSummaryTypeMap,
  AdditionalProps & AccordionSummaryOwnProps,
  RootComponent
>;

export type AccordionSummaryProps<
  RootComponent extends React.ElementType = MuiAccordionSummaryTypeMap['defaultComponent'],
  AdditionalProps = unknown
> = OverrideProps<
  AccordionSummaryTypeMap<AdditionalProps, RootComponent>,
  RootComponent
>;

export const AccordionSummary = forwardRefToOverridableComponent<
  AccordionSummaryTypeMap,
  AccordionSummaryProps
>(function AccordionSummaryGroup(inProps, forwardedRef) {
  const themeProps = useThemeProps({
    props: inProps,
    name: 'MuiAccordionSummary',
  });
  const props = extendSxProp(themeProps);
  const {
    header,
    summary,
    renderSecondaryAction = () => undefined,
    renderChip = () => undefined,
    children,
    ...rest
  } = props;
  const renderedSecondaryAction =
    renderSecondaryAction && renderSecondaryAction();
  const hasSummary = summary !== undefined;
  const hasSecondaryAction = renderedSecondaryAction !== undefined;
  const renderedChip = renderChip && renderChip();
  const hasChip = renderedChip !== undefined;
  return (
    <UnifyAccordionSummary
      ref={forwardedRef}
      expandIcon={<UnifyIcons.ExpandMore />}
      {...rest}
    >
      <Grid container alignItems="center">
        <Grid
          container
          item
          xs={hasSummary ? 6 : true}
          overflow={'hidden'}
          alignItems={'center'}
        >
          <UnifyAccordionSummaryContentHeader
            variant="h5"
            ownerState={{
              hasContentSummary: summary !== undefined,
              hasChip: hasChip,
            }}
          >
            {header ?? children}
          </UnifyAccordionSummaryContentHeader>
          {hasChip && (
            <UnifyAccordionSummaryContentChipWrapper item>
              {renderedChip}
            </UnifyAccordionSummaryContentChipWrapper>
          )}
        </Grid>
        {hasSummary && (
          <Grid item xs={true} overflow={'hidden'}>
            <UnifyAccordionSummaryContentSummary variant="subtitle2">
              {summary}
            </UnifyAccordionSummaryContentSummary>
          </Grid>
        )}
        {hasSecondaryAction && (
          <UnifyAccordionSummaryContentSecondaryActionWrapper
            container
            item
            xs="auto"
            onClick={(e) => e.stopPropagation()}
          >
            {renderedSecondaryAction}
          </UnifyAccordionSummaryContentSecondaryActionWrapper>
        )}
      </Grid>
    </UnifyAccordionSummary>
  );
});

const UnifyAccordionSummaryContentSecondaryActionWrapper = styled(Grid, {
  name: 'MuiAccordionSummary',
  slot: 'ContentSecondaryActionWrapper',
  overridesResolver: (styles) => styles.contentSecondaryActionWrapper,
})`
  // Normalizes the height of AccordionSummary when there is a secondaryAction vs when there is not
  min-height: 40px;
  align-items: center;
  &:empty {
    visibility: hidden;
  }
`;

const UnifyAccordionSummaryContentChipWrapper = styled(Grid, {
  name: 'MuiAccordionSummary',
  slot: 'ContentChipWrapper',
  overridesResolver: (styles) => styles.contentChipWrapper,
})(({ theme }) => {
  return {
    marginRight: theme.spacing(accordionSummaryContentHeaderSpacingAmount),
    [`&:empty`]: {
      display: 'none',
    },
  };
});

const UnifyAccordionSummaryContentHeader = styled(Typography, {
  name: 'MuiAccordionSummary',
  slot: 'ContentHeader',
  overridesResolver: (styles) => styles.contentHeader,
})<{ ownerState: { hasContentSummary: boolean; hasChip: boolean } }>(
  ({ theme }) => {
    return {
      padding: theme.spacing(0, accordionSummaryContentHeaderSpacingAmount),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      alignItems: 'center',
      fontWeight: 'normal',
      color: getThemePalette(theme).text.primary,
    };
  }
);

const UnifyAccordionSummaryContentSummary = styled(Typography, {
  name: 'MuiAccordionSummary',
  slot: 'ContentSummary',
  overridesResolver: (styles) => styles.contentSummary,
})(({ theme }) => {
  return {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    color: getThemePalette(theme).text.secondary,
  };
});

const UnifyAccordionSummary = styled(MuiAccordionSummary)(({ theme }) => {
  const focusBorderColor = (theme.vars || theme).palette.action
    .focusOutlineColor;
  return {
    // Use padding instead of margin to prevent bg clipping of children like the bg color of secondaryActions when focused
    padding: theme.spacing(accordionSummaryThemeSpacingAmount),
    // The focus outline should be rounded based on the rounding of the accordion itself
    borderRadius: 'inherit',
    // No bottom border radius on the summary when the details are visible
    [`&.${accordionSummaryClasses.expanded}`]: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    [`&.${accordionSummaryClasses.focusVisible}, &:focus`]: {
      // Allow the paper color of the root accordion element to show through
      backgroundColor: 'transparent',
      // Blue outline when focused
      outline: `2px solid ${focusBorderColor}`,
      outlineOffset: -2,
    },

    [`&.${accordionSummaryClasses.disabled}`]: {
      opacity: (theme.vars || theme).palette.action.disabledOpacity,
    },

    [`&:hover:not(.${accordionSummaryClasses.disabled})`]: {
      cursor: 'pointer',
      // TODO (adl): Change this to layer-hover as part of the tokens work
      backgroundColor: (theme.vars || theme).palette.action.hover,
    },

    [`& .${accordionSummaryClasses.content}`]: {
      overflow: 'hidden',
      maxWidth: '100%',
      alignItems: 'center',

      // Override MUI margin defaults since we're using padding instead on the parent summary root node
      [`&, &.${accordionSummaryClasses.expanded}`]: {
        // Reduce the overall height regardless of the default (or customized) top/bottom padding on the AccordionSummary's root node
        margin: '-2px 0',
        transition: 'none',
      },
    },

    [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
      padding: theme.spacing(0.75),
    },
  };
});

export default AccordionSummary;
