import { Stack, StackProps, useThemeProps } from '@mui/material';
import React from 'react';

declare module '@mui/material/styles' {
  interface Components {
    ListItemSecondaryActions?: {
      defaultProps?: ListItemSecondaryActionsProps;
    };
  }
}

export type ListItemSecondaryActionsProps = StackProps;

/**
 * The `ListItemSecondaryActions` component sets spacing and alignment for multiple {@link ListItem} secondary actions.
 *
 * Renders / forwards props to the {@link Stack} component.
 * See the Stack component documentation for more details.
 */
export const ListItemSecondaryActions = React.forwardRef<
  HTMLDivElement,
  ListItemSecondaryActionsProps
>(function ListItemSecondaryActions(inProps, ref) {
  const props = useThemeProps({
    props: inProps,
    name: 'ListItemSecondaryActions',
  });
  const { children, ...other } = props;

  return (
    <Stack direction="row" spacing={1} alignItems="center" ref={ref} {...other}>
      {children}
    </Stack>
  );
});
