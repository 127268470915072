import * as React from 'react';

/**
 * A hook that returns true if {@link shouldMount} is true,
 * or has been set to true at least once during its lifetime.
 *
 * This allows for lazy-mounting of content (like "lazy-rendering", but content
 * that has been rendered before isn't unmounted).
 *
 * @example
 * // Lazy-render contents, but don't unmount them when we're no longer expanded.
 * const shouldRenderContents = useLazyMount(props.isExpanded);
 * return shouldRenderContents ? props.contents : null;
 */
export function useLazyMount(shouldMount: boolean): boolean {
  const hasMountedBefore = React.useRef(false);
  if (shouldMount) {
    hasMountedBefore.current = true;
  }
  return hasMountedBefore.current;
}
