import { UseControlledProps } from '@mui/utils/useControlled';
import React from 'react';
import useTransparentControlled from 'src/hooks/use_transparent_controlled';

interface UseLockableControllerConfig
  extends Pick<UseControlledProps, 'name' | 'state'> {
  /**
   * Can be set to control the value. Toggling this
   * from `true` to `false` has the same effect as calling `setIsLocked(true | false)`.
   *
   * Note: when setting this, you cannot use `setIsLocked` to control
   * the editability.
   */
  isLocked?: boolean;

  /**
   * Callback that is fired when the state goes from unlocked to locked.
   */
  onLock?: () => void;

  /**
   * Callback that is fired when the state goes from locked to unlocked.
   */
  onUnlock?: () => void;
}

interface LockableController {
  /**
   * Whether the current state of the controller is set to locked.
   */
  isLocked: boolean;

  /**
   * Sets the locked state (`isLocked`) to the `value` provided.
   *
   * Note: This cannot be use in conjunction with the `isLocked` controller
   * param (not to be confused with the `isLocked` status returned from the hook)
   * because utilitizing the `isLocked` parameter forces it to control the
   * locked state.
   */
  setIsLocked: (value: boolean) => void;
}

/**
 * Utility hook that can be used to track the state of an entity
 * that has both a locked state and an unlocked state.
 */
export function useLockableController({
  isLocked: controlledIsLocked,
  onLock,
  onUnlock,
  name,
  state,
}: UseLockableControllerConfig): LockableController {
  const [isLocked, setIsLocked, isControlled] = useTransparentControlled({
    controlled: controlledIsLocked,
    default: true,
    name,
    state,
  });
  const prevIsLocked = React.useRef(isLocked);

  React.useEffect(() => {
    if (prevIsLocked.current === true && !isLocked) {
      onUnlock?.();
    } else if (prevIsLocked.current === false && isLocked) {
      onLock?.();
    }

    prevIsLocked.current = isLocked;
  }, [isLocked, onLock, onUnlock]);

  const toggleIsLocked = (position: boolean) => {
    if (isControlled) return;

    setIsLocked(position);
  };

  return {
    isLocked,
    setIsLocked: toggleIsLocked,
  };
}

/**
 * A configuration for how `useCommittedValue` should
 * watch for key events.
 *
 * The mapping key should be the key code of the key
 * to watch. The mapping value should be whether or not
 * this key represents a "commit" or a "discard" action.
 */
interface KeybindConfig {
  [keyCode: string]: boolean;
}

interface UseCommittedValueConfig
  extends Pick<UseControlledProps, 'name' | 'state'> {
  /**
   * The controlled value of the hook.
   *
   * Note that setting this configures the hook
   * to ignore its own committed value state and instead
   * uses the provided value as a baseline for commits and
   * discards.
   */
  value?: string;

  /**
   * The configuration of which keys should cause the value
   * to be either committed or discard.
   */
  keybindConfig: KeybindConfig;

  /**
   * A callback that is fired when a commit action is fired.
   *
   * @param committedValue is the value to be committed. Note that if the
   * component is in a controlled configuration, the value won't actually be
   * tracked internally. In order for the value to be set, it must be passed
   * back in the typical controlled fashion.
   * @param prevCommittedValue is the last committed value that is being replaced
   * by `committedValue`.
   */
  onCommit: (committedValue: string, prevCommittedValue: string) => void;

  /**
   * A callback that is fired when a discard action is fired.
   *
   * @param discardedVal is the value that was uncommitted and is currently
   * being discarded.
   * @param prevCommittedValue is the last committed value that is being reverted
   * to.
   */
  onDiscard: (discardedVal: string, prevCommittedValue: string) => void;
}

interface UseCommittedValueHandles {
  /**
   * A callback to commit the current uncommitted value.
   *
   * This will always result in {@link UseCommittedValueConfig.onCommitAttempt} being
   * called with `didCommit` equal to `true`.
   */
  forceCommit: () => void;

  /**
   * Updates the internal uncommitted value state.
   *
   * This should be used as an event handler on an input
   * to incrementally update the value and _should not_
   * be used to brute force the internal value. If you would
   * like to have more control over the value of the input,
   * use {@link UseCommittedValueConfig.value} to control the
   * input.
   */
  setUncommittedValue: (val: string) => void;

  /**
   * The final value that represents the current
   * state.
   *
   * The hook balances input for the provided config `value`,
   * the internal uncommitted state, and the internal committed
   * state. This value represents the outcome of logic that understands
   * what to show given all the forms of input.
   */
  displayValue: string;

  /**
   * The callback that understands how to handle key down events that
   * affect the committed value state.
   *
   * This needs to be attached to an input's `onKeyDown` event handler
   * in order to properly handle events provided in the {@link KeybindConfig}.
   */
  handleKeyDown: (e: React.KeyboardEvent) => void;
}

/**
 * Hook that tracks a "committed" value and understands how to update and display it.
 *
 * The hook returns an object with a {@link UseCommittedValueHandles.displayValue} property.
 * That property is the ultimate output from the hook, representing the correct value to display
 * to the user. That value could be either the uncommitted state, if there is one, or the last
 * committed value.
 *
 * The committed value is a value that represents a temporary, uncommitted,
 * value that was then deliberately committed to state. A commitment can occur
 * due to keyboard events (defined in {@link KeybindConfig}) or due to
 * forcing a commit (using {@link UseCommittedValueHandles.forceCommit}).
 *
 * The temporary state of the value (the uncommitted value) is controlled
 * by the hook, but can be incrementally updated via {@link UseCommittedValueHandles.updateUncommittedValue}.
 * Note that this callback shouldn't be used to brute force the value being
 * shown to the user, and instead is just a tool to handle incremental (`onChange`)
 * type updates.
 *
 * To have more aggressive control over the value, set {@link UseCommittedValueConfig.value}
 * to the desired state. This puts the hook into a controlled mode, where it's internal
 * commmitted value is effectively ignored in favor of the provided value.
 */
export function useCommittedValue({
  value: controlledValue,
  keybindConfig,
  onCommit,
  onDiscard,
  name,
  state,
}: UseCommittedValueConfig): UseCommittedValueHandles {
  const [committedVal, setCommittedVal] = useTransparentControlled({
    controlled: controlledValue,
    default: '',
    name,
    state,
  });

  /**
   * State used to track in progress, uncommitted changes.
   *
   * When this is `undefined`, it means that there is no in-progress
   * update that is being tracked.
   */
  const [uncommittedValue, setUncommittedValue] = React.useState<
    undefined | string
  >(undefined);

  /**
   * Decides what the current value of the hook is.
   *
   * The goal of this function is to abstract the logic necessary
   * to determine what text should be shown as the output of the hook.
   *
   * For example, if the hook's consumer has updated the uncommitted value,
   * the hook should be showing that. However, after a commit, the committed
   * value is now the source of truth and should be the hook's value.
   *
   * @returns the current value of the hook.
   */
  const getDisplayValue = () => {
    return uncommittedValue ?? committedVal;
  };

  const handleOnCommit = (
    newCommittedValue: string,
    currentCommittedValue: string
  ) => {
    setCommittedVal(newCommittedValue);
    onCommit(newCommittedValue, currentCommittedValue);

    /**
     * Make sure to reset the uncommitted value to
     * signal that there is no pending uncommitted state.
     */
    setUncommittedValue(undefined);
  };

  const handleOnDiscard = (
    currentUncommittedValue: string,
    currentCommittedValue: string
  ) => {
    onDiscard(currentUncommittedValue, currentCommittedValue);

    /**
     * Make sure to reset the uncommitted value to
     * signal that there is no pending uncommitted state.
     */
    setUncommittedValue(undefined);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    // eslint-disable-next-line no-prototype-builtins
    if (keybindConfig.hasOwnProperty(e.key)) {
      const currentCommittedValue = committedVal;
      const currentUncommittedValue = uncommittedValue ?? '';

      if (keybindConfig[e.key]) {
        handleOnCommit(
          uncommittedValue ?? currentCommittedValue,
          currentCommittedValue
        );
      } else {
        handleOnDiscard(currentUncommittedValue, currentCommittedValue);
      }
    }
  };

  const forceCommit = () => {
    const valueToCommit = uncommittedValue ?? committedVal ?? '';

    handleOnCommit(valueToCommit, committedVal);
  };

  return {
    forceCommit,
    setUncommittedValue,
    displayValue: getDisplayValue(),
    handleKeyDown,
  };
}
