import generateUtilityClass from '@mui/utils/generateUtilityClass';
import generateUtilityClasses from '@mui/utils/generateUtilityClasses';

export interface LockableTextFieldClasses {
  root: string;
  unlocked: string;
  locked: string;
}

export type LockableTextFieldClassKey = keyof LockableTextFieldClasses;

export function getTextFieldUtilityClass(slot: string): string {
  return generateUtilityClass('LockableTextField', slot);
}

const lockableTextFieldClasses: LockableTextFieldClasses =
  generateUtilityClasses('LockableTextField', ['root', 'unlocked', 'locked']);

export default lockableTextFieldClasses;
