import {
  ChipClasses,
  generateUtilityClass,
  generateUtilityClasses,
  chipClasses as muiChipClasses,
} from '@mui/material';

// Module augmentation for Chip classes
declare module '@mui/material/Chip/chipClasses' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface ChipClasses extends WsdChipClasses {}
}

export interface WsdChipClasses {
  /** Styles applied to the root element with default color. */
  colorDefault: string;
  /** Styles applied to the root element with `color="primary"`. */
  colorPrimary: string;
  /** Styles applied to the root element with `color="secondary"`. */
  colorSecondary: string;
  /** Styles applied to the root element with `color="info"`. */
  colorInfo: string;
  /** Styles applied to the root element with `color="success"`. */
  colorSuccess: string;
  /** Styles applied to the root element with `color="error"`. */
  colorError: string;
  /** Styles applied to the root element with `color="error"`. */
  colorWarning: string;
  /** Styles applied to the root element if `variant="wsdBadge"` and `color="wsdBadgeOutlined"`. */
  colorWsdBadgeOutlined: string;
  /** Styles applied to the root element if `variant="wsdBadge"`. */
  wsdBadge: string;
  /** Styles applied to the root element if `variant="wsdLabel"`. */
  wsdLabel: string;
  /** Styles applied to the root element if `variant="wsdLabel"` and `color="wsdLabelBlue"`. */
  colorWsdLabelBlue: string;
  /** Styles applied to the root element if `variant="wsdLabel"` and `color="wsdLabelLightBlue"`. */
  colorWsdLabelLightBlue: string;
  /** Styles applied to the root element if `variant="wsdLabel"` and `color="wsdLabelLightTeal"`. */
  colorWsdLabelTeal: string;
  /** Styles applied to the root element if `variant="wsdLabel"` and `color="wsdLabelLightGray"`. */
  colorWsdLabelGray: string;
  /** Styles applied to the root element if `variant="wsdLabel"` and `color="wsdLabelLightRed"`. */
  colorWsdLabelRed: string;
  /** Styles applied to the root element if `variant="wsdLabel"` and `color="wsdLabelLightGreen"`. */
  colorWsdLabelGreen: string;
  /** Styles applied to the root element if `variant="wsdLabel"` and `color="wsdLabelLightPurple"`. */
  colorWsdLabelPurple: string;
  /** Styles applied to the root element if `variant="wsdLabel"` and `color="wsdLabelLightOrange"`. */
  colorWsdLabelOrange: string;
  /** Styles applied to the root element if `variant="wsdLabelStatus"`. */
  colorWsdLabelStatus: string;
}

export function getWsdChipUtilityClass(slot: string): string {
  return generateUtilityClass('MuiChip', slot);
}

const wsdChipClasses: WsdChipClasses = generateUtilityClasses('MuiChip', [
  'colorDefault',
  'colorPrimary',
  'colorSecondary',
  'colorInfo',
  'colorSuccess',
  'colorError',
  'colorWarning',
  'colorWsdBadgeOutlined',
  'wsdBadge',
  'wsdLabel',
  'colorWsdLabelBlue',
  'colorWsdLabelLightBlue',
  'colorWsdLabelTeal',
  'colorWsdLabelGray',
  'colorWsdLabelRed',
  'colorWsdLabelGreen',
  'colorWsdLabelPurple',
  'colorWsdLabelOrange',
  'colorWsdLabelStatus',
]);

const chipClasses: ChipClasses = {
  ...muiChipClasses,
  ...wsdChipClasses,
};

export default chipClasses;
