import * as React from 'react';
import { ScopedCssBaselineProps } from 'src/components/ScopedCssBaseline/ScopedCssBaseline';
import UnifyThemeProvider from 'src/components/UnifyThemeProvider/UnifyThemeProvider';

export type WorkivaMuiThemeProviderProps = ScopedCssBaselineProps;

/**
 * Use `UnifyThemeProvider` instead.
 *
 * @deprecated use `UnifyThemeProvider` instead.
 */
export const WorkivaMuiThemeProvider = React.forwardRef<
  HTMLDivElement,
  WorkivaMuiThemeProviderProps
>(function WorkivaMuiThemeProvider(props, forwardedRef) {
  const { children, ...rest } = props;

  return (
    <UnifyThemeProvider {...rest} ref={forwardedRef}>
      {children}
    </UnifyThemeProvider>
  );
});

// eslint-disable-next-line deprecation/deprecation
export default WorkivaMuiThemeProvider;
