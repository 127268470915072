import MuiRadioGroup, {
  RadioGroupProps as MuiRadioGroupProps,
} from '@mui/material/RadioGroup';
import * as React from 'react';

export interface RadioGroupProps extends MuiRadioGroupProps {
  /**
   * Callback fired when the state is changed.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.value` (string).
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

// This wrapper is necessary to change the typing of the `onChange` prop from
// `(event: ChangeEvent, value:string) => void` to `(event: ChangeEvent) => void`
// in order to avoid unnecessary typing conflicts with `UiProps.onChange`.
export const RadioGroup = React.forwardRef<HTMLElement, RadioGroupProps>(
  function RadioGroup(props, ref) {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      return props.onChange?.(event);
    };

    return <MuiRadioGroup {...props} onChange={handleChange} ref={ref} />;
  }
);

export default RadioGroup;
