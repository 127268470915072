import { styled, TextField, TextFieldProps } from '@mui/material';
import * as React from 'react';
import { LockedComponentProps } from 'src/components/lockable/LockableTextInputBase/LockableTextInputBase';

const LockedTextFieldTextField = styled(TextField, {
  name: 'LockableTextField',
  slot: 'Locked',
  overridesResolver: (_, styles) => styles.locked,
})((props) => {
  const cursor = props.disabled ? 'default' : 'pointer';

  return {
    cursor,
    '.MuiInputBase-root': {
      cursor,
    },
    '& input': {
      cursor,
    },
    '& textarea': {
      cursor,
      // The locked variant should not show a vertical scrollbar
      overflowY: 'hidden',
    },
    '& fieldset': {
      // This removes the default border on the contained variant
      border: 'none',
    },
  };
});

/**
 * The expected props for the {@link LockedTextField}.
 *
 * This uses {@link TextFieldProps} as a base, but primarily requires
 * {@link LockedComponentProps} to ensure it is connected correctly
 * to the lockable architecture.
 */
type LockedTextFieldProps = LockedComponentProps &
  // Omit `ref` and `value` because we want to use the types from `LockedComponentProps`
  Omit<TextFieldProps, 'ref' | 'value'>;

/**
 * The locked varsion of {@link LockableOutlinedTextField}.
 *
 * This component is a MUI `InputBase` that is styled to appear
 * more like a MUI outlined text input. It is also set to be a
 * purely `readOnly` input, to convery the locked state.
 */
const LockedTextField = React.forwardRef<
  // This is typed as `any` because the incoming ref has that type.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  LockedTextFieldProps
>(function LockedTextField(props, ref) {
  const {
    value,
    inputProps = {},
    InputProps = {},
    ...otherInputBaseProps
  } = props;

  const { onMouseDown, ...otherInputElementProps } = inputProps;
  return (
    <LockedTextFieldTextField
      // Set tabIndex = 0 to allow for keyboard navigation expected in form
      // environments.
      tabIndex={0}
      value={value}
      inputProps={{
        // this `readOnly` is what makes it the "locked" variant!
        readOnly: true,
        ref,
        // When overflow is set to `ellipses` on an input, the browser will
        // remove that styling when the input is focused. When interacting with
        // the element, this creates a jarring experience where the visual of
        // the input shifts due to the ellipses being added and removed.
        //
        // Preventing default on mouse down stops this from happening on click,
        // and a tabIndex of -1 prevents it when interacting with a keyboard.
        onMouseDown: (e) => {
          e.preventDefault();
          onMouseDown?.(e);
        },
        tabIndex: -1,
        ...otherInputElementProps,
      }}
      InputProps={{
        // the standard and filled variants include an underline.
        //
        // The easiest way to remove that is to use the prop, but passing it
        // to the `outlined` variant triggers a prop warning because it gets forwarded
        // all the way to the HTML element.
        ...(['standard', 'filled'].includes(
          otherInputBaseProps.variant ?? 'outlined'
        )
          ? {
              disableUnderline: true,
            }
          : {}),
        ...InputProps,
      }}
      {...otherInputBaseProps}
    />
  );
});

export default LockedTextField;
