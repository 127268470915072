/* eslint-disable @typescript-eslint/consistent-type-assertions */

import {
  ThemeProvider as MuiThemeProvider,
  Theme,
  ThemeOptions,
  createTheme as createThemeWithoutVars,
  useTheme,
} from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import * as React from 'react';

export interface ThemeProviderProps<T extends Theme> {
  children?: React.ReactNode;
  /**
   * A legacy theme object. You can provide a function to extend the outer theme.
   *
   * Your theme must include `isLegacyWsdTheme: true`, or unexpected behavior will occur.
   *
   * To use a non-legacy theme, use `UnifyThemeProvider`.
   */
  theme:
    | (Partial<T> & { isLegacyWsdTheme: true })
    | ((outerTheme: T) => T & { isLegacyWsdTheme: true });
}

/**
 * This component makes the `theme` available down the React tree.
 * It should preferably be used at **the root of your component tree**.
 *
 * @deprecated use UnifyThemeProvider instead.
 */
export const ThemeProvider: React.FC<ThemeProviderProps<Theme>> =
  function ThemeProvider(props) {
    const { children, theme: innerThemeInput, ...rest } = props;
    const outerTheme = useTheme();
    // If our custom isLegacyWsdTheme key is not present, then this theme
    // is not nested within one of our themes.
    const themeIsNested = 'isLegacyWsdTheme' in outerTheme;
    const innerTheme =
      typeof innerThemeInput === 'function'
        ? innerThemeInput(outerTheme)
        : innerThemeInput;

    if (
      process.env.NODE_ENV !== 'production' &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      innerTheme.isLegacyWsdTheme === false
    ) {
      throw new TypeError(
        'UNIFY: The theme passed to `ThemeProvider` must be a "legacy WSD theme" (theme.isLegacyWsdTheme must be true). To use a non-legacy theme, try the `UnifyThemeProvider` component instead.'
      );
    }

    function mergeThemes(outerTheme: Theme): Theme {
      const outerUsesCssVarsProvider = typeof outerTheme.vars != 'undefined';

      let mergedTheme: Theme = deepmerge(outerTheme, innerTheme);

      if (outerUsesCssVarsProvider && innerTheme.isLegacyWsdTheme) {
        // A legacy WSD theme is being nested within a CssVarsProvider based theme.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error we have to delete the vars key so that MUI components don't try to look for value in that object.
        delete mergedTheme.vars;
        mergedTheme = createThemeWithoutVars(mergedTheme as ThemeOptions);
      }

      return mergedTheme;
    }

    return (
      <MuiThemeProvider
        theme={themeIsNested ? mergeThemes : innerThemeInput}
        {...rest}
      >
        {children}
      </MuiThemeProvider>
    );
  };

// eslint-disable-next-line deprecation/deprecation
export default ThemeProvider;
