import HomeOutlined from '@mui/icons-material/HomeOutlined';
import {
  Breadcrumbs,
  BreadcrumbsProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { useThemeProps } from '@mui/material/styles';
import * as React from 'react';
import { Link, LinkProps, LinkTypeMap } from 'src/components/Link/Link';
import { forwardRefToOverridableComponent } from 'src/utils/forwardRefToOverridableComponent';

declare module '@mui/material/styles' {
  interface Components {
    BreadcrumbNav?: {
      defaultProps?: Partial<BreadcrumbNavProps>;
    };
  }
}

/**
 * A model representing the metadata for a single breadcrumb instance
 * within a model-driven [BreadcrumbNav] instance.
 */
export class BreadcrumbModel<
  RootComponent extends React.ElementType = LinkTypeMap['defaultComponent']
> {
  constructor(
    content: React.ReactNode,
    crumbLinkProps?: LinkProps<RootComponent>,
    activeCrumbTypographyProps?: TypographyProps
  ) {
    this.content = content;
    this.crumbLinkProps = crumbLinkProps;
    this.activeCrumbTypographyProps = activeCrumbTypographyProps;
  }

  /**
   * The text that describes the breadcrumb.
   *
   * This will usually be a string, but can also be any kind of ReactNode.
   */
  content: React.ReactNode;

  /**
   * Props applied to the `Link` element that renders a non-active breadcrumb.
   *
   * Note: Only set this property if the breadcrumb is non-active.
   */
  crumbLinkProps?: LinkProps<RootComponent>;

  /**
   * Props applied to the `Typography` element that renders the last / active breadcrumb.
   *
   * Note: Only set this property if the breadcrumb is active.
   */
  activeCrumbTypographyProps?: TypographyProps;
}

export interface BreadcrumbNavProps extends Omit<BreadcrumbsProps, 'children'> {
  /**
   * A list of breadcrumb models that will be rendered in order.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  crumbs: BreadcrumbModel<any>[];

  /**
   * If `true`, the first crumb will be rendered as a home icon.
   *
   * The value of [BreadcrumbModel.content] for the first crumb will be used as the aria-label for the crumb (this should be a string value).
   *
   * @default false
   */
  useHomeIconForFirstCrumb?: boolean;
}

/**
 * Renders a MUI `Breadcrumbs` navigation component that renders MUI `Link`s based on the list of [BreadcrumbModel]s
 * provided to [BreadcrumbNavProps.crumbs].
 */
// Ignore display name warning because it is already set in the forwardRef.
// eslint-disable-next-line react/display-name
export const BreadcrumbNav = React.memo(
  React.forwardRef<HTMLElement, BreadcrumbNavProps>(function BreadcrumbNav(
    inProps,
    ref
  ) {
    const props = useThemeProps({ props: inProps, name: 'BreadcrumbNav' });
    const { crumbs, useHomeIconForFirstCrumb = false, ...other } = props;
    const homeIcon = React.useMemo(() => {
      return <HomeOutlined fontSize="small" sx={{ display: 'flex' }} />;
    }, []);

    if (crumbs.length === 0) return null;

    return (
      <Breadcrumbs aria-label="Breadcrumb Navigation" {...other} ref={ref}>
        {crumbs.map((model, index) => {
          const { content, crumbLinkProps, activeCrumbTypographyProps } = model;

          if (index === crumbs.length - 1) {
            if (crumbLinkProps) {
              throw new Error(
                'Do not set `BreadcrumbModel.crumbLinkProps` on the last / active breadcrumb. Use `BreadcrumbModel.activeCrumbTypographyProps` instead.'
              );
            }

            if (
              typeof content !== 'string' &&
              !activeCrumbTypographyProps?.['aria-label']
            ) {
              console.warn(
                'Non-string `BreadcrumbModel.content` should be accompanied by an aria-label in `BreadcrumbModel.activeCrumbTypographyProps`.'
              );
            }

            // Render active breadcrumb as Typography.
            return (
              <Typography
                aria-current="page"
                color="text.primary"
                key={index}
                {...activeCrumbTypographyProps}
              >
                {content}
              </Typography>
            );
          } else {
            if (activeCrumbTypographyProps) {
              throw new Error(
                'Only set `BreadcrumbModel.activeCrumbTypographyProps` on the last / active breadcrumb. Use `BreadcrumbModel.crumbLinkProps` instead.'
              );
            }

            const useHomeIcon = useHomeIconForFirstCrumb && index === 0;

            if (useHomeIcon && typeof content !== 'string') {
              console.warn(
                'Do not use non-string `BreadcrumbModel.content` when `props.useHomeIconForFirstCrumb is true. The content value will be used to set the aria-label on the icon crumb.'
              );
            } else if (
              typeof content !== 'string' &&
              !crumbLinkProps?.['aria-label']
            ) {
              console.warn(
                'Non-string `BreadcrumbModel.content` should be accompanied by an aria-label in `BreadcrumbModel.crumbLinkProps`.'
              );
            }

            // Render inactive breadcrumbs as Links.
            return (
              <BreadcrumbLink
                key={index}
                aria-label={
                  useHomeIcon && typeof content === 'string'
                    ? content
                    : undefined
                }
                {...model.crumbLinkProps}
              >
                {useHomeIcon ? homeIcon : content}
              </BreadcrumbLink>
            );
          }
        })}
      </Breadcrumbs>
    );
  })
);

export default BreadcrumbNav;

/**
 * Renders a memoized Link to be used in [BreadcrumbNav] to prevent unnecessary re-renders
 * when the crumbs list changes.
 */
// Ignore display name warning because it is already set in the forwardRef.
// eslint-disable-next-line react/display-name
const BreadcrumbLink = React.memo(
  forwardRefToOverridableComponent<LinkTypeMap, LinkProps>(
    function BreadcrumbLink(props, ref) {
      const {
        href,
        sx: sxInput = {},
        component: componentIn,
        children,
        ...rest
      } = props;
      const component =
        componentIn ?? (typeof href === 'undefined' ? 'button' : 'a');
      const sx: LinkProps['sx'] = {
        ...(component === 'button' && {
          cursor: 'pointer',
        }),
        ...sxInput,
      };

      return (
        <Link
          underline="hover"
          color="inherit"
          component={component}
          href={href}
          sx={sx}
          {...rest}
          ref={ref}
        >
          {children}
        </Link>
      );
    }
  )
);
