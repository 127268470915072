import * as React from 'react';
import type { DateRangePicker as MuiDateRangePicker } from 'src/date_pickers';

export { type DateRangePickerProps } from 'src/date_pickers';
/**
 * Date Range Picker lets the user select a range of dates.
 *
 * > ⚠️ *Warning*: This is a React.lazy component that must be wrapped with `React.Suspense`.
 *
 * @deprecated Use `package:unify_ui/components/date_and_time_pickers.dart` instead. Will be removed in next major version.
 */
export const DateRangePickerLazy = React.lazy<typeof MuiDateRangePicker>(() =>
  import('src/browser.date_pickers').then((module) => ({
    default: module.DateRangePicker,
  }))
);
