import {
  CircularProgress as MuiCircularProgress,
  CircularProgressProps as MuiCircularProgressProps,
  styled,
  useThemeProps,
} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import * as React from 'react';
import circularProgressClasses from 'src/components/CircularProgress/circularProgressClasses';

const CircularProgressWrapper = styled(Box, {
  name: 'MuiCircularProgress',
  slot: 'Wrapper',
  overridesResolver: (props, styles) => styles.wrapper,
})(function CircularProgressWrapper() {
  return {
    position: 'relative',
    display: 'inline-flex',
    width: 'fit-content',
  };
});

const CircularProgressValueDisplay = styled(Typography, {
  name: 'MuiCircularProgress',
  slot: 'ValueDisplay',
  overridesResolver: (props, styles) => styles.valueDisplay,
})(function CircularProgressValueDisplay() {
  return {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
});

export interface CircularProgressProps extends MuiCircularProgressProps {
  /**
   * Whether the current progress value should be displayed in plain text within the circle.
   *
   * Only works when `variant` is `determinate`.
   *
   * Default: `false`
   */
  visibleValue?: boolean;
}

export const CircularProgress = React.forwardRef<
  HTMLDivElement,
  CircularProgressProps
>(function CircularProgress(inProps, forwardedRef) {
  const props = useThemeProps({ props: inProps, name: 'MuiCircularProgress' });
  const {
    visibleValue: visibleValueProp = false,
    variant = 'indeterminate',
    value = 0,
    className,
    size,
    ...rest
  } = props;

  const visibleValue = visibleValueProp && variant === 'determinate';

  const classes = clsx(
    visibleValue && circularProgressClasses.visibleValue,
    className
  );

  const fontSize = size
    ? `calc(${size}${typeof size === 'number' ? 'px' : ''} * 0.3)`
    : 12;

  return (
    <CircularProgressWrapper className={circularProgressClasses.wrapper}>
      <MuiCircularProgress
        value={value}
        variant={variant}
        className={classes}
        size={size}
        {...rest}
        ref={forwardedRef}
      />
      {visibleValue && (
        <CircularProgressValueDisplay
          variant="caption"
          className={circularProgressClasses.valueDisplay}
          aria-hidden={true}
          fontSize={fontSize}
        >{`${Math.round(value)}%`}</CircularProgressValueDisplay>
      )}
    </CircularProgressWrapper>
  );
});

export default CircularProgress;
