import {
  CardActionsProps,
  CardActions as MuiCardActions,
  SxProps,
  Theme,
  paperClasses,
  useThemeProps,
} from '@mui/material';
import React from 'react';
import {
  cardContentHorizontalThemeSpacing,
  cardContentVerticalThemeSpacing,
} from 'src/components/CardContent/cardContentStyleOverrides';
import { getThemePalette } from 'src/styles/utils';

export type { CardActionsProps };

// Update the CardActions module to have our conditional showDividers prop.
declare module '@mui/material/CardActions/CardActions' {
  interface CardActionsProps {
    /**
     * Whether a divider should be shown between the action and the adjacent `CardContent` areas.
     *
     * Defaults to `true` when the parent `Card` is an outlined variant, otherwise `false`.
     */
    showDividers?: boolean;
  }
}

export const CardActions = React.forwardRef<HTMLElement, CardActionsProps>(
  function UnifyCardActions(inProps, ref) {
    const props = useThemeProps({ props: inProps, name: 'CardActions' });

    const { showDividers, children, sx: inSx = {}, ...rest } = props;

    const baseStyle = '1px solid';

    // Build custom handling of the borders. Apply a top and bottom border in every case except the last entry.
    const borderStyles: Partial<SxProps<Theme>> = {
      borderTop: (theme) => `${baseStyle} ${getThemePalette(theme).divider}`,
      // As long as the action is not (:not()) the last child (:last-child) of the parent (&) render a bottom border.
      '&:not(:last-child)': {
        borderBottom: (theme) =>
          `${baseStyle} ${getThemePalette(theme).divider}`,
      },
    };

    // Apply style overrides to the `sx` props.
    const sx: SxProps<Theme> = {
      // Adjust padding by lr: 2 & tb: 3 theme spacing units.
      padding: (theme) =>
        theme.spacing(
          cardContentVerticalThemeSpacing,
          cardContentHorizontalThemeSpacing
        ),
      // Skip applying the divider if the flag is unset.
      ...(showDividers !== false && {
        // Apply borders in an outlined parent by default.
        [`.${paperClasses.outlined} &`]: borderStyles,
      }),
      // Force apply border if set.
      ...(showDividers && borderStyles),
      // Propagate the custom styles.
      ...inSx,
    };

    return (
      <MuiCardActions ref={ref} sx={sx} {...rest}>
        {children}
      </MuiCardActions>
    );
  }
);

export default CardActions;
