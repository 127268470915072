import {
  generateUtilityClasses,
  stepIconClasses as muiStepIconClasses,
  type StepIconClasses,
} from '@mui/material';
import generateUtilityClass from '@mui/utils/generateUtilityClass';

// Module augmentation for StepIconClasses
declare module '@mui/material/StepIcon' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface StepIconClasses extends UnifySeverityStepIconClasses {}
}

export interface UnifySeverityStepIconClasses {
  severityError: string;
  severityWarning: string;
  severitySuccess: string;
}

const unifySeverityStepIconClasses: UnifySeverityStepIconClasses =
  generateUtilityClasses('MuiStepIcon', [
    'severityError',
    'severityWarning',
    'severitySuccess',
  ]);

export function getStepIconUtilityClass(slot: string): string {
  return generateUtilityClass('MuiStepIcon', slot);
}

const stepIconClasses: StepIconClasses = {
  ...muiStepIconClasses,
  ...unifySeverityStepIconClasses,
};

export default stepIconClasses;
