import { unstable_composeClasses as composeClasses } from '@mui/base';
import {
  getToggleButtonUtilityClass,
  ToggleButton as MuiToggleButton,
  ToggleButtonProps as MuiToggleButtonProps,
  useThemeProps,
} from '@mui/material';
import { styled } from '@mui/system';
import clsx from 'clsx';
import * as React from 'react';
import { toggleButtonClasses } from 'src/components/ToggleButton/toggleButtonClasses';
import { useIsAncestorDisabled } from 'src/context/DisabledAncestorContext/DisabledAncestorContext';

/**
 * Custom props for the ToggleButton component
 */
export type ToggleButtonProps = MuiToggleButtonProps & {
  /**
   * The icon to display on its own or before the button's content.
   */
  startIcon?: React.ReactNode;

  // Note: The href prop is manually added here because it was being deleted/overridden
  // when generating the props. the ExtendButtonBase type uses OverridableComponent
  // and OverrideProps to handle component props, making it challenging to augment
  // the type without causing issues or requiring extensive changes.
  href?: string;
};

const useUtilityClasses = (
  ownerState: ToggleButtonProps & { hasChildren: boolean }
) => {
  const { classes, hasChildren } = ownerState;

  const slots = {
    root: ['root', hasChildren && 'hasChildren'],
    startIcon: ['startIcon'],
  };

  return composeClasses(slots, getToggleButtonUtilityClass, classes);
};

// Create a styled component for the start icon slot
// This component applies margin based on the presence of children
const ToggleButtonStartIcon = styled('span', {
  name: 'MuiToggleButton',
  slot: 'StartIcon',
  overridesResolver: (_, styles) => styles.startIcon,
})(({ theme }) => ({
  display: 'inherit',
  [`.${toggleButtonClasses.hasChildren} &`]: {
    marginRight: theme.spacing(1),
  },
}));

export const ToggleButton = React.forwardRef<
  HTMLButtonElement,
  ToggleButtonProps
>(function ToggleButton(inProps, ref) {
  const props = useThemeProps({ props: inProps, name: 'MuiToggleButton' });
  const {
    children,
    className,
    disabled: disabledProp,
    startIcon,
    ...rest
  } = props;
  const ancestorDisabled = useIsAncestorDisabled();
  const disabled = disabledProp ?? ancestorDisabled ?? false;
  const hasChildren = Boolean(children);
  const classes = useUtilityClasses({ ...props, hasChildren });
  const rootClasses = clsx(classes.root, className);

  return (
    <MuiToggleButton
      ref={ref}
      className={rootClasses}
      disabled={disabled}
      {...rest}
    >
      {startIcon && (
        <ToggleButtonStartIcon className={classes.startIcon}>
          {startIcon}
        </ToggleButtonStartIcon>
      )}
      {children}
    </MuiToggleButton>
  );
});

export default ToggleButton;
