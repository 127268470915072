import type { Color as MuiColor } from '@mui/material';
import type { WkColor } from 'src/styles/colors/types';

interface LegacyWsdColor {
  300: string;
  500: string;
  600: string;
  main: string;
  800: string;
  900: string;
}

const _green: MuiColor = {
  50: '#D7FAE1',
  100: '#D7FAE1',
  200: '#B1F5C5',
  300: '#c2eb99',
  400: '#3DCD64',
  500: '#a3e066',
  600: '#85d633',
  700: '#007F18',
  800: '#66cc00',
  900: '#339900',
  A100: '#D7FAE1',
  A200: '#B1F5C5',
  A400: '#3DCD64',
  A700: '#007F18',
};

/**
 * Use non-wsd green colors instead.
 * @deprecated
 */
const green: WkColor = {
  ..._green,
  main: _green[800],
  light: _green[100],
  highlight: _green[50],
  onHighlight: _green[800],
  dark: _green[900],
};

/**
 * @deprecated
 */
export const greenB: LegacyWsdColor = {
  300: '#b3deb6',
  500: '#8ece91',
  600: '#68bd6d',
  main: '#42ad48',
  800: '#42ad48',
  900: '#2b7237',
};

/**
 * @deprecated
 */
export const greenC: LegacyWsdColor = {
  300: '#9fccb3',
  500: '#6fb28c',
  600: '#3f9966',
  main: '#0f7f40',
  800: '#0f7f40',
  900: '#175123',
};

// eslint-disable-next-line deprecation/deprecation
export default green;
