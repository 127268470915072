import { generateUtilityClasses } from '@mui/material';

export interface ButtonToolbarClasses {
  root: string;
}

const buttonToolbarClasses: ButtonToolbarClasses = generateUtilityClasses(
  'MuiButtonToolbar',
  ['root']
);

export default buttonToolbarClasses;
