import {
  AlertTitle as MuiAlertTitle,
  AlertTitleProps as MuiAlertTitleProps,
  useTheme,
} from '@mui/material';
import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AlertTitleProps extends MuiAlertTitleProps {}

// This wrapper will be removed when styles are moved into wkTheme - FED-1257
export const AlertTitle = React.forwardRef<HTMLDivElement, AlertTitleProps>(
  function WsdAlertTitle({ children, sx, ...rest }, forwardedRef) {
    const theme = useTheme();
    let styles = sx;
    if (theme.isLegacyWsdTheme) {
      const { h4 } = theme.typography;
      let marginBottom: string | undefined;
      if (typeof h4.fontSize === 'string') {
        marginBottom = `${parseInt(h4.fontSize) - 1}px`;
      } else if (typeof h4.fontSize === 'number') {
        marginBottom = `${h4.fontSize - 1}px`;
      }

      let lineHeight = h4.lineHeight;
      if (typeof lineHeight === 'number') {
        lineHeight += 0.1;
      }

      styles = {
        fontSize: h4.fontSize,
        margin: `0 0 ${marginBottom}`,
        lineHeight: lineHeight,
        ...sx,
      };
    }

    return (
      <MuiAlertTitle ref={forwardedRef} sx={styles} {...rest}>
        {children}
      </MuiAlertTitle>
    );
  }
);

export default AlertTitle;
