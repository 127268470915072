import {
  iconClasses,
  styled,
  svgIconClasses,
  useThemeProps,
} from '@mui/material';
import {
  default as MuiLink,
  LinkTypeMap as MuiLinkTypeMap,
} from '@mui/material/Link';
import { OverrideProps } from '@mui/material/OverridableComponent';
import * as React from 'react';
import { useIsAncestorDisabled } from 'src/context/DisabledAncestorContext/DisabledAncestorContext';
import {
  ExtendTypeMap,
  forwardRefToOverridableComponent,
} from 'src/utils/forwardRefToOverridableComponent';
import linkClasses from './linkClasses';

export interface LinkOwnProps {
  /**
   * Icon element placed after the children.
   */
  endIcon?: React.ReactNode;
}

export type LinkTypeMap<
  AdditionalProps = unknown,
  RootComponent extends React.ElementType = MuiLinkTypeMap['defaultComponent']
> = ExtendTypeMap<
  MuiLinkTypeMap,
  AdditionalProps & LinkOwnProps,
  RootComponent
>;

export type LinkProps<
  RootComponent extends React.ElementType = MuiLinkTypeMap['defaultComponent'],
  AdditionalProps = unknown
> = OverrideProps<LinkTypeMap<AdditionalProps, RootComponent>, RootComponent>;

/**
 * Demos:
 *
 * - [Breadcrumbs](https://mui.com/material-ui/react-breadcrumbs/)
 * - [Links](https://mui.com/material-ui/react-link/)
 *
 * API:
 *
 * - [Link API](https://mui.com/material-ui/api/link/)
 * - inherits [Typography API](https://mui.com/material-ui/api/typography/)
 */
export const Link = forwardRefToOverridableComponent<LinkTypeMap, LinkProps>(
  function Link(inProps, ref) {
    const props = useThemeProps({ props: inProps, name: 'MuiLink' });
    const { children, color: colorProp, endIcon, ...rest } = props;
    const ancestorDisabled = useIsAncestorDisabled();
    const color = ancestorDisabled ? 'inherit' : colorProp;

    return (
      <MuiLink
        sx={endIcon ? { display: 'flex', alignItems: 'center' } : {}}
        color={color}
        {...rest}
        ref={ref}
      >
        {children}
        {endIcon ? (
          <LinkEndIcon className={linkClasses.endIcon}>{endIcon}</LinkEndIcon>
        ) : undefined}
      </MuiLink>
    );
  }
);

const LinkEndIcon = styled('span', {
  name: 'MuiLink',
  slot: 'EndIcon',
  overridesResolver: (props, styles) => styles.endIcon,
})(() => ({
  display: 'inherit',
  marginLeft: 8,
  [`& .${svgIconClasses.root}, & .${iconClasses.root}`]: {
    fontSize: '20px',
  },
}));

export default Link;
