import { useThemeProps } from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';
import {
  default as MuiStack,
  StackProps as MuiStackProps,
  StackTypeMap as MuiStackTypeMap,
} from '@mui/material/Stack';
import clsx from 'clsx';
import * as React from 'react';
import { default as buttonToolbarClasses } from 'src/components/ButtonToolbar/buttonToolbarClasses';
import {
  ExtendTypeMap,
  forwardRefToOverridableComponent,
} from 'src/utils/forwardRefToOverridableComponent';

export interface ButtonToolbarOwnProps {
  /**
   * The accessible role of the root node rendered by the `ButtonToolbar`.
   *
   * @default toolbar
   */
  role?: string;

  /**
   * Defines the `flex-direction` style property.
   * It is applied for all screen sizes.
   * @default row
   */
  direction?: MuiStackProps['direction'];
}

export type ButtonToolbarTypeMap<
  AdditionalProps = unknown,
  RootComponent extends React.ElementType = MuiStackTypeMap['defaultComponent']
> = ExtendTypeMap<
  MuiStackTypeMap,
  AdditionalProps & ButtonToolbarOwnProps,
  RootComponent
>;

export type ButtonToolbarProps<
  RootComponent extends React.ElementType = MuiStackTypeMap['defaultComponent'],
  AdditionalProps = unknown
> = OverrideProps<
  ButtonToolbarTypeMap<AdditionalProps, RootComponent>,
  RootComponent
>;

/**
 * The `ButtonToolbar` component adds horizontal or vertical spacing between
 * adjacent {@link Button}s or {@link ButtonGroup}s.
 *
 * Renders / forwards props to the {@link Stack} component.
 * See the Stack component documentation for more details.
 */
export const ButtonToolbar = forwardRefToOverridableComponent<
  ButtonToolbarTypeMap,
  ButtonToolbarProps
>(function ButtonToolbar(props, forwardedRef) {
  const {
    role = 'toolbar',
    spacing = 0.625,
    direction = 'row',
    className: classNameProp,
    sx: sxProp,
    ...rest
  } = useThemeProps({ props, name: 'ButtonToolbar' });
  const className = clsx(buttonToolbarClasses.root, classNameProp);
  const sx: ButtonToolbarProps['sx'] = {
    flexWrap: 'wrap',
    ...sxProp,
  };

  return (
    <MuiStack
      ref={forwardedRef}
      className={className}
      direction={direction}
      role={role}
      spacing={spacing}
      sx={sx}
      {...rest}
    />
  );
});

export default ButtonToolbar;
